import { Icons } from 'components';
import { useDirtyDialog } from 'dirty';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ZoneSelectors } from 'zone';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import ZonesIcon from '@material-ui/icons/Subscriptions';

import { useStyles } from './BottomNav.jss';
import { LayoutActions, LayoutSelectors } from './duck';
import { MainLayoutArea } from './duck/types';

export const BottomNav: React.FunctionComponent = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const layoutState = useSelector(LayoutSelectors.selectLayoutState);
  const zones = useSelector(ZoneSelectors.selectZones);
  const [nextTab, setNextTab] = useState(0);

  const onConfirmDirty = useCallback(() => {
    changeTab(nextTab);
    setNextTab(0);
  }, [nextTab]);

  const onCancelDirty = useCallback(() => {
    setNextTab(0);
  }, []);

  const { dirty, openDialog } = useDirtyDialog(undefined, onConfirmDirty, onCancelDirty);

  const onNavChanged = useCallback(
    (e: any, value: any) => {
      if (dirty) {
        openDialog();
        setNextTab(Number(value));
      } else {
        changeTab(Number(value));
      }
    },
    [dirty, openDialog]
  );

  const changeTab = useCallback((tab: number) => {
    let path = '';
    switch (tab) {
      case MainLayoutArea.LibraryOnHold:
        path = '/library';
        break;
      case MainLayoutArea.LibraryOverhead:
        path = '/library';
        break;
      case MainLayoutArea.Unauthorized:
        path = '/unauthorized';
        break;
      case MainLayoutArea.ZonesOverhead:
        path = '/zones';
        break;
    }
    dispatch(LayoutActions.selectMainArea(tab, 0));

    history.push(path);
  }, []);

  // bit of a hack here: since we're mapping two values(ZonesOnHold and ZonesOverhead) to just one tab
  const selectedNav =
    layoutState.selectedMainArea === MainLayoutArea.ZonesOnHold
      ? MainLayoutArea.ZonesOverhead
      : layoutState.selectedMainArea;

  return (
    <BottomNavigation value={selectedNav} showLabels className={classes.bottomNav} onChange={onNavChanged}>
      {zones.overHead.length > 0 && (
        <BottomNavigationAction
          label={t('overHead')}
          icon={<Icons.OverheadIcon />}
          value={MainLayoutArea.LibraryOverhead}
        />
      )}
      {zones.onHold.length > 0 && (
        <BottomNavigationAction label={t('onHold')} icon={<Icons.OnHoldIcon />} value={MainLayoutArea.LibraryOnHold} />
      )}
      <BottomNavigationAction label={t('zones')} icon={<ZonesIcon />} value={MainLayoutArea.ZonesOverhead} />
    </BottomNavigation>
  );
};
