import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      flexGrow: 1,
      overflow: 'auto',
    },
    listItem: {
      '&:hover': {
        '& $hiddenButton': {
          visibility: 'visible',
        },
      },
    },
    listItemRoot: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(10),
      },
    },
    hiddenButton: {
      visibility: "collapse",
    },
    dragHandle: {
      cursor: 'grab',
      minWidth: theme.spacing(3),
    },
    dragButton: {
      marginRight: theme.spacing(2)
    },
    itemSecondaryAction: {
      right: 0
    },
    itemIcon: {
      minWidth: theme.spacing(5)
    }
  })
);
