import { SkeletonList } from 'components';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ListItemIcon, Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PlaylistIcon from '@material-ui/icons/FormatListNumbered';

import { ZoneSelectors } from '../duck';
import { useStyles } from './Playlists.jss';

export interface PlaylistsProps {
  onSelected?: (id: number) => void;
  selected?: number;
  zoneId: number;
  searchTerm?: string;
}

const PlaylistsEmpty = React.memo(() => {
  const [t] = useTranslation();

  return (
    <Typography variant="subtitle2" color="textSecondary" align="center">
      {t('playlists.emptyList')}
    </Typography>
  );
});

export const Playlists: React.FunctionComponent<PlaylistsProps> = (props) => {
  const { selected, onSelected, searchTerm } = props;
  const classes = useStyles();
  const history = useHistory();
  const playlists = useSelector((state) => ZoneSelectors.selectZonePlaylistsFiltered(state, searchTerm));
  const isFetchingZonePlaylists = useSelector(ZoneSelectors.selectFetchingZonePlaylists);

  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      const id = Number(e.currentTarget.id);
      const eventPlaylist = playlists.find((ep) => ep.id === id);

      if (!eventPlaylist) {
        return;
      }

      if (onSelected) {
        onSelected(id);
      } else {
        history.push(`/zone/${props.zoneId}/${id}`);
      }
    },
    [onSelected, history, playlists]
  );

  if (isFetchingZonePlaylists) {
    return <SkeletonList minItems={3} maxItems={7} icon listClass={classes.list} divider/>;
  }

  if (playlists.length === 0) {
    return <PlaylistsEmpty />;
  }

  return (
    <List className={classes.list}>
      {playlists.map((e) => (
        <ListItem button divider key={e.id} id={e.id.toString()} selected={e.id === selected} onClick={handleClick}>
          <ListItemIcon>
            <PlaylistIcon />
          </ListItemIcon>
          <ListItemText primary={e.name} />
        </ListItem>
      ))}
    </List>
  );
};
