import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    avatar: {
      marginRight: theme.spacing(1),
      width: theme.spacing(4),
      height: theme.spacing(4),
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    badge: {
      right: -3,
      top: 13,
      //border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  })
);
