import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { ZoneSelectors } from '../duck';

export const PlaylistEditAppBar: React.FunctionComponent = (props) => {
  let params = useParams<{ playlistId?: string }>();
  const [t] = useTranslation();

  const newlyAddedPlaylistId = useSelector(ZoneSelectors.selectNewlyAddedPlaylist);

  const playlist = useSelector((state) =>
    params.playlistId
      ? ZoneSelectors.selectPlaylist(state, Number(params.playlistId))
      : ZoneSelectors.selectPlaylist(state, newlyAddedPlaylistId)
  );

  if (!playlist && params.playlistId) {
    throw 'No event found for id ' + params.playlistId;
  }

  return playlist ? (
    <>
      <Typography>{playlist.name}</Typography>
    </>
  ) : (
    <>
      <Typography>{t('createPlaylist')}</Typography>
    </>
  );
};
