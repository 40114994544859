import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';

import { useStyles } from './ConfirmationDialog.jss';

export interface ConfirmationDialogProps {
  open: boolean;
  title: string;
  content: string;
  cancelLabelKey?: string;
  confirmLabelKey?: string;
  countCheck?: number;
  countCheckLabelKey?: string;
  onCancel?: () => void;
  onConfirm: () => void;
}

const CountCheck: React.FunctionComponent<{
  label: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <TextField
      className={classes.countInput}
      size="small"
      color="secondary"
      type="number"
      autoFocus
      label={t<string>(props.label)}
      fullWidth
      onChange={props.onChange}
    />
  );
};

export const ConfirmationDialog: React.FunctionComponent<ConfirmationDialogProps> = (props) => {
  const { open, title, countCheck, onCancel, onConfirm } = props;
  const [t] = useTranslation();
  const [confirmDisabled, setConfirmDisabled] = useState(() => (countCheck ? true : false));

  const innerCancelLabelKey = props.cancelLabelKey ? props.cancelLabelKey : 'cancel';
  const innerConfirmLabelKey = props.confirmLabelKey ? props.confirmLabelKey : 'confirm';

  const countChangeCallback = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (countCheck) {
        setConfirmDisabled(countCheck !== Number(e.target.value));
      }
    },
    [countCheck]
  );

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {props.content && <DialogContentText>{props.content}</DialogContentText>}
        {props.children}
        {props.countCheck && props.countCheckLabelKey && (
          <CountCheck onChange={countChangeCallback} label={props.countCheckLabelKey} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t(innerCancelLabelKey)}</Button>
        <Button onClick={onConfirm} color="secondary" variant="contained" disabled={confirmDisabled}>
          {t(innerConfirmLabelKey)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
