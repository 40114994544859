import { createSelector } from 'reselect';

import { MediaTypes } from '@models';

import { zoneAreaName, ZoneState } from './types';

export const selectZoneState = (state: any): ZoneState => {
  return state[zoneAreaName];
};

export const selectZones = createSelector([selectZoneState], (state) => ({
  overHead: state.overheadZones,
  onHold: state.onHoldZones,
  initialized: state.initialized,
}));

export const selectFetchingZones = createSelector([selectZoneState], (state) => {
  return state.fetchingZones;
});

export const selectZonePlaylists = createSelector([selectZoneState], (state) => state.selectedZonePlaylists);

export const selectZonePlaylistsFiltered = createSelector([selectZoneState, (state: any, searchTerm: string | undefined) => searchTerm], (state, searchTerm: string | undefined) => {
  return !searchTerm ? state.selectedZonePlaylists : state.selectedZonePlaylists.filter(p => p.name._contains(searchTerm, false));
});

export const selectFetchingZonePlaylists = createSelector([selectZoneState], (state) => {
  return state.fetchingZonePlaylists;
});

export const selectZone = createSelector([selectZones, (_: any, zoneId: number) => zoneId], (zones, zoneId) => {
  let zone = zones.overHead.find((z) => z.zoneId === zoneId);
  if (!zone) {
    zone = zones.onHold.find((z) => z.zoneId === zoneId);
  }
  return zone;
});

export const selectIsDeletingZonePlaylist = createSelector(selectZoneState, (state) => state.deletingZonePlaylist);

export const selectPlaylist = createSelector(
  [selectZonePlaylists, (_: any, playlistId: number) => playlistId],
  (playlists, playlistId) => playlists.find((e) => e.id === playlistId) as MediaTypes.Playlist.PlaylistDto
);

export const selectPlaylistMessages = createSelector([selectZoneState], (state) => state.selectedPlaylistMessages);

export const selectNewlyAddedPlaylist = createSelector([selectZoneState], (state) => state.newlyCreatedPlaylistId);

export const selectIsSaving = createSelector([selectZoneState], (state) => state.savingPlaylist);

export const selectIsLoading = createSelector(
  [selectZoneState],
  (state) => state.deletingZonePlaylist || state.fetchingZonePlaylists || state.savingPlaylist
);

export const selectIsFetchingPlaylist = createSelector([selectZoneState], (state) => state.fetchingPlaylist);
