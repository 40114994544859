import AddressingService from 'addressing/addressingService';
import deepClone from 'lodash-es/cloneDeep';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  AddressingActions,
  AddressingSelectors,
  fetchAddressingRules,
  MediaDeny,
  TreeItemType,
  TreeNode
} from '../duck';

const useAddressingRules = (channelId: number = 0, mediaId: number = 0, treeId: string, mediaDenies?: MediaDeny[]) => {
  const dispatch = useDispatch();
  const addressingRulesState = useSelector(AddressingSelectors.selectAddressingRules(treeId));
  const addressingInstanceState = useSelector(AddressingSelectors.selectAddressingInstance(treeId));

  const applyRules = useCallback(
    (newDenies: MediaDeny[]) => {
      dispatch(AddressingActions.processAddressingRulesStart(newDenies, treeId));
    },
    [treeId, dispatch]
  );

  const onRuleChanged = useCallback(
    (item: TreeNode) => {
      if (item.chckState === 4) {
        return;
      }

      if (item.modelType === TreeItemType.FOLDER) {
        return;
      }

      const channelMediaDenyInfo: MediaDeny = {
        idMediaChannel: channelId,
        idMedia: mediaId,
        idEntity: Number(item.cleanId),
        idEntityType: item.entityType,
        deny: item.chckState === 0 || item.chckState === 3 ? false : item.chckState === 1 ? true : null,
      };

      applyRules([channelMediaDenyInfo]);
    },
    [channelId, mediaId, applyRules]
  );

  // subscribe to the Web Worker so it can send us back the processed rules
  useEffect(() => {
    // if there's not state for this addressing instance (treeId) abort
    if (!addressingInstanceState) {
      return;
    }

    if (addressingInstanceState.subscribedToWebWorker) {
      return;
    }

    AddressingService.subscribe((result) => {
      dispatch(AddressingActions.processAddressingRulesEnd(result, treeId));
    }, treeId);

    dispatch(AddressingActions.subscribeToWebWorker(treeId));
  }, [addressingInstanceState, addressingInstanceState.subscribedToWebWorker, channelId, mediaId]);

  // effect used for initial data retrieval
  useEffect(() => {
    // if there's not state for this addressing instance (treeId) abort
    if (!addressingInstanceState) {
      return;
    }

    const isFetching = addressingRulesState.fetchStatus.isFetching;
    const fetchComplete = addressingRulesState.fetchStatus.complete;

    // we need to fetch addressing rules
    if (!isFetching && !fetchComplete) {
      // if we provided mediaDenies from the outside, just use those
      if (mediaDenies) {
        dispatch(AddressingActions.fetchAddressingRulesSuccess(channelId, mediaId, mediaDenies, treeId));
        return;
      } else {
        // if we didn't provide deny rules from the outside, go and fetch them from the server
        dispatch(fetchAddressingRules(channelId, mediaId, treeId));
      }
    }
  }, [
    addressingInstanceState,
    addressingRulesState.fetchStatus.isFetching,
    addressingRulesState.fetchStatus.complete,
    channelId,
    mediaId,
  ]);

  return {
    processingStatus: addressingRulesState.processingStatus,
    fetchStatus: addressingRulesState.fetchStatus,
    rawData: addressingRulesState.rawData,
    processedData: addressingRulesState.processedData,
    mediaId: mediaId,
    onAddressingChange: onRuleChanged,
  };
};

export default useAddressingRules;
