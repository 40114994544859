import i18next from 'i18next';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeTree, TreeWalker, TreeWalkerValue } from 'react-vtree';

import {
  AddressingActions,
  AddressingSelectors,
  AddressingViewModes,
  MediaDeny,
  NodeMeta,
  TreeData,
  TreeNode
} from '../duck';
import { generateTreeIdentifier } from '../duck/utils';
import useAddressingRules from '../hooks/addressingRules';
import useAddressingStructure from '../hooks/addressingStructure';
import { AddressingNodeRenderer } from './AddressingNode';
import { useStyles } from './AddressingTree.jss';
import { EmptyAddressing } from './EmptyAddressing';

export interface AddressingTreeProps {
  viewMode: AddressingViewModes;
  workgroupId: number;
  channelId: number;
  mediaId: number;
  style?: React.CSSProperties;
  placeholder: JSX.Element;
  nodeRenderer: AddressingNodeRenderer;
  mediaDenies?: MediaDeny[];
}

export const AddressingTree: React.FunctionComponent<AddressingTreeProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tree = useRef<FixedSizeTree<TreeData>>(null);
  const { workgroupId, channelId, mediaId } = props;
  const treeIdentifier = generateTreeIdentifier(workgroupId, channelId, mediaId);
  const addressingStructure = useAddressingStructure(channelId, workgroupId, treeIdentifier);
  const addressingRules = useAddressingRules(channelId, mediaId, treeIdentifier, props.mediaDenies);
  const needToUpdateTree = useSelector(AddressingSelectors.selectNeedToUpdateTree(treeIdentifier));
  const initializationComplete = useSelector(AddressingSelectors.selectInitializationComplete(treeIdentifier));

  const getNodeData = useCallback(
    (node: TreeNode, nestingLevel: number): TreeWalkerValue<TreeData, NodeMeta> => {
      return {
        data: {
          id: node.$$hashKey,
          isLeaf: node.children.length === 0,
          isOpenByDefault: node.isExpanded,
          label: node.label,
          nestingLevel,
          isExpanded: node.isExpanded,
          chckState: node.chckState,
          modelType: node.modelType,
          isLinked: node.linked ? true : false,
          isProcessing: addressingRules.processingStatus.isProcessing,
          onToggle: () => addressingStructure.toggleExpandNode(node),
          onChange: () => addressingRules.onAddressingChange(node),
        },
        nestingLevel,
        node,
      };
    },
    [addressingStructure.processedData, addressingRules.processingStatus.isProcessing]
  );

  const treeWalker = useCallback(
    function* (): ReturnType<TreeWalker<TreeData, NodeMeta>> {
      // Workgroup View
      //yield getNodeData(addressingStructure.processedData[viewMode][0], 0);

      // A-Z view
      for (let i = 0; i < addressingStructure.processedData[props.viewMode].length; i++) {
        yield getNodeData(addressingStructure.processedData[props.viewMode][i], 0);
      }

      // Groups view
      // for (let i =0; i < addressingStructure.processedData[viewMode].length; i++) {
      //   yield getNodeData(addressingStructure.processedData[viewMode][i], 0);
      // }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      while (true) {
        const parentMeta = yield;
        if (parentMeta.node.isExpanded) {
          // eslint-disable-next-line @typescript-eslint/prefer-for-of
          for (let i = 0; i < parentMeta.node.children.length; i++) {
            yield getNodeData(parentMeta.node.children[i], parentMeta.nestingLevel + 1);
          }
        }
      }
    },
    [addressingStructure.processedData, props.viewMode, addressingRules.processingStatus.isProcessing, getNodeData]
  );

  useEffect(() => {
    dispatch(AddressingActions.setAddressingViewMode(props.viewMode, treeIdentifier));
  }, [props.viewMode, dispatch, treeIdentifier]);

  // effect used to process addressing rules once the Web Worker and the tree structure have been initialized
  useEffect(() => {
    const canProcessAddressingRules =
      addressingStructure.processingStatus.complete && addressingRules.fetchStatus.complete;
    const rulesInitializationComplete =
      addressingRules.processingStatus.isProcessing || addressingRules.processingStatus.complete;

    if (canProcessAddressingRules && !rulesInitializationComplete) {
      console.info('processAddressingRulesStart');
      dispatch(
        AddressingActions.processAddressingRulesStart(addressingRules.rawData, treeIdentifier)
      );
    }
  }, [
    addressingRules.fetchStatus.complete,
    addressingRules.processingStatus,
    addressingStructure.processingStatus.complete,
    addressingRules.mediaId,
  ]);

  // effect used to update the addressing tree when something (a rule) changes
  useEffect(() => {
    const canUpdateAddressingTree =
      addressingRules.processingStatus.complete && addressingStructure.processingStatus.complete;
    if (needToUpdateTree && canUpdateAddressingTree) {
      dispatch(AddressingActions.updateAddressingTreeStart(treeIdentifier));
    }
  }, [
    addressingRules.processedData,
    addressingRules.processingStatus.complete,
    addressingStructure.processedData,
    addressingStructure.processingStatus.complete,
    needToUpdateTree,
    treeIdentifier,
    dispatch,
  ]);

  if (initializationComplete) {
    if (addressingStructure.processedData[props.viewMode].length < 1) {
      return (
        <div className={classes.noDataContainer}>
          <div className={classes.noDataText}>
            <EmptyAddressing />
          </div>
        </div>
      );
    }
    return (
      <div style={props.style}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <FixedSizeTree ref={tree} treeWalker={treeWalker} itemSize={48} height={height} width={'100%'}>
              {props.nodeRenderer}
            </FixedSizeTree>
          )}
        </AutoSizer>
      </div>
    );
  } else {
    return props.placeholder;
  }
};
