import { MediaDto } from '@models/media/MediaDto';

export interface FileUploadRequest {
  /**
   * The index of the chunk in the current upload.
   * First chunk is 1 (no base-0 counting here).
   */
  flowChunkNumber: number;

  /**
   * The total number of chunks.
   */
  flowTotalChunks: number;

  /**
   * The general chunk size. Using this value and flowTotalSize
   * you can calculate the total number of chunks. Please note
   * that the size of the data received in the HTTP might be
   * lower than flowChunkSize of this for the last chunk for a
   * file.
   */
  flowChunkSize: number;

  /**
   * The total file size.
   */
  flowTotalSize: number;

  /**
   * A unique identifier for the file contained in the request.
   */
  flowIdentifier: string;

  /**
   * The original file name (since a bug in Firefox results in
   * the file name not being transmitted in chunk multipart posts).
   */
  flowFilename: string;

  /**
   * The file's relative path when selecting a directory
   * (defaults to file name in all browsers except Chrome).
   */
  flowRelativePath: string;

  /**
   * Specifies if the file is media content or not
   */
  isContent: boolean;
}

export interface FileUploadResult {
  success: boolean;
  file: File;
  media: MediaDto | null;
}

export interface FileAssembleInfo {
  sha: string;
  size: number;
}

export interface UploadMediaInfo {
  media: {
    fileName: string;
    fileShaCode: string;
    mediaId: number;
    mediaName: string;
  };
  mediaFolderId?: number;
  mediaOrigin: 1;
}

export enum UploadStatus {
  NotStarted,
  Analyzing,
  CreateMedia,
  ChunkUpload,
  AssemblingFile,
  Done,
  Canceled,
  Failed,
}
