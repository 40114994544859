import { FullWidthSearchBox } from 'components';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpDialog, SearchBox } from 'react-tools';

import { useTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HelpIcon from '@material-ui/icons/Help';

import { useStyles } from './Toolbar.jss';

export interface ToolbarProps {
  helpType: string;
  onSearch?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  hideSearch?: boolean;
  hideHelp?: boolean;
  searchBreakpoint?: Breakpoint;
}

export const Toolbar: React.FunctionComponent<ToolbarProps> = React.memo((props) => {
  const classes = useStyles();
  const [helpOpen, setHelpOpen] = useState(false);

  const [t] = useTranslation();
  const theme = useTheme();
  const dettachSearch = useMediaQuery(
    `(max-width: ${theme.breakpoints.values[props.searchBreakpoint ? props.searchBreakpoint : 'sm']}px)`
  );

  const onHelpOpen = useCallback(() => setHelpOpen(true), [setHelpOpen]);
  const onHelpClose = useCallback(() => {
    setHelpOpen(false);
  }, [setHelpOpen]);

  return (
    <div className={classes.container}>
      <div className={classes.mainBarContainer}>
        {typeof React.Children.toArray(props.children)[0] === 'string' ? (
          <Typography variant="h5">{React.Children.toArray(props.children)[0]}</Typography>
        ) : (
          React.Children.toArray(props.children)[0]
        )}
        <div className={classes.grow} />
        {!props.hideSearch && !dettachSearch && (
          <div className={classes.quickSearchContainer}>
            <SearchBox placeholder={t('search')} onChange={props.onSearch} />
          </div>
        )}
        {React.Children.toArray(props.children)[1]}
        {!props.hideHelp && (
          <>
            <IconButton onClick={onHelpOpen}>
              <HelpIcon />
            </IconButton>
            <HelpDialog isOpen={helpOpen} type={props.helpType} closeDialog={onHelpClose} />
          </>
        )}
      </div>
      {typeof React.Children.toArray(props.children)[2] === 'string' ? (
        <Typography variant="caption" color="textSecondary" className={classes.subtitle}>
          {React.Children.toArray(props.children)[2]}
        </Typography>
      ) : (
        React.Children.toArray(props.children)[2]
      )}
      {dettachSearch && (
        <FullWidthSearchBox
          disabled={false}
          placeholder={t('search')}
          searchBoxRootClass={classes.fullWidthSearchBox}
          onChange={props.onSearch}
        />
      )}
    </div>
  );
});
