import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2),
      },
      height: '100%',
      '@global': {
        '*': {
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
            cursor: 'pointer',
            background: 'rgba(255, 255, 255, 0.1)',
          },

          '&::webkit-scrollbar-track': {
            background: 'black',
          },

          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(255, 255, 255, 0.3)',
            borderRadius: '5px',

            '&:hover': {
              background: 'rgba(255, 255, 255, 0.3)',
            },
          },
        },
      },
    },
    dialogTitle: {
      '& h2': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
    },
  })
);
