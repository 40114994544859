import { useDispatch, useSelector } from 'react-redux';

import {
  useAddressingContextChannelId,
  useAddressingContextMediaId,
  useAddressingContextWorkgroupId,
} from '../context/hooks';
import { AddressingActions, AddressingSelectors, AddressingViewModes } from '../duck';
import { generateTreeIdentifier } from '../duck/utils';

export const useAddressingWithContext = () => {
  const workgroupId = useAddressingContextWorkgroupId();
  const channelId = useAddressingContextChannelId();
  const mediaId = useAddressingContextMediaId();
  const treeId = generateTreeIdentifier(workgroupId, channelId, mediaId);
  const viewMode = useSelector(AddressingSelectors.selectAddressingViewMode(treeId));
  const dispatch = useDispatch();

  return {
    channelId,
    mediaId,
    workgroupId,
    viewMode,
    selectSpecificAddressingRules: AddressingSelectors.selectSpecificAddressingRules,
    setViewMode: (viewMode: AddressingViewModes) => dispatch(AddressingActions.setAddressingViewMode(viewMode, treeId)),
  };
};

export const useAddressing = (workgroupId: number, channelId: number, mediaId: number) => {
  const treeId = generateTreeIdentifier(workgroupId, channelId, mediaId);
  const viewMode = useSelector(AddressingSelectors.selectAddressingViewMode(treeId));
  const dispatch = useDispatch();

  return {
    viewMode,
    selectSpecificAddressingRules: AddressingSelectors.selectSpecificAddressingRules,
    setViewMode: (viewMode: AddressingViewModes) => dispatch(AddressingActions.setAddressingViewMode(viewMode, treeId)),
  };
};
