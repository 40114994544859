import { AddressingNodeFormLabel } from "./AddressingCheckbox";
import { NodeComponentProps } from "react-vtree/dist/es/Tree";
import { FixedSizeNodePublicState } from "react-vtree";
import { TreeData } from "../duck";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

export type AddressingNodeRenderer = React.FC<
  NodeComponentProps<TreeData, FixedSizeNodePublicState<TreeData>>
>;

const AddresingNode: AddressingNodeRenderer = ({ data, style }) => {
  return (
    <div
      style={{
        ...style,
        alignItems: "center",
        display: "flex",
        marginLeft: data.nestingLevel * 30 + (data.isLeaf ? 24 : 0),
      }}
    >
      {!data.isLeaf && (
        <div>
          <div
            onClick={(e) => {
              data.onToggle();
            }}
            style={{ fontFamily: "Courier New" }}
          >
            {data.isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </div>
        </div>
      )}
      <AddressingNodeFormLabel {...data} />
    </div>
  );
};

export default AddresingNode;