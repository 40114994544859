import { useFetchLibrary } from 'hooks';
import { LayoutSelectors } from 'layout';
import React from 'react';
import { useSelector } from 'react-redux';

import Hidden from '@material-ui/core/Hidden';

import { MessageMasterDetails } from './list/MessageMasterDetails';
import { useStyles } from './MessageLibrary.jss';
import { Navigator } from './navigator';

export interface MessageLibraryProps {}

export const MessageLibrary: React.FunctionComponent<MessageLibraryProps> = (_) => {
  const selectedMessageType = useSelector(LayoutSelectors.selectMessageType);
  
  useFetchLibrary();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Hidden smDown>
        <MessageMasterDetails selectedMessageType={selectedMessageType} />
      </Hidden>
      <Hidden mdUp>
        <Navigator variant="list" hideControls />
      </Hidden>
    </div>
  );
};
