import {
  createStyles,
  fade,
  makeStyles,
  Theme
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    tablePaper: {
      overflow: 'hidden',
      marginTop: theme.spacing(4),
      height: '100%',
      width: '100%',
    },
    newTableRow: {
      animation: `$highlightNewRow 4000ms ${theme.transitions.easing.easeInOut}`,
    },
    '@keyframes highlightNewRow': {
      '0%': {
        background: fade(theme.palette.warning.light, 0.4),
      },
      '100%': {
        background: 'inherit',
      },
    },
  })
);
