import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { EntityType } from 'react-tools';

import { Divider, IconButton, Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Skeleton from '@material-ui/lab/Skeleton';

import { useStyles } from './Addressing.jss';
import AddressingComponent from './components/AddressingComponent';
import AddresingNode from './components/AddressingNode';
import {
  AddressingCleanUp,
  AddressingViewModes,
  DefaultMediaAddressingBehavior
} from './duck';
import { useAddressingWithContext } from './hooks/addressing';
import { WorkgroupRuleSwitch } from './WorkgroupRuleSwitch';

const AddressingSkeleton: React.FunctionComponent = React.memo(() => {
  return (
    <div>
      <div>
        <Skeleton variant="text" width={120} height={24} style={{ marginLeft: 40 }} />
        <Divider />
      </div>
      <div style={{ marginLeft: 40 }}>
        <div>
          <Skeleton variant="text" width={120} height={24} style={{ marginLeft: 80 }} />
          <Skeleton variant="text" width={250} height={24} style={{ marginLeft: 120 }} />
          <Skeleton variant="text" width={20} height={24} style={{ marginLeft: 120 }} />
          <Divider />
        </div>
        <div>
          <Skeleton variant="text" width={180} height={24} style={{ marginLeft: 80 }} />
          <Skeleton variant="text" width={190} height={24} style={{ marginLeft: 100 }} />
          <Skeleton variant="text" width={70} height={24} style={{ marginLeft: 140 }} />

          <Divider />
        </div>
        <div>
          <Skeleton variant="text" width={90} height={24} style={{ marginLeft: 80 }} />
          <Skeleton variant="text" width={150} height={24} style={{ marginLeft: 140 }} />
          <Skeleton variant="text" width={50} height={24} style={{ marginLeft: 120 }} />
          <Divider />
        </div>
      </div>
    </div>
  );
});

interface AddressingProps {
  workgroupId?: number;
  channelId?: number;
  mediaId?: number;
  cleanupMode: AddressingCleanUp;
  defaultBehavior: DefaultMediaAddressingBehavior;
}

export const Addressing: React.FunctionComponent<AddressingProps> = (props) => {
  const { workgroupId, channelId, mediaId, viewMode, setViewMode } = useAddressingWithContext();
  const classes = useStyles();
  const [t] = useTranslation();

  const updateViewMode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setViewMode(Number.parseInt(e.target.value, 10));
    },
    [setViewMode]
  );

  return (
    <div className={classes.container}>
      <div className={classes.toolbar}>
        <FormControl component="fieldset">
          <RadioGroup
            row
            aria-label="position"
            name="position"
            value={viewMode}
            defaultValue={AddressingViewModes.AZ}
            onChange={updateViewMode}
          >
            <FormControlLabel
              value={AddressingViewModes.AZ}
              control={<Radio color="primary" />}
              label={t('addressing.viewMode.az')}
            />
            <FormControlLabel
              value={AddressingViewModes.Groups}
              control={<Radio color="primary" />}
              label={t('addressing.viewMode.groups')}
            />
          </RadioGroup>
        </FormControl>
        <div className={classes.grow} />
        <WorkgroupRuleSwitch defaultBehavior={props.defaultBehavior} />
      </div>
      <AddressingComponent
        viewMode={viewMode}
        cleanupMode={props.cleanupMode}
        style={{ height: '100%', flex: '1 1 0' }}
        workgroupId={workgroupId}
        channelId={channelId}
        mediaId={mediaId}
        mediaDenies={
          mediaId
            ? undefined
            : [
                {
                  idMediaChannel: channelId,
                  idMedia: mediaId,
                  idEntity: 1,
                  idEntityType: EntityType.Workgroup,
                  deny: true,
                },
              ]
        }
        placeholder={<AddressingSkeleton />}
        nodeRenderer={AddresingNode}
      />
    </div>
  );
};
