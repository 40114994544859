import {
    AddressingContextData
  } from "../duck";
import { AddressingGridContext } from "addressing/context/addressing.context";
import { useContext } from "react";
  
export const useAddressingContextMediaId = () => {
  const context = useContext<AddressingContextData>(AddressingGridContext);
  return context.mediaId;
};

export const useAddressingContextChannelId = () => {
  const context = useContext<AddressingContextData>(AddressingGridContext);
  return context.channelId;
};

export const useAddressingContextWorkgroupId = () => {
  const context = useContext<AddressingContextData>(AddressingGridContext);
  return context.workgroupId;
};

  