import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ZoneSelectors } from 'zone';
import { LayoutActions, MainLayoutArea } from './duck';

export const useRedirect = () => {
  const [initialized, setInitialized] = React.useState(false);
  const history = useHistory();
  const zones = useSelector(ZoneSelectors.selectZones);
  const dispatch = useDispatch();

  useEffect(() => {
    if (zones && history) {
      if (!zones.overHead.length && !zones.onHold.length && zones.initialized) {
        history.push('/unauthorized');
        dispatch(LayoutActions.selectMainArea(MainLayoutArea.Unauthorized, 0));
        setInitialized(true);
      } else if (zones.initialized && !initialized) {
        history.push('/library');
        setInitialized(true);
      }
    }
  }, [zones, history, initialized]);
};
