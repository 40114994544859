import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const UnauthorizedPage = () => {
  const [t] = useTranslation();
  return (
    <Typography variant={'subtitle2'} align="center">
      {t('unauthorized')}
    </Typography>
  );
};
