import React from 'react';

import { IconButton, Tooltip } from '@material-ui/core';

import { ButtonItem } from './duck/types';
import { useStyles } from './AppBarButton.jss';

interface AppBarButtonProps extends ButtonItem {

}

export const AppBarButton: React.FunctionComponent<AppBarButtonProps> = (props) => {
  const classes = useStyles();
  const button = (
    <IconButton className={classes.appBarButton} onClick={props.onClick} disabled={props.disabled}>
      {props.icon && props.icon()}
    </IconButton>
  );
  return props.disabled ? (
    button
  ) : (
    <Tooltip
      title={props.tooltip ?? ''}
      key={`Button_${props.icon ? props.icon.toString() : ''}`}
    >
      {button}
    </Tooltip>
  );
};

