import { FormListSwitchItem } from 'components';
import { useField } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldInnerWrapper } from 'react-tools';
import { DateTimeUtils } from 'utils';
import {
  getHMSFromMS,
  getMSFromHMS,
  getValueWithLimits
} from 'utils/dateTimeUtils';

import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import Timer from '@material-ui/icons/Timer';

import { useStyles } from './FormMediaDurationListItem.jss';

const DEFAULT_DURATION = 10000;
type DurationComponent = 'hours' | 'minutes' | 'seconds';

export const FormMediaDurationListItem: React.FunctionComponent = (props) => {
  const [field, meta, helpers] = useField('duration');
  const classes = useStyles();
  const [hours, minutes, seconds] = DateTimeUtils.getHMSFromMS(field.value ? field.value : 0);

  const onSwitchChange = useCallback(
    (on: boolean) => {
      helpers.setValue(on ? DEFAULT_DURATION : undefined);
    },
    [helpers]
  );

  const onFieldChange = useCallback(
    (name: DurationComponent, value: number) => {
      let ms = 0;
      switch (name) {
        case 'hours':
          ms = DateTimeUtils.getMSFromHMS(value, minutes, seconds);
          break;
        case 'minutes':
          ms = DateTimeUtils.getMSFromHMS(hours, value, seconds);
          break;
        case 'seconds':
          ms = DateTimeUtils.getMSFromHMS(hours, minutes, value);
          break;
      }
      helpers.setValue(ms);
    },
    [hours, minutes, seconds, helpers]
  );

  return (
    <FormListSwitchItem
      on={field?.value !== undefined}
      labelKey={'duration'}
      noValueKey="media.duration.error.unavailable"
      value={field.value ? DateTimeUtils.secondsFormat(field.value) : ''}
      icon={<Timer />}
      onSwitchChange={onSwitchChange}
    >
      <ListItem>
        <ListItemText
          inset
          disableTypography
          primary={
            <div className={classes.intervalWrapper}>
              <DurationField name="hours" max={23} value={hours} onChange={onFieldChange} />
              <DurationField name="minutes" max={59} value={minutes} onChange={onFieldChange} />
              <DurationField name="seconds" max={59} value={seconds} onChange={onFieldChange} />
            </div>
          }
        />
      </ListItem>
    </FormListSwitchItem>
  );
};

const DurationField: React.FunctionComponent<{
  name: DurationComponent;
  value: number;
  min?: number;
  max: number;
  onChange: (name: DurationComponent, value: number) => void;
}> = (props) => {
  const [t] = useTranslation();
  const classes = useStyles();
  const { name, onChange, value, min = 0, max } = props;
  const onTexFieldChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(name, parseInt(event.target.value || '0'));
    },
    [name, onChange]
  );
  return (
    <TextFieldInnerWrapper
      label={t(`media.duration.${name}`)}
      type={'number'}
      className={classes.field}
      name={name}
      InputProps={{
        inputProps: {
          min,
          max,
        },
      }}
      variant="outlined"
      value={value.toString()}
      onChange={onTexFieldChanged}
    />
  );
};
