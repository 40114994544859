import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomNav: {
      minHeight: '56px',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  })
);
