import { MSG } from 'App.bootstrap';
import { DirtyDialog, useNavigationHandle } from 'dirty';
import i18next from 'i18next';
import { MessageEdit, MessageEditAppBar, MessageLibrary } from 'library';
import { UnauthorizedPage } from 'pages';
import React, { useCallback, useMemo } from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import {
  PlaylistEdit,
  PlaylistEditAppBar,
  ZoneEdit,
  ZoneEditAppBar,
  ZoneList
} from 'zone';

import AppBar from '@material-ui/core/AppBar';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';

import { AppBarButton } from './AppBarButton';
import { AppBarContextProvider } from './AppBarContext';
import { BottomNav } from './BottomNav';
import { DrawerContent } from './DrawerContent';
import { useHeaderButtons } from './duck/hooks';
import { useStyles } from './Layout.jss';
import { SplashScreen } from './SplashScreen';

const routes = [
  {
    path: '/library/message/:id',
    exact: true,
    appBar: () => <MessageEditAppBar />,
    main: () => <MessageEdit />,
    bottom: () => null,
  },
  {
    path: '/library',
    exact: true,
    appBar: () => <Typography variant="h6">{i18next.t('library')}</Typography>,
    main: () => <MessageLibrary />,
    bottom: () => <BottomNav />,
  },
  {
    path: '/zones',
    exact: true,
    appBar: () => <Typography variant="h6">{i18next.t('zones')}</Typography>,
    main: () => <ZoneList />,
    bottom: () => <BottomNav />,
  },
  {
    path: '/zone/:zoneId',
    exact: true,
    appBar: () => <ZoneEditAppBar />,
    main: () => <ZoneEdit />,
    bottom: () => <BottomNav />,
  },
  {
    path: '/zone/:zoneId/create',
    exact: true,
    appBar: () => <PlaylistEditAppBar />,
    main: () => <PlaylistEdit />,
    bottom: () => null,
  },
  {
    path: '/zone/:zoneId/:playlistId',
    exact: true,
    appBar: () => <PlaylistEditAppBar />,
    main: () => <PlaylistEdit />,
    bottom: () => null,
  },
  {
    path: '/unauthorized',
    exact: true,
    appBar: () => <></>,
    main: () => <UnauthorizedPage />,
    bottom: () => null,
  },
];

const MemoizedSpashScreen = React.memo(() => <SplashScreen />);

export const LayoutWithRouter: React.FunctionComponent<{}> = () => {
  return (
    <>
      <HashRouter basename={process.env.REACT_APP_ROUTE_BASE}>
        <MemoizedSpashScreen />
        <AppBarContextProvider>
          <Layout />
        </AppBarContextProvider>
      </HashRouter>
    </>
  );
};

const Layout = () => {
  useNavigationHandle();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const buttons = useHeaderButtons();

  const buttonContainerStyle = useMemo(() => ({ minWidth: `${buttons ? 36 + buttons.length * 18 : 36}px` }), [buttons]);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [setMobileOpen, mobileOpen]);

  return (
    <div className={classes.root}>
      <Hidden lgUp implementation="css">
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="back home"
              edge="start"
              className={classes.homeButton}
              onClick={MSG.homeFn}
            >
              <HomeIcon />
            </IconButton>
            <Switch>
              {routes.map((route, index) => (
                <Route key={index} path={route.path} exact={route.exact} children={<route.appBar />} />
              ))}
            </Switch>
            <div style={buttonContainerStyle}>
              {buttons && buttons.map((button, index: number) => <AppBarButton key={index} {...button} />)}
            </div>
          </Toolbar>
        </AppBar>
      </Hidden>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden lgUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <DrawerContent />
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <DrawerContent />
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.main}>
        <Hidden lgUp implementation="css">
          <div className={classes.toolbar} />
        </Hidden>
        <div className={classes.content}>
          <Switch>
            <Redirect exact from="/" to="/library" />
            {routes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact} children={<route.main />} />
            ))}
          </Switch>
        </div>
        <Switch>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} exact={route.exact} children={<route.bottom />} />
          ))}
        </Switch>
      </main>
      <DirtyDialog />
    </div>
  );
};
