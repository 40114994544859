import { DirtyActions } from 'dirty';
import * as DirtySelectors from './selectors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ActionTypes } from './types';

type CallbackFn = () => void;

type SetDirtyFn = (value: boolean) => {
  type: ActionTypes.SET_DIRTY;
  payload: {
    type: string | string[];
    value: boolean;
  };
};

type UseDirtyFn = (
  type: string
) => {dirty: boolean, setDirty: SetDirtyFn};

export const useDirty: UseDirtyFn = (type: string) => {
  const dispatch = useDispatch();
  const dirty = useSelector(DirtySelectors.selectDirty(type));

  useEffect(() => {
    dispatch(DirtyActions.initDirtyInstance(type));

    return () => {
      dispatch(DirtyActions.clearDirtyInstance(type));
      return ;
    }
  }, [dispatch, type]);

  const setDirty = useCallback(
    (value: boolean) => dispatch(DirtyActions.setDirty(type, value)),
    [dispatch, type]
  );

  return {
    dirty,
    setDirty,
  };
};


type OpenDialogFn = (
  title?: string,
  content?: string
) => {
  type: ActionTypes.OPEN_DIALOG;
  payload: {
    issuer: string;
    title: string | undefined;
    content: string | undefined;
  };
};

type CleanDirtyFn = () => {
  type: ActionTypes.SET_DIRTY;
  payload: {
    type: string | string[];
    value: boolean;
  };
};

type UseDirtyDialogFn = (
  types?: string |string[],
  onConfirm?: CallbackFn,
  onCancel?: CallbackFn
) => {dirty: boolean, clearDirty: CleanDirtyFn, confirmClicked: boolean, openDialog: OpenDialogFn};

export const useDirtyDialog: UseDirtyDialogFn = (types?: string | string[], onConfirm?: CallbackFn, onCancel?: CallbackFn) => {
  const dispatch = useDispatch();
  const [idn] = useState(Math.random().toString(16).slice(2, 6));
  const issuer = useSelector(DirtySelectors.selectIssuer);
  const confirmClicked = useSelector(DirtySelectors.selectConfirmClicked);
  const cancelClicked = useSelector(DirtySelectors.selectCancelClicked);
  const instances = useSelector(DirtySelectors.selectInstances(typeof types === 'string' ? [types] : types));
  const dirty = Object.keys(instances).reduce<boolean>((acc, type) => acc || instances[type].value, false);

  const typesList = useMemo(() => {
    if (Array.isArray(types)) {
      return types;
    }

    if (types === undefined) {
      return Object.keys(instances);
    }

    return [types];
  }, [types, instances]);

  const openDialog = useCallback(
    (title?: string, content?: string) => dispatch(DirtyActions.openDialog(idn, title, content)),
    [dispatch, idn]
  );

  const clearDirty = useCallback(
    () => dispatch(DirtyActions.setDirty(typesList, false)),
    [dispatch, typesList]
  );

  useEffect(() => {
    if (confirmClicked && (issuer === idn)) {
      dispatch(DirtyActions.clearDialog());
      clearDirty();

      if (onConfirm) {
        onConfirm();
      }
    }
  }, [confirmClicked, issuer, idn, dispatch, clearDirty, onConfirm]);

  useEffect(() => {
    if (cancelClicked && (issuer === idn)) {
      dispatch(DirtyActions.clearDialog());

      if (onCancel) {
        onCancel();
      }
    }
  }, [cancelClicked, issuer, idn, dispatch, onCancel]);

  return {
    dirty,
    clearDirty,
    confirmClicked,
    openDialog,
  };
};

