import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      flexGrow: 1,
      overflow: 'auto',
      marginTop: theme.spacing(1),
    },
    searchBox: {
      marginTop: theme.spacing(2),
    },
    inline: {
      display: 'inline',
    },
  })
);
