import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldInnerWrapper } from 'react-tools';

import {
  ListItem,
  ListItemText,
  ListItemTypeMap,
  TextFieldProps
} from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';

export interface FormTextFieldListItemProps {
  fieldName: string;
  label?: string;
  maxLength?: number;
  inset?: boolean;
  icon?: React.ReactElement;
  disableGutters?: boolean;
  TextFieldProps?: TextFieldProps;
}
export const FormTextFieldListItem: React.FunctionComponent<FormTextFieldListItemProps> = (props) => {
  const [field, meta, helpers] = useField(props.fieldName);
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      helpers.setValue(e.target.value);
    },
    [helpers.setValue]
  );
  const [t] = useTranslation();
  return (
    <ListItem disableGutters={props.disableGutters}>
      {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
      <ListItemText disableTypography inset={props.inset}>
        <TextFieldInnerWrapper
          fullWidth
          inputProps={{ maxLength: props.maxLength ? props.maxLength : 255 }}
          variant="filled"
          label={props.label ? t(props.label) : undefined}
          error={meta.error !== undefined}
          value={field.value}
          onChange={onChange}
          onBlur={field.onBlur}
          {...props.TextFieldProps}
          helperText={meta.error}
        />
      </ListItemText>
    </ListItem>
  );
};
