import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(-2),
      },
      width: '100%',
    },
    nameField: {
      fontFamily: theme.typography.h5.fontFamily,
      fontSize: theme.typography.h5.fontSize,
      marginTop: theme.spacing(1),
    },
    listForm: {
      flexGrow: 1,
    },
    rangeArrow: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    playbackIntervalCountInput: {
      margin: theme.spacing(0, 1),
      width: '100px',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    outlineInputContainer: {
      display: 'inline-flex',
      flexDirection: 'column',
    },
  })
);
