import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { InputBaseInnerWrapper } from 'react-tools';

import { InputBaseProps } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { useStyles } from './FullWidthSearchBox.jss';

export interface SearchBoxClasses extends InputBaseProps {
  searchBoxRootClass?: string;
}

export const FullWidthSearchBox: React.FunctionComponent<SearchBoxClasses> = (props) => {
  const classes = useStyles();
  const { searchBoxRootClass, ...otherProps } = props;
  return (
    <div className={clsx([classes.search, searchBoxRootClass])}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>

      <InputBaseInnerWrapper
        inputProps={{ maxLength: 25 }}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        autoComplete="off"
        {...otherProps}
      />
    </div>
  );
};
