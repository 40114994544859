import { MasterDetails } from 'components';
import { useDirtyDialog } from 'dirty';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MessageType } from '@models';

import { formType } from '../duck';
import { MessageEdit } from '../edit/MessageEdit';
import { Navigator } from '../navigator';
import { EmptySelection } from './EmptySelection';

export interface MessageMasterDetailsProps {
  selectedMessageType?: MessageType;
}

export const MessageMasterDetails: React.FunctionComponent<MessageMasterDetailsProps> = (props) => {
  const [t] = useTranslation();
  const [selected, setSelected] = React.useState(0);
  const [nextSelected, setNextSelected] = React.useState(0);

  const onDirtyConfirm = useCallback(() => {
    setSelected(nextSelected);
    setNextSelected(0);
  }, [setSelected, setNextSelected, nextSelected]);

  const onDirtyCancel = useCallback(() => {
    setNextSelected(0);
  }, [setNextSelected]);

  const { dirty, openDialog } = useDirtyDialog(formType, onDirtyConfirm, onDirtyCancel);

  useEffect(() => {
    setSelected(0);
  }, [props.selectedMessageType]);

  const onSelection = useCallback(
    (id: number) => {
      if (dirty) {
        openDialog(undefined, t('message.discardChangesPrompt.content'));
        setNextSelected(id);
      } else {
        setSelected(id);
      }
    },
    [dirty, selected, setSelected, openDialog, t]
  );

  return (
    <MasterDetails masterSize={8} detailsSize={4} emptySelection={!selected}>
      <Navigator variant="table" selected={selected} onSelection={onSelection} />
      {selected ? <MessageEdit id={selected} /> : <EmptySelection />}
    </MasterDetails>
  );
};
