import { action } from 'typesafe-actions';

import {
  ActionTypes,
  AddressingStructureResponse,
  AddressingViewModes,
  MediaDeny,
  MediaDenyApplyResult
} from './types';

export const fetchAddressingStructureRequest = (channelId: number, workgroupId: number, treeIdentifier: string) =>
  action(ActionTypes.FETCH_ADDRESSING_STRUCTURE_REQUEST, {
    channelId,
    workgroupId,
    treeIdentifier,
  });

export const fetchAddressingStructureSuccess = (
  channelId: number,
  workgroupId: number,
  addressingStructure: AddressingStructureResponse[],
  treeIdentifier: string
) =>
  action(ActionTypes.FETCH_ADDRESSING_STRUCTURE_SUCCESS, {
    channelId,
    workgroupId,
    treeIdentifier,
    addressingStructure,
  });

export const fetchAddressingStructureError = (err: string, treeIdentifier: string) =>
  action(ActionTypes.FETCH_ADDRESSING_STRUCTURE_ERROR, { err, treeIdentifier });

export const fetchAddressingRulesRequest = (channelId: number, mediaId: number, treeIdentifier: string) =>
  action(ActionTypes.FETCH_ADDRESSING_RULES_REQUEST, { channelId, mediaId, treeIdentifier });

export const fetchAddressingRulesSuccess = (
  channelId: number,
  mediaId: number,
  addresingRules: MediaDeny[],
  treeIdentifier: string
) =>
  action(ActionTypes.FETCH_ADDRESSING_RULES_SUCCESS, {
    channelId,
    mediaId,
    treeIdentifier,
    addresingRules,
  });

export const updateAddressingTreeStart = (treeIdentifier: string) =>
  action(ActionTypes.UPDATE_ADDRESSING_TREE, { treeIdentifier });

export const clearAddressingStructure = (treeIdentifier: string) =>
  action(ActionTypes.CLEAR_ADDRESSING_STRUCTURE, { treeIdentifier });

export const clearAddressingRules = (treeIdentifier: string) =>
  action(ActionTypes.CLEAR_ADDRESSING_RULES, { treeIdentifier });

export const clearAddressingFull = (treeIdentifier: string[] = []) =>
  action(ActionTypes.CLEAR_ADDRESSING_FULL, treeIdentifier);

export const fetchAddressingRulesError = (err: string, treeIdentifier: string) =>
  action(ActionTypes.FETCH_ADDRESSING_RULES_ERROR, { err, treeIdentifier });

export const saveAddressingRules = (treeIdentifier: string) =>
  action(ActionTypes.SAVE_ADDRESSING_RULES, { treeIdentifier });

export const buildAddressingTreeStart = (
  addressingStructure: AddressingStructureResponse[],
  workgroupId: number,
  treeIdentifier: string
) => action(ActionTypes.BUILD_ADDRESSING_TREE, { addressingStructure, workgroupId, treeIdentifier });

export const toggleNodeExpand = ($$hashKey: string, treeIdentifier: string) =>
  action(ActionTypes.TOGGLE_NODE, { $$hashKey, treeIdentifier });

export const processAddressingRulesStart = (newRules: MediaDeny[], treeIdentifier: string) =>
  action(ActionTypes.PROCESS_ADDRESSING_RULES_START, { newRules, treeIdentifier });

export const addressingRulesReset = (treeIdentifier: string) =>
  action(ActionTypes.ADDRESSING_RULES_RESET, { treeIdentifier });


export const processAddressingRulesEnd = (processedRules: MediaDenyApplyResult, treeIdentifier: string) =>
  action(ActionTypes.PROCESS_ADDRESSING_RULES_END, { processedRules, treeIdentifier });

export const subscribeToWebWorker = (treeIdentifier: string) =>
  action(ActionTypes.SUBSCRIBE_TO_WEB_WORKER, { treeIdentifier });

export const setAddressingViewMode = (viewMode: AddressingViewModes, treeIdentifier: string) =>
  action(ActionTypes.SET_ADDRESSING_VIEW_MODE, { viewMode, treeIdentifier });

export const initAddressingInstance = (treeIdentifier: string, clearIfPresent: boolean = false) =>
  action(ActionTypes.INIT_ADDRESSING_INSTANCE, { treeIdentifier, clearIfPresent });

export const initAddressingInstanceByIds = (workgroupId: number, channelId: number, mediaId: number, clearIfPresent: boolean = false) =>
  action(ActionTypes.INIT_ADDRESSING_INSTANCE, { workgroupId, channelId, mediaId, clearIfPresent });
