import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { LibrarySelectors } from '../duck';

export const MessageEditAppBar: React.FunctionComponent = (props) => {
  let params = useParams<{ id: string }>();
  const message = useSelector((state) => LibrarySelectors.selectMessage(state, Number(params.id)));
  if (!message) {
    throw 'No message found for id ' + params.id;
  }
  return <Typography>{message.mediaInfo.name}</Typography>;
};
