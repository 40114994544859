import classNames from 'classnames';
import React from 'react';

import { GridSize } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { useStyles } from './MasterDetails.jss';

export interface MasterDetailsProps {
  emptySelection: boolean;
  masterSize: GridSize;
  detailsSize: GridSize;
}

export const MasterDetails: React.FunctionComponent<MasterDetailsProps> = (props) => {
  const classes = useStyles();
  if (React.Children.count(props.children) !== 2) {
    throw 'MasterDetails component requires exactly two children';
  }

  return (
    <Grid className={classes.grid} container spacing={3}>
      <Grid item xs={props.masterSize} className={classes.gridItemContent}>
        {React.Children.toArray(props.children)[0]}
      </Grid>
      <Grid item xs={props.detailsSize} className={classes.gridItemContent}>
        <Paper
          className={classNames(classes.gridItemPaper, {
            [classes.gridItemEmptySelection]: props.emptySelection,
          })}
        >
          {React.Children.toArray(props.children)[1]}
        </Paper>
      </Grid>
    </Grid>
  );
};
