import { MessageLibraryItem } from 'library';
import { useCallback, useEffect, useState } from 'react';
import { useGrid, useGridApi, useGridEvents } from 'react-tools';

import { ColumnApi, GridApi, RowNode, SelectionChangedEvent } from '@ag-grid-enterprise/all-modules';

export interface useTableSelectionArgs {
  controlledSelectionEnabled: boolean | undefined;
  controlledSelection: number | undefined;
  onItemSelected?: (item: MessageLibraryItem) => void;
}

export const useTableSelection = (args: useTableSelectionArgs) => {
  const [onGridReady] = useGrid();
  const gridApi = useGridApi();

  // selection flow step 1
  const onSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      // save grid's internal selections.
      const selectedRows = event.api.getSelectedRows();

      if (args.controlledSelectionEnabled) {
        // discard grid's internal selection.
        const selectedNodes = event.api.getSelectedNodes();
        if (selectedNodes && selectedNodes.length) {
          selectedNodes[0].setSelected(false, undefined, true);
        }

        // put the props selection back.
        if (args.controlledSelection !== undefined) {
          const row = event.api.getRowNode(args.controlledSelection.toString());
          if (row) {
            row.setSelected(true, undefined, true);
          }
        }
      }

      // send grid's internal selections up.
      if (args.onItemSelected && selectedRows.length > 0) {
        args.onItemSelected(selectedRows[0]);
      }
    },
    [args.onItemSelected, args.controlledSelection]
  );

  // selection flow step 2
  useEffect(() => {
    if (args.controlledSelectionEnabled) {
      if (!gridApi) {
        return;
      }

      const selectedNodes = gridApi.getSelectedNodes();
      const selectedId = selectedNodes.length ? selectedNodes[0].id : undefined;

      // props selection is different than grid's internal selection.
      if (args.controlledSelection !== undefined && args.controlledSelection !== Number(selectedId)) {
        // discard grid's internal selection.
        let row = gridApi.getRowNode(args.controlledSelection.toString());
        if (row) {
          row.setSelected(true, undefined, true);
        }

        // put the props selection back.
        if (row && selectedId) {
          row = gridApi.getRowNode(selectedId);
          (row as RowNode).setSelected(false, undefined, true);
        }
      }
    }
  }, [gridApi, args.controlledSelection, args.controlledSelectionEnabled]);

  return [onGridReady, onSelectionChanged];
};
