import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  ButtonGroup,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import { useStyles } from './DiffusionDaysButtons.jss';

const isDaySelected = (diffusionDays: number, day: string) => {
  return (diffusionDays & diffusionMap[day]) !== 0;
};

const updateDiffusion = (diffusionDays: number, day: string, selected: boolean): number => {
  if (!selected) {
    return diffusionDays & ~diffusionMap[day];
  } else {
    return diffusionDays | diffusionMap[day];
  }
};

const diffusionMap: { [key: string]: number } = {
  monday: 64,
  tuesday: 32,
  wednesday: 16,
  thursday: 8,
  friday: 4,
  saturday: 2,
  sunday: 1,
};

export const DiffusionDaysLabels: React.FunctionComponent = (_) => {
  const classes = useStyles({});
  const [field, meta] = useField('scheduling.diffusionDays');
  const [t] = useTranslation();
  return meta.error ? (
    <Typography variant="caption" color="error" >
      {meta.error}
    </Typography>
  ) : (
    <>
      {Object.keys(diffusionMap)
        .filter((k) => isDaySelected(field.value, k))
        .map((day) => (
          <Typography className={classes.dayLabel} variant="caption" color="textSecondary" key={day}>
            {t(day + '.abv')}
          </Typography>
        ))}
    </>
  );
};

export const DiffusionDaysButtons: React.FunctionComponent = (_) => {
  const classes = useStyles({});
  const [t] = useTranslation();
  const theme = useTheme();
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px)`);
  const [field, meta, helpers] = useField('scheduling.diffusionDays');

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const day = event.currentTarget.getAttribute('data-name');
      if (!day) {
        return;
      }
      const selected = !isDaySelected(field.value, day);
      const newDiffusionDays = updateDiffusion(field.value, day, selected);
      helpers.setValue(newDiffusionDays);
    },
    [field.value, helpers.setValue]
  );

  return (
    <>
      <ButtonGroup size="small" fullWidth={isSm} className={classes.buttonGroup}>
        {Object.keys(diffusionMap).map((day) => (
          <Button
            key={day}
            data-name={day}
            onClick={onClick}
            variant={isDaySelected(field.value, day) ? 'contained' : 'outlined'}
            classes={{ label: classes.buttonLabel }}
            className={classes.button}
          >
            {t(day + '.abv')}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};
