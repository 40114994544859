import { FormListItemText } from 'components/formListItems/FormListItemText';
import { useField, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import VolumeIcon from '@material-ui/icons/VolumeUp';
import { MediaType } from '@models/media/MediaType';

import { useStyles } from './FormMediaVolumeListItem.jss';

export const FormMediaVolumeListItem: React.FunctionComponent = (props) => {
  const [field, meta, helpers] = useField('properties.soundVolume');
  const classes = useStyles();
  const [t] = useTranslation();
  const [value, setValue] = React.useState(field.value);
  const onChangeCommited = React.useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      helpers.setValue(value as number);
    },
    [helpers]
  );
  const innerOnChange = React.useCallback(
    (event: React.ChangeEvent<{}>, value: number | number[]) => {
      setValue(value as number);
    },
    [setValue]
  );

  useEffect(() => {
    if (field.value !== undefined) {
      setValue(field.value);
    } else {
      setValue(0);
    }
  }, [field.value]);

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <VolumeIcon/>
        </ListItemIcon>
        <FormListItemText focused={false} labelKey="media.soundVolume" value={field.value} />
      </ListItem>
      <ListItem className={classes.sliderItem}>
        <ListItemText inset disableTypography>
          <Slider
            className={classes.slider}
            value={value}
            onChange={innerOnChange}
            onChangeCommitted={onChangeCommited}
          />
        </ListItemText>
      </ListItem>
    </>
  );
};
