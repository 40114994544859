import { fade } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      },
    },
    tabPanel: {
      flexGrow: 1,
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
  })
);
