import { LayoutSelectors } from 'layout';
import { LibraryThunks } from 'library';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useFetchLibrary = () => {
  const dispatch = useDispatch();
  const selectedMessageType = useSelector(LayoutSelectors.selectMessageType);

  useEffect(() => {
    if (selectedMessageType !== undefined) {
      dispatch(LibraryThunks.fetchLibrary(selectedMessageType));
    }
  }, [selectedMessageType, dispatch]);
};
