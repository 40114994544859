import { ConfirmationDialog } from 'components/ConfirmationDialog';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import * as Actions from './actions';
import { selectContent, selectOpen, selectTitle } from './selectors';

export const DirtyDialog = () => {
  const [] = useTranslation();
  const dispatch = useDispatch();
  const open = useSelector(selectOpen);
  const title = useSelector(selectTitle);
  const content = useSelector(selectContent);

  const onCancel = useCallback(() => {
    dispatch(Actions.cancelClicked());
  }, [dispatch]);

  const onConfirm = useCallback(() => {
    dispatch(Actions.confirmClicked());
  }, [dispatch]);

  return <ConfirmationDialog title={title} open={open} content={content} onConfirm={onConfirm} onCancel={onCancel} />;
};
