import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    rangeArrow: {
      verticalAlign: 'middle',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    checkbox: {
      marginRight: theme.spacing(2),
    },
  });
};

export const useStyles = makeStyles(styles);
