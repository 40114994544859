import HttpService from 'utils/http';
import { AddressingStructureResponse, MediaDeny } from './types';

export const AddressingApi = {
  fetchAddressingStructure: async (
    channelId: number,
    workgroupId: number
  ): Promise<AddressingStructureResponse[]> => {
    return await HttpService.get<AddressingStructureResponse[]>(`v6/addressingStructure/${channelId}/${workgroupId}`);
  },
  fetchAddressingRules: async (channelId: number, mediaId: number): Promise<MediaDeny[]> => {
    return await HttpService.get<MediaDeny[]>(`v6/addressingRules/${channelId}/${mediaId}`);
  },
  updateAddressing: async (addressingRules: MediaDeny[]) => {
    return await HttpService.post('v6/addressing/batch', addressingRules);
  }
};
