import { Icons } from 'components';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useGridExportEvents } from 'react-tools';
import { validateLocaleAndSetLanguage } from 'typescript';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export const GridExportButton: React.FunctionComponent<{
  fileName?: string;
}> = React.memo((props) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const { fileName } = props;
  const [t] = useTranslation();
  const exportFns = useGridExportEvents();
  const exportCsv = useCallback(() => {
    exportFns?.exportCsv(fileName);
  }, [exportFns, fileName]);
  const exportXlsx = useCallback(() => {
    exportFns?.exportExcel(fileName);
  }, [exportFns, fileName]);
  const handleMenuClose = useCallback(() => {
    setMenuAnchorEl(null);
  }, [setMenuAnchorEl]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchorEl(event.currentTarget);
    },
    [setMenuAnchorEl]
  );

  return (
    <>
      <IconButton onClick={handleClick}>
        <Icons.FileDownloadIcon />
      </IconButton>
      <Menu id="sort-menu" anchorEl={menuAnchorEl} keepMounted open={Boolean(menuAnchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={exportCsv}>{t('export.csv')}</MenuItem>
        <MenuItem onClick={exportXlsx}>{t('export.xlsx')}</MenuItem>
      </Menu>
    </>
  );
});
