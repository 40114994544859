import { MSG } from 'App.bootstrap';
import Axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface ResponseWithVersion<T> {
  version: string;
  data: T;
}

export interface ErrorResponse {
  message: string;
  status: number;
}

export class ConcurrencyError implements ErrorResponse {
  constructor(public message: string, public status: number) {}
}

export class BadRequestError implements ErrorResponse {
  constructor(public message: string, public status: number) {}
}
export class InternalServerError implements ErrorResponse {
  constructor(public message: string, public status: number) {}
}

class HttpService {
  constructor() {
    Axios.interceptors.request.use((config: AxiosRequestConfig) => {
      config.headers['Content-Type'] = 'application/json';

      if (isDevelop()) {
        config.headers[CURRENT_WORKGROUP_HEADER_NAME] = MSG.workgroupId;
      } else {
        config.headers[CURRENT_WORKGROUP_HEADER_NAME] = getCookieValue(currentWorkgroupKey);
      }
      return config;
    });

    Axios.interceptors.response.use(
      (response: AxiosResponse<any>) => response,
      ({ response }: { response: AxiosResponse<string> }) => {
        if (!response) {
          return Promise.reject({
            message: 'Oops, something went wrong',
            status: 0,
          });
        }

        if (response.status === 409) {
          return Promise.reject(new ConcurrencyError(response.data, response.status));
        }

        if (response.status === 400) {
          return Promise.reject(new BadRequestError(response.data, response.status));
        }
        if (response.status === 500) {
          return Promise.reject(new InternalServerError(response.data, response.status));
        }
      }
    );
  }

  async get<T>(url: string, headers?: { [key: string]: string }, data?: any): Promise<T> {
    const response = await Axios.get<T>(`${process.env.REACT_APP_ROOT_DOMAIN}${url}`, { headers, params: data });
    return response.data;
  }

  async getWithVersion<T>(url: string, headers?: { [key: string]: string }): Promise<ResponseWithVersion<T>> {
    const response = await Axios.get<T>(`${process.env.REACT_APP_ROOT_DOMAIN}${url}`, { headers });
    const version = response.headers.etag;
    return {
      data: response.data,
      version,
    };
  }

  async post<T, K = {}>(url: string, body: K = {} as K, headers?: { [key: string]: string }): Promise<T> {
    const response = await Axios.post<T>(`${process.env.REACT_APP_ROOT_DOMAIN}${url}`, body, { headers });
    return response.data;
  }

  async postWithReturnedVersion<T, K = {}>(
    url: string,
    body: K = {} as K,
    headers?: { [key: string]: string }
  ): Promise<{ data: T; version: string }> {
    const response = await Axios.post<T>(`${process.env.REACT_APP_ROOT_DOMAIN}${url}`, body, { headers });
    const version = response.headers.etag;
    return {
      data: response.data,
      version,
    };
  }

  async put<T, K = {}>(url: string, body: K = {} as K, headers?: { [key: string]: string }): Promise<T> {
    const response = await Axios.put<T>(`${process.env.REACT_APP_ROOT_DOMAIN}${url}`, body, { headers });
    return response.data;
  }

  async putWithVersion<T, K = {}>(
    url: string,
    body: K = {} as K,
    version: string,
    headers?: { [key: string]: string }
  ): Promise<{ data: T; version: string }> {
    const response = await Axios.put<T>(`${process.env.REACT_APP_ROOT_DOMAIN}${url}`, body, {
      headers: {
        ...headers,
        ETag: version,
      },
    });
    const returnedVersion = response.headers.etag;
    return {
      data: response.data,
      version: returnedVersion,
    };
  }

  async delete<T, K = {}>(url: string, body: K = {} as K, headers?: { [key: string]: string }): Promise<T> {
    const response = await Axios.delete<T>(`${process.env.REACT_APP_ROOT_DOMAIN}${url}`, {
      headers: {},
      data: {
        ...body,
      },
    });
    return response.data;
  }
}

export default new HttpService();

export const getCookieValue = (key: string): string => {
  const b: string[] | null = document.cookie.match(`(^|;)*${key}*=*([^;]+)`);

  if (b === null) {
    return '';
  }

  const value = b.pop();
  return value ? value : '';
};

export const isDevelop = () => process.env.REACT_APP_DEVELOPMENT === 'true';

export const currentWorkgroupKey: string = 'currentWorkgroup';
export const CURRENT_WORKGROUP_HEADER_NAME: string = process.env.REACT_APP_HEADER_01 as string;
