import React, { useEffect, useState } from "react";
import { AppBarContextData, ButtonItem } from "./duck/types";

export const AppBarContext = React.createContext<AppBarContextData>({
  updateAppBarButtons: () => null,
  buttons: [],
});

export const AppBarContextProvider: React.FC = (props) => {
  const [buttons, setButtons] = useState<ButtonItem[]>([]);

  const value = {
    buttons,
    updateAppBarButtons: (appBarButtons: ButtonItem[]) => (setButtons(appBarButtons)),
  };

  return <AppBarContext.Provider value={value}>{props.children}</AppBarContext.Provider>;
};
