import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      paddingRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        minHeight: theme.spacing(6),
        alignItems: 'flex-end',
      },
    },
    toolbarButton: {
      marginLeft: theme.spacing(2),
    },
    spacer: {
      flexGrow: 1,
    },
    buttonProgress: {
      color: theme.palette.type === 'dark' ? 'secondary' : 'inherit',
    },
  })
);
