import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography, { TypographyProps } from '@material-ui/core/Typography';

import { LibraryActions, LibrarySelectors } from '../duck';
import { useStyles } from './NavigatorBreadcrumbs.jss';

export interface NavigatorBreadcrumbsProps {
  typographyProps?: TypographyProps;
  classes?: {
    root?: string;
    link?: string;
  };
}

export const NavigatorBreadcrumbs: React.FunctionComponent<NavigatorBreadcrumbsProps> = React.memo((props) => {
  const folderPath = useSelector(LibrarySelectors.selectFolderPath);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleLinkClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const id = Number(event.currentTarget.id);
      const folder = folderPath.find((f) => f.id === id);
      if (!folder) {
        return;
      }
      dispatch(LibraryActions.popFolder(folder));
    },
    [folderPath, dispatch]
  );
  return (
    <>
      <Breadcrumbs classes={{ root: props.classes?.root }}>
        <Typography {...props.typographyProps}>Library</Typography>
        {folderPath.map((f) => (
          <Link key={f.id} className={classes.link} id={f.id.toString()} onClick={handleLinkClick} color="inherit">
            <Typography {...props.typographyProps}>{f.name}</Typography>
          </Link>
        ))}
      </Breadcrumbs>
    </>
  );
});
