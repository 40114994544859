import { format, parseJSON } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
  getDateFormatBasedOnBrowser,
  getTimeFormatBasedOnBrowser
} from 'localization/dateLocale';

interface FormatterParams {
  value: any;
  data: any;
}

export const localeDateTimeFormatter = (params: FormatterParams) => {
  const date = utcToZonedTime(parseJSON(params.value), 'UTC');
  return `${format(date, getDateFormatBasedOnBrowser())} ${format(date, getTimeFormatBasedOnBrowser())}`;
};

export const localeDateFormatter = (params: FormatterParams) =>
{
  if(!params.value) {
    return '';
  }
  const date = parseJSON(params.value);
  const dateUtc = utcToZonedTime(date, 'UTC');
  return params.data.mediaInfo.isFolder ? '' : format(dateUtc, getDateFormatBasedOnBrowser());
}  

export const localeTimeFormatter = (params: FormatterParams) =>
  params.data.mediaInfo.isFolder ? '' : format(utcToZonedTime(parseJSON('0001-01-01T' + params.value), 'UTC'), getTimeFormatBasedOnBrowser());

export const dateStringComparator = (filterLocalDateAtMidnight: Date, cellValue: string) => {
  const cellDate = new Date(cellValue);
  if (cellDate < filterLocalDateAtMidnight) {
    return -1;
  } else if (cellDate > filterLocalDateAtMidnight) {
    return 1;
  }
  return 0;
};

