import React from 'react';

import List from '@material-ui/core/List';

import { FileUploadResult } from './types';
import { useStyles } from './uploader.jss';
import { UploadFile } from './uploadFile';

export interface UploadFileListProps {
  files: File[];
  folderId?: number;
  removeFile: (file: File) => void;
  chunkSize: number;
  canUpload: boolean;
  filesAreContent: boolean;
  onFileComplete?: (result: FileUploadResult) => void;
}

export const UploadFileList: React.FunctionComponent<UploadFileListProps> = React.memo((props) => {
  const classes = useStyles();
  return (
    <div className={classes.filesZone}>
      <List>
        {props.files.map((f) => (
          <UploadFile
            key={f.name}
            file={f}
            folderId={props.folderId}
            removeFile={props.removeFile}
            filesAreContent={props.filesAreContent}
            chunkSize={props.chunkSize}
            startUpload={props.canUpload}
            onFileComplete={props.onFileComplete}
          />
        ))}
      </List>
    </div>
  );
});
