import { LayoutWithRouter } from 'layout';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { NotifierContainer } from 'react-tools';

import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';

import { moodThemeDark } from './App.jss';

const generateClassName = createGenerateClassName({
  productionPrefix: 'msga',
  disableGlobal: true,
});

function App() {
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={moodThemeDark}>
        <ScopedCssBaseline style={{ height: '100%' }}>
          <SnackbarProvider maxSnack={3}>
            <>
              <NotifierContainer />
              <LayoutWithRouter />
            </>
          </SnackbarProvider>
        </ScopedCssBaseline>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
