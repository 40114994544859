import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddressingActions, AddressingSelectors, fetchAddressingStructure, TreeNode } from '../duck';

const useAddressingStructure = (channelId: number = 0, workgroupId: number = 0, treeId: string) => {
  const dispatch = useDispatch();
  const addressingStructureState = useSelector(AddressingSelectors.selectAddressingStructure(treeId));
  const addressingInstanceState = useSelector(AddressingSelectors.selectAddressingInstance(treeId));

  // effect used to load addressing structure data
  useEffect(() => {
    // if there's not state for this addressing instance (treeId) abort
    if (!addressingInstanceState) {
      return;
    }

    const isFetching = addressingStructureState.fetchStatus.isFetching;
    const fetchComplete = addressingStructureState.fetchStatus.complete;

    // we need to fetch addressing structure
    if (!isFetching && !fetchComplete) {
      dispatch(fetchAddressingStructure(channelId, workgroupId, treeId));
    }
  }, [
    addressingInstanceState,
    addressingStructureState.fetchStatus.complete,
    addressingStructureState.fetchStatus.isFetching,
    channelId,
    workgroupId,
    treeId,
    dispatch,
  ]);

  const toggleExpandNode = useCallback(
    (node: TreeNode) => {
      dispatch(AddressingActions.toggleNodeExpand(node.$$hashKey, treeId));
    },
    [treeId, dispatch]
  );

  return {
    processedData: addressingStructureState.processedData,
    fetchStatus: addressingStructureState.fetchStatus,
    processingStatus: addressingStructureState.processingStatus,
    toggleExpandNode: toggleExpandNode,
  };
};

export default useAddressingStructure;
