import { FormTextFieldListItem } from 'components';
import { useField } from 'formik';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TextFieldInnerWrapper } from 'react-tools';

import { List, ListItem, ListItemText } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import NotesIcon from '@material-ui/icons/Notes';

export const FormMediaDescriptionListItem: React.FunctionComponent = (props) => (
  <FormTextFieldListItem
    fieldName="description"
    icon={<NotesIcon />}
    label={'media.description'}
    TextFieldProps={{ multiline: true, rowsMax: 3 }} />);
