import { createSelector } from 'reselect';

import {
  addressingAreaName,
  AddressingRulesState,
  AddressingState,
  AddressingViewModes
} from './types';
import { generateTreeIdentifier } from './utils';

export const selectAddressingState = (state: any): AddressingState => {
  return state[addressingAreaName];
};

export const selectAddressingInstance = (treeId: string) => {
  return createSelector([selectAddressingState], (state) => state[treeId]);
};
export const selectAddressingStructure = (treeId: string) => {
  return createSelector([selectAddressingState], (state) => state[treeId].addressingStructure);
};
export const selectAddressingRules = (treeId: string) => {
  return createSelector([selectAddressingState], (state) => state[treeId]?.addressingRules);
};
export const selectNeedToUpdateTree = (treeId: string) => {
  return createSelector([selectAddressingState], (state) => state[treeId].needToUpdateTree);
};
export const selectAddressingViewMode = (treeId: string) => {
  return createSelector([selectAddressingState], (state) => state[treeId]?.viewMode || AddressingViewModes.AZ);
};

// select a specific set of (updated) addressing rules based on wk, channel and media
export const selectSpecificAddressingRules = (workgroupId: number, channelId: number, mediaId: number) => {
  const treeId = generateTreeIdentifier(workgroupId, channelId, mediaId);
  return createSelector([selectAddressingState], (state) => {
    if (!state[treeId]) {
      return [];
    }

    return state[treeId].addressingRules.rawData;
  });
};

// select all updated addressing rules from all the addressing instances
export const selectAllAddressingRules = createSelector([selectAddressingState], (state) => {
  return Object.keys(state).reduce<{ [key: string]: AddressingRulesState }>((acc, treeId) => {
    acc[treeId] = state[treeId].addressingRules;
    return acc;
  }, {});
});

export const selectInitializationComplete = (treeId: string) => {
  return createSelector([selectAddressingState], (state) => state[treeId]?.initializationComplete);
};
