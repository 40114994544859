import { fade } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      flexGrow: 1,
      minHeight: 0,
      overflowY: 'auto',
    },
  })
);
