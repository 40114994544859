import { action } from 'typesafe-actions';

import { ActionTypes, MainLayoutArea } from './types';

export const selectMainArea = (mainArea: MainLayoutArea, selectedItem: number) =>
  action(ActionTypes.MENU_SELECTION, { mainArea, selectedItem });

export const markSplashScreenAsShown = () => action(ActionTypes.MARK_SPLASH_SCREEN_AS_SHOWN);

export const selectZone = () => action('DUMMY');
