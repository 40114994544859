import { MessageType } from '@models';

import { layoutAreaName, LayoutState, MainLayoutArea } from './types';

export const selectLayoutState = (state: any): LayoutState => state[layoutAreaName];

export const selectMessageType = (state: any): MessageType | undefined => {
  switch (state[layoutAreaName].selectedMainArea) {
    case MainLayoutArea.LibraryOnHold:
    case MainLayoutArea.ZonesOnHold:
      return MessageType.OnHold;
    case MainLayoutArea.LibraryOverhead:
    case MainLayoutArea.ZonesOverhead:
      return MessageType.Overhead;
    default:
      return undefined;
  }
};

export const selectSplashScreenShown = (state: any) => state[layoutAreaName].splashScreenShown;
