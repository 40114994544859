export enum ContentFileState {
  Uploading = 1,
  Uploaded = 2,
  Ready = 3,
  Reencoding = 4,
  Reencoded = 5,
  ReencodedWithErrors = 6,
  WaitingForReencoding = 7,
  WaitingForUpload = 8,
  UploadedWithError = 9,
  WaitingForMetaInfo = 10,
}
