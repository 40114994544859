import { NumberFilter } from 'ag-grid-community';
import { action } from 'typesafe-actions';

import { MediaDto, MediaTypes, MessageType } from '@models';

import { ActionTypes, Zone } from './types';

export const fetchZonesRequest = (workgroupId: number) => action(ActionTypes.FETCH_ZONES_REQUEST, { workgroupId });

export const fetchZonesSuccess = (workgroupId: number, overHeadZones: Zone[], onHoldZones: Zone[]) =>
  action(ActionTypes.FETCH_ZONES_SUCCESS, {
    workgroupId,
    overHeadZones,
    onHoldZones,
  });

export const fetchZonePlaylistsRequest = (zoneId: number) =>
  action(ActionTypes.FETCH_ZONE_PLAYLISTS_REQUEST, { zoneId });

export const fetchZonePlaylistsSuccess = (zoneId: number, playlists: MediaTypes.Playlist.PlaylistDto[]) =>
  action(ActionTypes.FETCH_ZONE_PLAYLISTS_SUCCESS, { zoneId, playlists });

export const fetchPlaylistInfoRequest = (playlistId: number) =>
  action(ActionTypes.FETCH_PLAYLIST_REQUEST, { playlistId });

export const fetchPlaylistInfoSuccess = (playlistId: number, playlist: MediaTypes.Playlist.PlaylistDto, messages: MediaDto[]) =>
  action(ActionTypes.FETCH_PLAYLIST_SUCCESS, {
    playlistId,
    playlist,
    messages,
  });

export const fetchPlaylistInfoFail = (error: string) => action(ActionTypes.FETCH_PLAYLIST_FAIL, { error });

export const savePlaylistRequest = () => action(ActionTypes.SAVE_PLAYLIST_REQUEST);

export const savePlaylistSuccess = (playlistInfo: MediaTypes.Playlist.PlaylistDto, messages: MediaDto[]) =>
  action(ActionTypes.SAVE_PLAYLIST_SUCCESS, { playlistInfo, messages });

export const savePlaylistFail = () => action(ActionTypes.SAVE_PLAYLIST_FAIL);

export const deleteZonePlaylistRequest = (playlistId: number) =>
  action(ActionTypes.DELETE_ZONE_PLAYLIST_REQUEST, { playlistId });

export const deleteZonePlaylistSuccess = (playlistId: number) =>
  action(ActionTypes.DELETE_ZONE_PLAYLIST_SUCCESS, { playlistId });

export const deleteZonePlaylistFail = () => action(ActionTypes.DELETE_ZONE_PLAYLIST_FAIL);

export const clearNewlyAddedPlaylistId = () => action(ActionTypes.CLEAR_NEWLY_CREATED_PLAYLIST_ID);

export const setSelectedPlaylistMessages = (messages: MediaDto[]) =>
  action(ActionTypes.SET_SELECTED_PLAYLIST, { messages });
