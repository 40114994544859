import { MessageLibraryItem } from 'library';
import React from 'react';

import { RowNode } from '@ag-grid-enterprise/all-modules';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import FolderIcon from '@material-ui/icons/Folder';

interface MediaNameIconRendererProps {
  node: RowNode;
}

const useStyles = makeStyles(({ spacing }: Theme) =>
  createStyles({
    icon: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
    },
    label: {
      marginLeft: spacing(6),
    },
  })
);

export const MediaNameIconRenderer: React.FunctionComponent<MediaNameIconRendererProps> = (props) => {
  const classes = useStyles();
  const icon = props.node.data.mediaInfo.isFolder ? (
    <FolderIcon className={classes.icon} color="primary" />
  ) : (
    <AudiotrackIcon className={classes.icon} />
  );
  return (
    <>
      {icon}
      <span className={classes.label}>{props.node.data.mediaInfo.name}</span>
    </>
  );
};
