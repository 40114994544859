import { WritableDraft } from 'immer/dist/internal';

import { AddressingStructure, MediaDenyApplyResult } from './types';

type BucketMapKey = keyof typeof bucketMap;

const bucketMap = {
  Workgroup: 'bucketWorkgroups',
  SiteGroup: 'bucketGroups',
  Site: 'bucketSites',
  Player: 'bucketPlayers',
  Stream: 'bucketStream',
};

// fucking typescript !!!
const getDenyMap = (model: BucketMapKey, denies: MediaDenyApplyResult): { [key: string]: number } =>
  denies[bucketMap[model] as keyof MediaDenyApplyResult];

export const applyDeniesOnTree = (addressingTrees: AddressingStructure[][], denies: MediaDenyApplyResult) => {
  //0 - bucketSites
  //1 - bucketWorkgroups
  //2 - bucketGroups
  //3 - rules views
  addressingTrees.forEach((rootNodeArray) => {
    rootNodeArray.forEach((node: AddressingStructure) => {
      const denyMap = getDenyMap(node.modelType as BucketMapKey, denies);

      if (denyMap) {
        let newHash = node.$$hashKey;

        if (newHash.substr(0, 5) === 'clone') {
          let position = newHash.indexOf('-');
          newHash = newHash.substring(position + 1);
        }

        node.chckState = denyMap[newHash];
      }

      setValueForChildren(node, denies);
    });
  });
  return addressingTrees;
};

export const searchTree = (
  addressingTree: WritableDraft<AddressingStructure>[] | AddressingStructure[],
  $$hashKey: string
): WritableDraft<AddressingStructure> | AddressingStructure | null => {
  let result: WritableDraft<AddressingStructure> | AddressingStructure | null;

  for (const node of addressingTree) {
    if (node.$$hashKey === $$hashKey) {
      return node;
    } else {
      result = findNode(node, $$hashKey);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

export const findNode = (
  addressingTree: WritableDraft<AddressingStructure> | AddressingStructure,
  $$hashKey: string
): WritableDraft<AddressingStructure> | AddressingStructure | null => {
  let node = addressingTree.children.find((n) => n.$$hashKey === $$hashKey) || null;

  if (node) {
    return node;
  } else {
    node = null;
    for (const n of addressingTree.children) {
      node = findNode(n, $$hashKey);
      if (node) {
        break;
      }
    }
    return node;
  }
};

const setValueForChildren = (currentNode: AddressingStructure, denies: MediaDenyApplyResult) => {
  currentNode.children.forEach((child) => {
    const denyMap = getDenyMap(child.modelType as BucketMapKey, denies);

    if (denyMap) {
      let newHash = child.$$hashKey;

      if (newHash.substr(0, 5) === 'clone') {
        let position = newHash.indexOf('-');
        newHash = newHash.substring(position + 1);
      }

      child.chckState = denyMap[newHash];
    }

    setValueForChildren(child, denies);
  });
};

export const generateTreeIdentifier = (workgroupId: number, channelId: number, mediaId: number) => {
  return `${workgroupId}_${channelId}_${mediaId}`;
};
