import { MediaForms } from 'components';
import { MediaFormData } from 'components/mediaEdit/forms';
import { format } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import deepClone from 'lodash-es/cloneDeep';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { MediaUpdateDto } from '@models';

import {
  AddressingCleanUp,
  AddressingSelectors,
  MediaDeny
} from '../../addressing';
import { AddressingContextProvider } from '../../addressing/context/addressing.context';
import { MSG } from '../../App.bootstrap';
import { LibrarySelectors, LibraryThunks } from '../duck';
import { clearLibrarySelection } from '../duck/actions';
import { MessageEditForm } from './MessageEditForm';

export interface MessageEditProps {
  id?: number;
  channelId?: number;
  onSave?: () => void;
  onCancel?: () => void;
}

export const MessageEdit = React.forwardRef<FormikProps<any>, MessageEditProps>((props, _) => {
  const history = useHistory();
  const dispatch = useDispatch();

  let { id: routeId } = useParams<{ id: string }>();
  let { id: propsId } = props;
  let messageId = Number(propsId);
  if (!messageId) {
    if (!routeId) {
      history.push('/library');
    } else {
      messageId = Number(routeId);
    }
  }

  const message = useSelector((state) => LibrarySelectors.selectMessage(state, messageId));
  const addressingRules = useSelector(AddressingSelectors.selectAllAddressingRules);

  useEffect(() => {
    dispatch(LibraryThunks.fetchMessage(messageId));
  }, [messageId, dispatch]);

  // clear message selection when unmounting component
  useEffect(() => {
    return () => {
      dispatch(clearLibrarySelection());
    };
  }, [dispatch]);

  const onSave = useCallback(
    (values: MediaFormData) => {
      if (!message) {
        return;
      }

      let addressing: { [key: string]: MediaDeny[] } = {};

      if (addressingRules) {
        // we make a temporary copy so no funny business takes places
        const tempCopy = deepClone(addressingRules);

        for (const [addrId, addrRules] of Object.entries(tempCopy)) {
          addressing[addrId] = addrRules.rawData;
        }
      }

      const updateDto: MediaUpdateDto = {
        id: message.mediaInfo.id,
        name: values.name,
        description: values.description,
        startDate: format(values.scheduling.startDate, 'yyyy-MM-dd 00:00:00'),
        endDate: format(values.scheduling.endDate, 'yyyy-MM-dd 00:00:00'),
        startTime: format(values.scheduling.startTime, 'HH:mm:ss'),
        endTime: format(values.scheduling.endTime, 'HH:mm:ss'),
        timezone: message.mediaInfo.timezone,
        diffusionDays: values.scheduling.diffusionDays,
        endMedia: message.mediaInfo.endMedia,
        mediaFolderId: message.mediaInfo.mediaFolderId,
        mediaType: message.mediaInfo.mediaType as any,
        properties: { ...values.properties },
        duration: values.duration,
      };
      dispatch(LibraryThunks.saveMessage(updateDto, addressing));

      if (props.onSave) {
        props.onSave();
      }
    },
    [message, addressingRules, dispatch, props.onSave]
  );

  // we use this so that when memoFormik updates because of the onSave dependency
  // (which in turn updates because of addressingRules changes) it doesn't reinitialize
  // the form data and loose all the changes
  const formData = useMemo(() => MediaForms.mediaDtoToFormData(message?.mediaInfo), [message]);

  const memoFormik = useMemo(() => {
    console.info(JSON.stringify(formData));
    return (
      <Formik
        initialValues={formData}
        validationSchema={MediaForms.mediaFormSchema}
        enableReinitialize
        onSubmit={onSave}
      >
        <MessageEditForm message={message} onCancel={props.onCancel} channelId={props.channelId} />
      </Formik>
    );
  }, [onSave, props.channelId, message, props.onCancel]);

  const addressingCleanup = useMemo(() => {
    return { [AddressingCleanUp.ON_MEDIA_CHANGE]: true, [AddressingCleanUp.ON_UNMOUNT]: true };
  }, []);

  return (
    <AddressingContextProvider
      {...{
        mediaId: message?.mediaInfo.id ?? 0,
        channelId: props.channelId ?? 0,
        workgroupId: MSG.workgroupId,
        cleanupMode: addressingCleanup,
      }}
    >
      {memoFormik}
    </AddressingContextProvider>
  );
});
