import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  useAddressingContextChannelId,
  useAddressingContextMediaId,
  useAddressingContextWorkgroupId,
} from '../context/hooks';
import { AddressingActions, AddressingCleanUp, AddressingSelectors } from '../duck';
import { generateTreeIdentifier } from '../duck/utils';
import { AddressingTree, AddressingTreeProps } from './AddressingTree';

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
// AddressingComponentProps is similar to AddressingTreeProps, except for making the wk, channel and media optional
type AddressingComponentProps = PartialBy<AddressingTreeProps, 'workgroupId' | 'channelId' | 'mediaId'> & {
  cleanupMode: AddressingCleanUp;
};

export const AddressingComponent: React.FunctionComponent<AddressingComponentProps> = (props) => {
  const dispatch = useDispatch();
  const contextWorkgrouplId = useAddressingContextWorkgroupId();
  const contextChannelId = useAddressingContextChannelId();
  const contextMediaId = useAddressingContextMediaId();
  const workgroupId = props.workgroupId ? props.workgroupId : contextWorkgrouplId;
  const channelId = props.channelId ? props.channelId : contextChannelId;
  const mediaId = props.mediaId ? props.mediaId : contextMediaId;
  const cleanUpRef = useRef(props.cleanupMode);
  cleanUpRef.current = props.cleanupMode;
  const initialWorkgroupId = useRef(workgroupId);
  const initialChannelId = useRef(channelId);
  const initialMediaId = useRef(mediaId);
  const treeIdentifier = generateTreeIdentifier(workgroupId, channelId, mediaId);
  const initialTreeIdentifier = useRef(treeIdentifier);
  const initializationComplete = useSelector(AddressingSelectors.selectAddressingInstance(treeIdentifier));
  const addressingIdRef = useRef(treeIdentifier);
  addressingIdRef.current = treeIdentifier;

  const cleanUpOldIstance = useCallback(
    (entity: string, refToUpdate: React.MutableRefObject<number>, updatedValue: number) => {
      if (refToUpdate.current === updatedValue) {
        return;
      }

      console.info(`AddressingComponent ${entity}: clearAddressingFull ${initialTreeIdentifier.current}`);
      dispatch(AddressingActions.clearAddressingFull([initialTreeIdentifier.current]));

      refToUpdate.current = updatedValue;
      initialTreeIdentifier.current = addressingIdRef.current;
    },
    [dispatch]
  );

  // we need to initialize the addressingInstance here
  useEffect(() => {
    if (workgroupId && channelId) {
      dispatch(AddressingActions.initAddressingInstance(treeIdentifier));
    }
  }, [workgroupId, channelId, mediaId, dispatch]);

  // do cleanup when this component unmounts
  useEffect(() => {
    return () => {
      // because we are using a ref this condition will always query the most recent value of cleanUpMode
      if (cleanUpRef.current & AddressingCleanUp.ON_UNMOUNT) {
        console.info(`AddressingComponent UNMOUNT: clearAddressingFull ${addressingIdRef.current}`);
        dispatch(AddressingActions.clearAddressingFull([addressingIdRef.current]));
      }
    };
  }, [dispatch]);

  // do cleanup when workgroup changes
  useEffect(() => {
    if (workgroupId !== initialWorkgroupId.current && props.cleanupMode & AddressingCleanUp.ON_WORKGROUP_CHANGE) {
      cleanUpOldIstance('WORKGROUP_CHANGE', initialWorkgroupId, workgroupId);
    }
  }, [props.cleanupMode, workgroupId, cleanUpOldIstance]);

  // do cleanup when channel changes
  useEffect(() => {
    if (channelId !== initialChannelId.current && props.cleanupMode & AddressingCleanUp.ON_CHANNEL_CHANGE) {
      cleanUpOldIstance('CHANNEL_CHANGE', initialChannelId, channelId);
    }
  }, [props.cleanupMode, channelId, cleanUpOldIstance]);

  // do cleanup when media changes
  useEffect(() => {
    if (mediaId !== initialMediaId.current && props.cleanupMode & AddressingCleanUp.ON_MEDIA_CHANGE) {
      cleanUpOldIstance('MEDIA_CHANGE', initialMediaId, mediaId);
    }
  }, [props.cleanupMode, mediaId, cleanUpOldIstance]);

  if (initializationComplete) {
    return <AddressingTree {...props} workgroupId={workgroupId} channelId={channelId} mediaId={mediaId} />;
  } else {
    return props.placeholder;
  }
};

export default AddressingComponent;
