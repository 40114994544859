import { useContext, useEffect } from "react";

import { AppBarContextData, ButtonItem } from "./types";
import { AppBarContext } from "../AppBarContext";

export const useAppBarButtons = (buttons: ButtonItem[], deps?: Array<any>) => {
  const { updateAppBarButtons } = useContext<AppBarContextData>(AppBarContext);;

  useEffect(() => {
    updateAppBarButtons(buttons)
  }, deps)
};

export const useHeaderButtons = (): ButtonItem[] => {
  const context = useContext<AppBarContextData>(AppBarContext);
  return context.buttons;
};

export { };
