import { MediaDto } from '@models';

import { ListMediaItem, MessageCountMap } from '../types';

export const generateListMediaItemCopies = (
  source: ListMediaItem[],
  id: number | MediaDto,
  count: number
): ListMediaItem[] => {
  const copies: ListMediaItem[] = [];
  let sourceItem: ListMediaItem | undefined;
  if (typeof id === 'number') {
    sourceItem = source.find((item) => item.media.id === id);
  } else {
    sourceItem = { media: id, localId: 0 };
  }

  if (!sourceItem) {
    return [];
  }
  for (let i = 0; i < count; i++) {
    copies.push({ ...sourceItem, localId: source.length + i + 1 });
  }
  return copies;
};

export const processBrowsedMedias = (existingMedias: ListMediaItem[], newMap: MessageCountMap) =>
  Object.keys(newMap).reduce<ListMediaItem[]>(
    (a, key) => {
      const id = Number(key);
      const mapItem = newMap[id];

      // add new items
      const newItemsToAdd = generateListMediaItemCopies(a, mapItem.item ? mapItem.item : id, mapItem.count);
      a = a.concat(newItemsToAdd);

      return a;
    },
    [...existingMedias]
  );
