import { MediaType } from '@models';

import { MediaBaseDto } from '../MediaDto';

export enum EventType {
    AsSoonAs = 1,
    ExactTime,
    PauseMedia,
    Overlay,
  }
  
  export enum RepeatFrequency {
    NoRepeat = 0,
    RepeatDaily,
    RepeatHourly,
    RepeatAfterNumberOfTracks,
  }
  
  export interface PlaylistDto extends MediaBaseDto {
    mediaType: MediaType.EventPlaylist | MediaType.LoopPlaylist;
    properties: undefined;
    eventType: EventType;
    repeatFrequency: RepeatFrequency;
    repeatNumberOfTracks?: number;
    repeatInterval?: string;
    isRandom: boolean;
    maxNoOfElements: number;
  }
  
  export interface PlaylistUpdateDto {
    id?: number;
    name: string;
    description: string;
    owner: string;
    created: Date;
    isLocalInput: boolean;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    timezone: string;
    endMedia: boolean;
    diffusionDays: number;
    eventType?: EventType;
    repeatFrequency: RepeatFrequency;
    repeatInterval?: string;
    repeatNumberOfTracks?: number;
    allowUserSelection?: boolean;
    origin: number;
    isTemporary?: boolean;
    isDefault?: boolean;
    maxNoOfElements?: number;
    isRandom?: boolean;
    mediaType: MediaType;
  }
  