import classNames from 'classnames';
import { MediaIcon } from 'components';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import { IdName } from 'react-tools';

import { Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DragHandleIcon from '@material-ui/icons/DragHandle';

import { ListMediaItem } from '../types';
import { useStyles } from './SortableList.jss';

interface SortableListContainerProps {
  items: ListMediaItem[];
  dragHandle: boolean;
  selectedIds: number[];
  onClick: (message: IdName) => void;
  onChecked: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

interface SortableItemProps {
  item: ListMediaItem;
  selected: boolean;
  itemPosition: number;
  totalCount: number;
  useDragHandle: boolean;
  onClick: (message: IdName) => void;
  onChecked: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const DragHandle = SortableHandle(() => {
  const classes = useStyles();

  return (
    <ListItemIcon className={classes.dragHandle}>
      <DragHandleIcon />
    </ListItemIcon>
  );
});

const SortableItem = SortableElement<SortableItemProps>((props: SortableItemProps) => {
  const [t] = useTranslation();
  const onItemClick = useCallback(() => {
    props.onClick(props.item.media);
  }, [props.item.media.id]);

  const classes = useStyles();
  return (
    <ListItem
      button
      disableGutters
      classes={{ container: classes.listItem, root: classes.listItemRoot }}
      divider={props.itemPosition < props.totalCount - 1}
      onClick={onItemClick}
      component="div"
    >
      <ListItemIcon classes={{root: classes.itemIcon}}>
        <MediaIcon mediaType={props.item.media.mediaType} />
      </ListItemIcon>
      <ListItemText
        primary={props.item.media.name}
        primaryTypographyProps={{ noWrap: true }}
      />
      <ListItemSecondaryAction classes={{root: classes.itemSecondaryAction}}>
        {props.useDragHandle && (
          <IconButton className={classNames(classes.dragButton)}>
            <DragHandle />
          </IconButton>
        )}
        <Checkbox
          edge="start"
          data-id={props.item.localId}
          checked={props.selected}
          tabIndex={-1}
          onChange={props.onChecked}
          inputProps={{ id: props.item.localId.toString() }}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
});

export const SortableListContainer = SortableContainer((props: SortableListContainerProps) => {
  const classes = useStyles();
  return (
    <List component="div" className={classes.list}>
      {props.items.map((item, index, a) => (
        <SortableItem
          key={`${item.media.id}-${index}`}
          itemPosition={index}
          index={index}
          item={item}
          totalCount={a.length}
          useDragHandle={props.dragHandle}
          selected={props.selectedIds.indexOf(item.localId) !== -1}
          onChecked={props.onChecked}
          onClick={props.onClick}
        />
      ))}
    </List>
  );
});
