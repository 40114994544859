import i18next from 'i18next';
import immer from 'immer';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import { ActionTypes, DirtyInstanceState, DirtyState } from './types';

export const initialDirtyInstanceState: DirtyInstanceState = {
  type: '',
  id: '',
  value: false,
};

export const initialDirtyState: DirtyState = {
  instances: {},
  dialog: {
    open: false,
    confirmClicked: false,
    cancelClicked: false,
    title: '',
    content: '',
    issuer: '',
  },
};

export const dirtyReducer: Reducer<DirtyState, ActionType<typeof Actions>> = (state = initialDirtyState, action) => {
  return immer(state, (draftState) => {
    switch (action.type) {
      case ActionTypes.SET_DIRTY: {
        const type = action.payload.type;
        const value = action.payload.value;

        if (Array.isArray(type)) {
          type.forEach((type) => (draftState.instances[type].value = value));
        } else {
          draftState.instances[type].value = value;
        }

        break;
      }
      case ActionTypes.OPEN_DIALOG:
        draftState.dialog.open = true;
        draftState.dialog.confirmClicked = false;
        draftState.dialog.cancelClicked = false;
        draftState.dialog.title = action.payload.title
          ? action.payload.title
          : i18next.t('dialog.discardChangesPrompt.title');
        draftState.dialog.content = action.payload.content
          ? action.payload.content
          : i18next.t('dialog.discardChangesPrompt.content');
        draftState.dialog.issuer = action.payload.issuer;
        break;
      case ActionTypes.CONFIRM_CLICKED:
        draftState.dialog.open = false;
        draftState.dialog.confirmClicked = true;
        draftState.dialog.cancelClicked = false;
        draftState.dialog.title = '';
        draftState.dialog.content = '';
        break;
      case ActionTypes.CANCEL_CLICKED:
        draftState.dialog.open = false;
        draftState.dialog.confirmClicked = false;
        draftState.dialog.cancelClicked = true;
        draftState.dialog.title = '';
        draftState.dialog.content = '';
        break;
      case ActionTypes.CLEAR_DIALOG:
        draftState.dialog.confirmClicked = false;
        draftState.dialog.cancelClicked = false;
        draftState.dialog.issuer = '';
        break;
      case ActionTypes.CLEAR_DIRTY_INSTANCE: {
        //const index = draftState.instances.findIndex(instance => instance.type === type)
        //draftState.instances.splice(index, 1);
        delete draftState.instances[action.payload];
        break;
      }
      case ActionTypes.INIT_DIRTY_INSTANCE:
        draftState.instances[action.payload] = { ...initialDirtyInstanceState };
        break;
      default:
        return state;
    }
  });
};
