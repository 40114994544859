import { MediaForms } from 'components';
import {
  loopPlaylistFormSchema,
  PlaylistFormData,
  playlistFormDataToCreateDto
} from 'components/mediaEdit/forms';
import { Formik } from 'formik';
import { useFetchLibrary } from 'hooks';
import { LayoutSelectors } from 'layout';
import deepClone from 'lodash-es/cloneDeep';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EntityType } from 'react-tools';
import { useDebouncedCallback } from 'use-debounce';
import { FormsUtils } from 'utils';
import { ZoneSelectors } from 'zone';
import { ZoneThunks } from 'zone/duck';

import { MediaDto, MessageType } from '@models';

import { AddressingCleanUp, AddressingSelectors } from '../../addressing';
import { AddressingContextProvider } from '../../addressing/context/addressing.context';
import { MSG } from '../../App.bootstrap';
import { PlaylistForm } from './PlaylistForm';
import { usePlaylistEditProps } from './props.hook';
import { ListMediaItem } from './types';

export interface PlaylistEditProps {
  playlistId?: number;
  zoneId?: number;
  onCancel?: () => void;
}

export const PlaylistEdit: React.FunctionComponent<PlaylistEditProps> = (props) => {
  const dispatch = useDispatch();

  const { playlistId, zoneId } = usePlaylistEditProps(props);
  const newlyAddedPlaylistId = useSelector(ZoneSelectors.selectNewlyAddedPlaylist);

  const playlist = useSelector((state) =>
    playlistId
      ? ZoneSelectors.selectPlaylist(state, Number(playlistId))
      : ZoneSelectors.selectPlaylist(state, newlyAddedPlaylistId)
  );

  const messageType = useSelector(LayoutSelectors.selectMessageType);
  const addressingRules = useSelector(
    AddressingSelectors.selectSpecificAddressingRules(MSG.workgroupId, zoneId, playlistId)
  );

  useFetchLibrary();

  useEffect(() => {
    if (playlistId) {
      dispatch(ZoneThunks.fetchPlaylistInfo(playlistId));
    }
  }, [playlistId, dispatch]);

  const onSave = useCallback(
    (values: PlaylistFormData & { messages: ListMediaItem[] }) => {
      let addressing = addressingRules;

      // if this is a new media and there are no addressing rules, add force deny on workgroup MoodMedia
      if (!playlistId && addressing.length < 1) {
        addressing = [
          {
            idMediaChannel: zoneId,
            idMedia: playlistId,
            idEntity: 1,
            idEntityType: EntityType.Workgroup,
            deny: true,
          },
        ];
      }

      dispatch(
        ZoneThunks.savePlaylist(
          zoneId,
          playlistFormDataToCreateDto(values, messageType, playlistId),
          values.messages.map(m => m.media),
          deepClone(addressing)
        )
      );
    },
    [playlistId, zoneId, playlist, addressingRules]
  );

  const [debouncedSave] = useDebouncedCallback(
    (values: PlaylistFormData & { messages: ListMediaItem[] }) => onSave(values),
    300
  );

  const formInitialValues = useMemo(() => {
    return { ...FormsUtils.playlistToFormData(playlist), messages: [] };
  }, [playlist]);

  const MemoizedForm = useMemo(
    () => (
      <Formik
        initialValues={formInitialValues}
        enableReinitialize
        onSubmit={debouncedSave}
        validationSchema={
          messageType === MessageType.Overhead ? MediaForms.eventPlaylistFormSchema : loopPlaylistFormSchema
        }
        validateOnBlur
        validateOnChange
      >
        <PlaylistForm zoneId={zoneId} playlistId={playlistId} messageType={messageType} onCancel={props.onCancel} />
      </Formik>
    ),
    [playlist, playlistId, props.onCancel, addressingRules]
  );

  const addressingCleanup = useMemo(() => {
    return { [AddressingCleanUp.ON_MEDIA_CHANGE]: true, [AddressingCleanUp.ON_UNMOUNT]: true };
  }, []);

  return (
    <>
      <AddressingContextProvider
        {...{
          mediaId: playlistId,
          channelId: zoneId,
          workgroupId: MSG.workgroupId,
          cleanupMode: addressingCleanup,
        }}
      >
        {MemoizedForm}
      </AddressingContextProvider>
    </>
  );
};
