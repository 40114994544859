import {
  createStyles,
  fade,
  makeStyles,
  Theme
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    mainBarContainer: {
      '& input[type=text]': {
        height: 'initial',
      },
      display: 'inline-flex',
      alignItems: 'center',
    },
    quickSearchContainer: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    subtitle: {
      marginTop: theme.spacing(-1)
    },
    fullWidthSearchBox: {
      marginTop: theme.spacing(2),
    }
  })
);
