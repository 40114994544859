import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TextField } from '@material-ui/core';

import { useStyles } from './Schedule.jss';

export interface MinutesAndSecondsControlProps {
  onChange: (seconds: number) => void;
  min: number;
  max: number;
  value?: number;
}

export const MinutesAndSecondsControl = (props: MinutesAndSecondsControlProps) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const minutes = useMemo(() => (props.value ? Math.floor(props.value / 60) : 0), [props.value]);
  const seconds = useMemo(() => (props.value ? props.value % 60 : 1), [props.value]);

  const checkLimits = useCallback(
    (newMinutes: number, newSeconds: number) => {
      const value = newMinutes * 60 + newSeconds;

      if (value > props.max) {
        return props.max;
      }

      if (value < props.min) {
        return props.min;
      }

      return value;
    },
    [props.min, props.max]
  );

  const onMinutesChange = useCallback(
    (newMinutes: number) => {
      if (!seconds && newMinutes) {
        props.onChange(checkLimits(newMinutes, 0));
      } else if (!seconds && !newMinutes) {
        props.onChange(props.min);
      } else {
        props.onChange(checkLimits(newMinutes, seconds));
      }
    },
    [seconds, props.onChange, checkLimits, props.min]
  );

  const onSecondsChange = useCallback(
    (newSeconds: number) => {
      if (!newSeconds && minutes) {
        props.onChange(checkLimits(minutes, 0));
      } else if (!newSeconds && !minutes) {
        props.onChange(props.min);
      } else {
        props.onChange(checkLimits(minutes, newSeconds));
      }
    },
    [minutes, props.onChange, checkLimits, props.min]
  );

  return (
    <>
      <div className={classes.outlineInputContainer}>
        <TextField
          type={'number'}
          variant={'outlined'}
          label={t('media.duration.minutes')}
          placeholder={t('media.duration.minutes')}
          inputProps={{
            min: 0,
            max: 1439,
          }}
          value={minutes}
          className={classes.playbackIntervalCountInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value =
              event.target.value !== undefined && event.target.value !== '' ? parseInt(event.target.value) : 0;
            onMinutesChange(value);
          }}
        />
      </div>
      <div className={classes.outlineInputContainer}>
        <TextField
          type={'number'}
          variant={'outlined'}
          label={t('media.duration.seconds')}
          placeholder={t('media.duration.seconds')}
          inputProps={{
            min: 0,
            max: 59,
          }}
          value={seconds}
          className={classes.playbackIntervalCountInput}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value =
              event.target.value !== undefined && event.target.value !== '' ? parseInt(event.target.value) : 0;

            const min = minutes === 0 ? 1 : 0;
            onSecondsChange(Math.max(min, Math.min(59, value)));
          }}
        />
      </div>
    </>
  );
};
