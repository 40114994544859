import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      height: '100%',
      flexGrow: 1,
      alignItems: 'stretch',
      flexWrap: 'nowrap',
    },
    gridItemContent: {
      padding: theme.spacing(1),
      maxHeight: '100%',
    },
    gridItemPaper: {
      height: '100%',
    },
    gridItemEmptySelection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
