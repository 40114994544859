import React from 'react';

import { List } from '@material-ui/core';

import { FormMediaDescriptionListItem } from './FormMediaDescriptionListItem';
import { FormMediaDurationListItem } from './FormMediaDurationListItem';
import { useStyles } from './MediaFormList.jss';
import { SchedulingListItems } from './scheduling';

export interface MediaFormListProps {
  hideDuration?: boolean;
}

export const MediaFormList: React.FunctionComponent<MediaFormListProps> = (props) => {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <SchedulingListItems />
      {!props.hideDuration && <FormMediaDurationListItem />}
      {props.children}
      <FormMediaDescriptionListItem />
    </List>
  );
};
