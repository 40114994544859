import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';

import { ZoneSelectors } from './duck';

export const ZoneEditAppBar: React.FunctionComponent = (props) => {
  let params = useParams<{ zoneId: string }>();
  const zone = useSelector((state) => ZoneSelectors.selectZone(state, Number(params.zoneId)));
  if (!zone) {
    throw 'No message found for id ' + params.zoneId;
  }
  return (
    <>
      <Typography>{zone.zoneName}</Typography>
    </>
  );
};
