import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    buttonLabel: {
      textTransform: 'capitalize',
    },
    buttonGroup: {
      marginTop: theme.spacing(2)
    },
    button: {
      [theme.breakpoints.down('sm')]: {
        minHeight: '44px',
      },
    },
    dayLabel: {
      marginRight: theme.spacing(1)
    }
  });
};

export const useStyles = makeStyles(styles);
