import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { FileUploadResult } from './types';
import { Uploader, UploaderProps } from './uploader';

export interface UploadDialogProps
  extends Pick<UploaderProps, Exclude<keyof UploaderProps, 'showButtons' | 'startUpload' | 'onUploadComplete'>> {
  open: boolean;
  title: string;
  onCancel: () => void;
  onFinish: (files: FileUploadResult[]) => void;
}

export const UploadDialog = React.memo(
  (props: UploadDialogProps) => {
    const { onFinish, open } = props;
    const [t] = useTranslation();
    const [startUpload, setStartUpload] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const completedFiles = React.useRef<FileUploadResult[]>([]);

    const onUpload = useCallback(() => {
      setStartUpload(true);
    }, [setStartUpload]);

    // event from files upload
    const handleUploadCompleted = useCallback(
      (files: FileUploadResult[]) => {
        completedFiles.current = files;
        setUploadComplete(true);
      },
      [setUploadComplete, completedFiles.current]
    );

    const handleFinishClick = useCallback(() => {
      if (uploadComplete && onFinish) {
        onFinish(completedFiles.current);
      }
    }, [completedFiles.current, uploadComplete]);

    // cleanup when closed
    useEffect(() => {
      completedFiles.current = [];
      setStartUpload(false);
      setUploadComplete(false);
    }, [open]);

    return (
      <Dialog open={props.open} maxWidth="lg" keepMounted={false}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <Uploader
            folderId={props.folderId}
            accept={props.accept}
            filesAreContent={props.filesAreContent}
            multiple={props.multiple}
            onUploadComplete={handleUploadCompleted}
            showButtons={false}
            startUpload={startUpload}
          />
        </DialogContent>

        <DialogActions>
          {!uploadComplete && (
            <>
              <Button onClick={props.onCancel} autoFocus>
                {t<string>('cancel')}
              </Button>
              <Button variant="contained" onClick={onUpload}>
                {t<string>('upload')}
              </Button>
            </>
          )}
          {uploadComplete && (
            <Button variant="contained" onClick={handleFinishClick}>
              {t<string>('close')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  },
  (prevProps, nextProps) => prevProps.open === nextProps.open
);
