import { Icons } from 'components';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import RemoveIcon from '@material-ui/icons/RemoveCircle';

import { useStyles } from './PlaylistMessagesToolbar.jss';

export interface PlaylistMessagesToolbarProps {
  onRemove: () => void;
  onDuplicate: () => void;
  onBrowse: () => void;
  onCheckChange: (checked: boolean) => void;
  checkStatus: boolean | null;
  selectionCount: number;
}

export const PlaylistMessagesToolbar: React.FunctionComponent<PlaylistMessagesToolbarProps> = (props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  const onCheckChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      props.onCheckChange(checked);
    },
    [props.onCheckChange]
  );

  const selectionLabelKey =
    props.checkStatus === null ? 'select.count' : props.checkStatus ? 'select.none' : 'select.all';
  return (
    <Toolbar classes={{ root: classes.messagesToolbar }} disableGutters>
      <Tooltip title={t<string>(selectionLabelKey, { count: props.selectionCount })}>
        <Checkbox
          indeterminate={props.checkStatus === null}
          checked={props.checkStatus ?? false}
          onChange={onCheckChange}
        />
      </Tooltip>
      <Tooltip title={t<string>('duplicate')}>
        <IconButton onClick={props.onDuplicate}>
          <Icons.ContentCopyIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t<string>('remove')}>
        <IconButton onClick={props.onRemove}>
          <RemoveIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t<string>('browse')}>
        <IconButton onClick={props.onBrowse}>
          <LibraryAddIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};
