import {
  Addressing,
  AddressingActions,
  AddressingRulesState,
  AddressingSelectors,
  DefaultMediaAddressingBehavior
} from 'addressing';
import { FormTab, MediaForm } from 'components/mediaEdit';
import { useDirty, useDirtyDialog } from 'dirty';
import { useFormikContext } from 'formik';
import { useCallback, useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ZoneSelectors } from 'zone';
import { setSelectedPlaylistMessages } from 'zone/duck/actions';

import { MediaDto, MediaTypes, MessageType } from '@models';

import { AddressingCleanUp, AddressingClearMode } from '../../addressing';
import { generateTreeIdentifier } from '../../addressing/duck/utils';
import { MSG } from '../../App.bootstrap';
import { formType } from '../duck';
import { PlaylistMessages } from './messages/PlaylistMessages';
import { Schedule } from './schedule/Schedule';
import { ListMediaItem } from './types';

const mapMessages = (messages: MediaDto[]) => {
  return messages.map<ListMediaItem>((m, i) => ({
    media: m,
    localId: i + 1,
  }));
};

const checkEqual = (
  initialValues: any,
  values: any,
  initialMessages: any,
  messages: any,
  rules?: AddressingRulesState
) => {
  const { messages: _, ...a } = initialValues as any;
  const { messages: __, ...b } = values as any;
  const ma = mapMessages(initialMessages);
  const mb = messages;

  const valuesAreEqual = isEqual(a, b);
  const messagesAreEqual = isEqual(ma, mb);
  let rulesAreEqual = true;

  if (rules) {
    let { originalData: ra, rawData: rb } = rules;
    rb = rb.filter((md) => md.deny !== null);
    rulesAreEqual = isEqual(ra, rb);
  }

  const equal = valuesAreEqual && messagesAreEqual && rulesAreEqual;

  return equal;
};

export const PlaylistForm = ({
  zoneId,
  playlistId,
  messageType,
  onCancel,
}: {
  zoneId: number;
  playlistId: number;
  messageType?: MessageType;
  onCancel?: () => void;
}) => {
  const [internalMessages, setInternalMessages] = useState<ListMediaItem[]>([]);
  const context = useFormikContext<MediaTypes.Playlist.PlaylistDto>();
  const dispatch = useDispatch();
  const isSaving = useSelector(ZoneSelectors.selectIsSaving);
  const messages = useSelector(ZoneSelectors.selectPlaylistMessages);
  const { dirty, setDirty } = useDirty(formType);
  const { confirmClicked } = useDirtyDialog(formType);
  const addressingId = generateTreeIdentifier(MSG.workgroupId, zoneId, playlistId);
  const rules = useSelector(AddressingSelectors.selectAddressingRules(addressingId));
  const [t] = useTranslation();
  
  useEffect(() => {
    setInternalMessages([]);
    dispatch(setSelectedPlaylistMessages([]));
  }, [playlistId]);

  const resetValues = useCallback(
    (adressingClearMode: AddressingClearMode) => {
      context.setValues({ ...context.initialValues });
      setInternalMessages(mapMessages(messages));

      if (!rules) {
        return;
      }

      if (adressingClearMode === AddressingClearMode.REVERT) {
        dispatch(AddressingActions.addressingRulesReset(addressingId));
      } else {
        dispatch(AddressingActions.clearAddressingFull([addressingId]));
      }
    },
    [context, messages, rules, playlistId]
  );

  const cancel = useCallback(() => {
    resetValues(AddressingClearMode.REVERT);
    if (!playlistId) {
      setDirty(false);
      if (onCancel) {
        onCancel();
      }
    }
  }, [onCancel, playlistId, resetValues]);

  // When back button in browser.
  // useEffect first reached because dialog sets confirmedClicked `true`.
  // useEffect then reached because dialog sets dirty value `false`.
  // If we don't clean the form, we end in a loop of dialogs.
  // This justifies resetValues().
  useEffect(() => {
    if (confirmClicked) {
      resetValues(AddressingClearMode.RESET);
      return;
    }

    const nextDirty = !checkEqual(context.initialValues, context.values, messages, internalMessages, rules);
    if (nextDirty !== dirty) {
      setDirty(nextDirty);
    }
  }, [context, messages, internalMessages, dirty, confirmClicked, rules]);

  useEffect(() => {
    const newMessages = mapMessages(messages);
    setInternalMessages(newMessages);
    context.setFieldValue('messages', newMessages);
  }, [messages]);

  useEffect(() => {
    if (playlistId <= 0) {
      setInternalMessages([]);
    }
  }, [playlistId]);

  useEffect(() => {
    if (context) {
      context.setFieldValue('messages', internalMessages);
    }
  }, [internalMessages]);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    inputRef.current?.focus();
  }, [playlistId]);

  return (
    <>
      <MediaForm saveInProgress={isSaving} onCancel={cancel} nameFieldPlaceholder={t('zonePlaylistTitlePlaceholder')}>
        <FormTab label="messages">
          <PlaylistMessages
            messages={internalMessages}
            onMessagesChange={(messages) => {
              setInternalMessages(messages);
            }}
            playlistId={playlistId}
            zoneId={zoneId}
          />
        </FormTab>
        <FormTab label="schedule">
          <Schedule messageType={messageType} />
        </FormTab>
        <FormTab label="locations">
          <Addressing cleanupMode={AddressingCleanUp.NONE} defaultBehavior={DefaultMediaAddressingBehavior.Deny}/>
        </FormTab>
      </MediaForm>
    </>
  );
};
