import React from 'react';

import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import FolderIcon from '@material-ui/icons/Folder';
import { MediaType } from '@models/media/MediaType';

export interface MediaIconProps {
  mediaType: MediaType;
  iconProps?: any;
}

export const MediaIcon: React.FunctionComponent<MediaIconProps> = React.memo(props=> {
    switch(props.mediaType) {
        case MediaType.Audio: return <AudiotrackIcon {...props.iconProps}/>; 
        case MediaType.Folder: return <FolderIcon {...props.iconProps}/>; 
        default: return <div/>;
    }
});
