import { LayoutActions, LayoutSelectors, MainLayoutArea } from 'layout';
import { LibrarySelectors } from 'library';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { ZoneSelectors } from 'zone';

import { getLayoutFromZones } from './utils';

export const useRoutes = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const libraryMatch = useRouteMatch('/library');
  const zoneMatch = useRouteMatch('/zone/:zoneId');
  const library = useSelector(LibrarySelectors.selectItems);
  const layoutState = useSelector(LayoutSelectors.selectLayoutState);
  const zones = useSelector(ZoneSelectors.selectZones);

  useEffect(() => {
    const browserAction = history.action === 'POP';
    const libraryItemsLoaded = library.length;
    const notInLibraryLayout = layoutState.selectedMainArea !== MainLayoutArea.LibraryOverhead &&
      layoutState.selectedMainArea !== MainLayoutArea.LibraryOnHold;

    if (browserAction && libraryMatch && libraryItemsLoaded && notInLibraryLayout) {
      dispatch(LayoutActions.selectMainArea(MainLayoutArea.LibraryOverhead, 0));
    }
  }, [libraryMatch, history, library, layoutState]);

  useEffect(() => {
    const browserAction = history.action === 'POP';
    if (browserAction && zoneMatch) {
      const zoneId = Number((zoneMatch.params as any).zoneId);
      const layout = getLayoutFromZones(zones, zoneId);

      if (!layout) {
        return;
      }

      if (layoutState.selectedItem !== zoneId) {
        dispatch(LayoutActions.selectMainArea(layout.selectedMainArea, zoneId));
      }
    }
  }, [zoneMatch, history, layoutState]);
};