import { unmountComponentAtNode } from 'react-dom';
import { IMVisionApp } from 'react-tools';
import { startReact } from 'startReact';

import packageJson from '../package.json';
import { createDefaultStore } from './store';

export const MSG: IMVisionApp = {
  open: (rootNodeId: string = 'root') => {
    startReact(rootNodeId, createDefaultStore());
  },
  unmount: (rootNodeId: string) => {
    unmountComponentAtNode(document.getElementById(rootNodeId) as Element);
  },
  homeFn: () => {
    console.log('home');
  },
  userId: 7149,
  workgroupId: 19680,
  version: '',
};

const mvisionLoaded = (): boolean =>
  typeof (window as any).mvision === 'object' &&
  typeof (window as any).mvision.messagingApp === 'object' &&
  (window as any).mvision.messagingApp.MSG_REQUIRED;

export const setupMSG = () => {
  const setGlobalMSG = () => {
    // set MSG as a global property
    (window as any).MSG = MSG;
    MSG.version = packageJson.version;
  };

  const onDOMContentLoaded = (event: Event) => {
    // remove the event listener
    document.removeEventListener('DOMContentLoaded', onDOMContentLoaded);
    if (!mvisionLoaded()) {
      // fallback to the "natural" react code
      startReact('root');
    }

    setGlobalMSG();
  };

  /**
   * If the document and the mvision.services namespace are already loaded
   * we set the global MSG object...
   */
  if (document.readyState === 'complete') {
    if (mvisionLoaded()) {
      setGlobalMSG();
    }
  } else {
    // ...otherwise we listen for the document to be ready
    document.addEventListener('DOMContentLoaded', onDOMContentLoaded);
  }
};
