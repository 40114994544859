import immer from 'immer';
import deepClone from 'lodash-es/cloneDeep';
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import AddressingHelper from '../addressingHelper';
import AddressingService from '../addressingService';
import * as Actions from './actions';
import {
  ActionTypes,
  AddressingInstanceState,
  AddressingRulesState,
  AddressingState,
  AddressingStructureState,
  AddressingViewModes
} from './types';
import { applyDeniesOnTree, generateTreeIdentifier, searchTree } from './utils';

type AddressingActions = ActionType<typeof Actions>;

const initialAddressingRulesState: AddressingRulesState = {
  originalData: [],
  rawData: [],
  processedData: {
    bucketGroups: {},
    bucketSites: {},
    bucketPlayers: {},
    bucketStream: {},
    bucketWorkgroups: {},
  },
  processingStatus: {
    isProcessing: false,
    complete: false,
  },
  fetchStatus: {
    isFetching: false,
    complete: false,
    error: '',
  },
};

const initialAddressingStructureState: AddressingStructureState = {
  originalData: [],
  rawData: [],
  processedData: [],
  processingStatus: {
    isProcessing: false,
    complete: false,
  },
  fetchStatus: {
    isFetching: false,
    complete: false,
    error: '',
  },
};

const initialAddressingInstanceState: AddressingInstanceState = {
  addressingStructure: initialAddressingStructureState,
  addressingRules: initialAddressingRulesState,
  needToUpdateTree: false,
  workgroupId: 0,
  channelId: 0,
  mediaId: 0,
  viewMode: AddressingViewModes.AZ,
  subscribedToWebWorker: false,
  initializationComplete: false,
};

const initialAddressingState: AddressingState = {};

const actionsWithNoAddressingState = [ActionTypes.CLEAR_ADDRESSING_FULL, ActionTypes.INIT_ADDRESSING_INSTANCE];

export const addressingReducer: Reducer<AddressingState, AddressingActions> = (
  state = initialAddressingState,
  action
) => {
  const treeId = (action.payload as any)?.treeIdentifier;

  /* 
  Most of the bellow actions require an addressing instance (a.k.a. treeId)
  to do their job, but it is not always guaranteed that that treeId is still available.

  Take for example PROCESS_ADDRESSING_RULES_START and PROCESS_ADDRESSING_RULES_END.
  Depending on how the AddressingComponent is used and various user actions, the same
  addressing instance used in PROCESS_ADDRESSING_RULES_START might not be available when
  PROCESS_ADDRESSING_RULES_END is called. The instance was probably removed intentionally.
  Since we no longer have an addressing instance with the provided id, we just return the
  current state without changing anything.
   */
  if (treeId && !state[treeId] && !actionsWithNoAddressingState.includes(action.type)) {
    console.info(`Action ${action.type} missing treeId ${treeId}`);
    return state;
  }

  return immer(state, (draftState) => {
    switch (action.type) {
      case ActionTypes.FETCH_ADDRESSING_STRUCTURE_REQUEST:
        draftState[treeId].addressingStructure.fetchStatus.isFetching = true;
        draftState[treeId].addressingStructure.fetchStatus.complete = false;
        draftState[treeId].addressingStructure.fetchStatus.error = '';
        draftState[treeId].workgroupId = action.payload.workgroupId;
        break;
      case ActionTypes.FETCH_ADDRESSING_STRUCTURE_SUCCESS:
        draftState[treeId].addressingStructure.rawData = deepClone(action.payload.addressingStructure);
        draftState[treeId].addressingStructure.fetchStatus.isFetching = false;
        draftState[treeId].addressingStructure.fetchStatus.complete = true;
        draftState[treeId].addressingStructure.fetchStatus.error = '';
        break;
      case ActionTypes.FETCH_ADDRESSING_STRUCTURE_ERROR:
        draftState[treeId].addressingStructure.originalData = [];
        draftState[treeId].addressingStructure.rawData = [];
        draftState[treeId].addressingStructure.fetchStatus.complete = false;
        draftState[treeId].addressingStructure.fetchStatus.isFetching = false;
        draftState[treeId].addressingStructure.fetchStatus.error = action.payload.err;
        draftState[treeId].workgroupId = 0;
        break;
      case ActionTypes.BUILD_ADDRESSING_TREE:
        const trees = AddressingService.init(action.payload.addressingStructure, action.payload.workgroupId, treeId);
        draftState[treeId].addressingStructure.originalData = deepClone(trees);
        draftState[treeId].addressingStructure.processedData = deepClone(trees);
        draftState[treeId].addressingStructure.processingStatus.complete = true;
        break;
      case ActionTypes.FETCH_ADDRESSING_RULES_REQUEST:
        draftState[treeId].addressingRules.fetchStatus.isFetching = true;
        draftState[treeId].addressingRules.fetchStatus.complete = false;
        draftState[treeId].mediaId = action.payload.mediaId;
        draftState[treeId].channelId = action.payload.channelId;
        break;
      case ActionTypes.FETCH_ADDRESSING_RULES_SUCCESS:
        draftState[treeId].addressingRules.fetchStatus.isFetching = false;
        draftState[treeId].addressingRules.fetchStatus.complete = true;
        draftState[treeId].addressingRules.originalData = deepClone(action.payload.addresingRules);
        draftState[treeId].addressingRules.rawData = deepClone(action.payload.addresingRules);
        draftState[treeId].mediaId = action.payload.mediaId;
        draftState[treeId].channelId = action.payload.channelId;
        break;
      case ActionTypes.FETCH_ADDRESSING_RULES_ERROR:
        draftState[treeId].addressingRules.fetchStatus.isFetching = false;
        draftState[treeId].addressingRules.fetchStatus.complete = false;
        draftState[treeId].addressingRules.fetchStatus.error = action.payload.err;
        draftState[treeId].addressingRules.originalData = [];
        draftState[treeId].addressingRules.rawData = [];
        draftState[treeId].mediaId = 0;
        break;
      case ActionTypes.ADDRESSING_RULES_RESET:
        const rules = [...state[treeId].addressingRules.originalData];
        AddressingService.applyRules(rules, { id: 0, name: '' }, treeId);
        draftState[treeId].addressingRules.rawData = rules;
        break;
      case ActionTypes.PROCESS_ADDRESSING_RULES_START:
        if (
          action.payload.newRules.length > 0 &&
          action.payload.newRules.findIndex((r) => r.idMedia !== action.payload.newRules[0].idMedia) !== -1
        ) {
          throw 'All items in the addressing rules array must have the same media id';
        }

        draftState[treeId].addressingRules.rawData = action.payload.newRules.reduce((a, r) => {
          let idx = a.findIndex(
            (addrRule) =>
              addrRule.idEntity === r.idEntity &&
              addrRule.idMedia === r.idMedia &&
              addrRule.idEntityType === r.idEntityType
          );

          if (idx === -1) {
            a.push(r);
          } else {
            a[idx] = r;
          }
          return a;
        }, draftState[treeId].addressingRules.rawData);

        draftState[treeId].addressingRules.processingStatus.isProcessing = true;
        draftState[treeId].addressingRules.processingStatus.complete = false;
        const rulesToProcess = deepClone(draftState[treeId].addressingRules.rawData.filter((md) => md.deny !== null));
        AddressingService.applyRules(rulesToProcess, { id: 0, name: '' }, treeId);
        break;
      case ActionTypes.PROCESS_ADDRESSING_RULES_END:
        draftState[treeId].addressingRules.processingStatus.isProcessing = false;
        draftState[treeId].addressingRules.processingStatus.complete = true;
        draftState[treeId].needToUpdateTree = true;
        draftState[treeId].addressingRules.processedData = deepClone(action.payload.processedRules);
        break;
      case ActionTypes.UPDATE_ADDRESSING_TREE:
        applyDeniesOnTree(
          draftState[treeId].addressingStructure.processedData,
          draftState[treeId].addressingRules.processedData
        );
        draftState[treeId].needToUpdateTree = false;
        draftState[treeId].initializationComplete = true;
        break;
      case ActionTypes.TOGGLE_NODE:
        draftState[treeId].addressingStructure.processedData.forEach((tree) => {
          const node = searchTree(tree, action.payload.$$hashKey);
          if (node) {
            node.isExpanded = !node.isExpanded;
            console.info(`${node.$$hashKey}: ${node.isExpanded ? 'expanded' : 'collapsed'}`);
          }
        });
        draftState[treeId].needToUpdateTree = false;
        break;
      case ActionTypes.SUBSCRIBE_TO_WEB_WORKER:
        if (!draftState[treeId].subscribedToWebWorker) {
          draftState[treeId].subscribedToWebWorker = true;
        }
        break;
      case ActionTypes.CLEAR_ADDRESSING_STRUCTURE:
        draftState[treeId].addressingStructure = deepClone(initialAddressingStructureState);
        draftState[treeId].channelId = 0;
        draftState[treeId].workgroupId = 0;
        draftState[treeId].initializationComplete = false;
        break;
      case ActionTypes.CLEAR_ADDRESSING_RULES:
        draftState[treeId].addressingRules = deepClone(initialAddressingRulesState);
        draftState[treeId].addressingStructure.processedData = deepClone(
          draftState[treeId].addressingStructure.originalData
        );
        draftState[treeId].mediaId = 0;
        draftState[treeId].initializationComplete = false;
        break;
      case ActionTypes.CLEAR_ADDRESSING_FULL:
        const treeIds = action.payload.length ? action.payload : Object.keys(state);

        treeIds.forEach((treeId) => {
          AddressingService.removeWorker(treeId);
          AddressingHelper.removeInstance(treeId);
          delete draftState[treeId];
        });

        break;
      case ActionTypes.SET_ADDRESSING_VIEW_MODE:
        draftState[treeId].viewMode = action.payload.viewMode;
        break;
      case ActionTypes.SAVE_ADDRESSING_RULES:
        const newOriginalData = draftState[treeId].addressingRules.rawData.filter((md) => md.deny !== null);
        draftState[treeId].addressingRules.originalData = deepClone(newOriginalData);
        break;
      case ActionTypes.INIT_ADDRESSING_INSTANCE:
        if (treeId) {
          if (draftState[treeId] && !action.payload.clearIfPresent) {
            break;
          }
          const ids = (treeId as string).split('_');
          const workgroupId = Number.parseInt(ids[0], 10);
          const channelId = Number.parseInt(ids[1], 10);
          const mediaId = Number.parseInt(ids[2], 10);
          draftState[treeId] = { ...initialAddressingInstanceState, workgroupId, channelId, mediaId };
        }

        if ('workgroupId' in action.payload) {
          let { workgroupId, channelId, mediaId } = action.payload;
          const treeId = generateTreeIdentifier(workgroupId, channelId, mediaId);

          if (draftState[treeId] && !action.payload.clearIfPresent) {
            break;
          }

          draftState[treeId] = { ...initialAddressingInstanceState, workgroupId, channelId, mediaId };
        }

        break;
      default:
        return state;
    }
  });
};
