import { Toolbar } from 'components';
import { LayoutSelectors } from 'layout';
import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { IconButton, Tooltip } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Trash from '@material-ui/icons/Delete';
import { MessageType } from '@models';

interface PlaylistsToolbarProps {
  onAddClick: () => void;
  onDeleteClick: () => void;
  onSearch: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  canDelete: boolean;
}

export const PlaylistsToolbar = ({ onAddClick, onDeleteClick, canDelete, onSearch }: PlaylistsToolbarProps) => {
  const [t] = useTranslation();
  const messageType = useSelector(LayoutSelectors.selectMessageType);

  return (
    <Toolbar helpType={messageType === MessageType.OnHold ? 'onHoldPlaylist' : 'overheadPlaylist'} onSearch={onSearch}>
      {t('playlists')}
      <>
        <Tooltip title={t<string>('createZonePlaylist')}>
          <IconButton onClick={onAddClick}>
            <Add />
          </IconButton>
        </Tooltip>
        <Tooltip title={t<string>('deleteZonePlaylist')}>
          <IconButton onClick={onDeleteClick} disabled={!canDelete}>
            <Trash />
          </IconButton>
        </Tooltip>
      </>
    </Toolbar>
  );
};
