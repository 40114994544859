import React from 'react';
import { useTranslation } from 'react-i18next';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { FormListItemText } from './FormListItemText';

export interface FormListItemProps {
  labelKey: string;
  value: string;
  noValueKey?: string;
  icon?: React.ReactElement;
}

export const FormListItem: React.FunctionComponent<FormListItemProps> = (props) => {
  const [t] = useTranslation();
  return (
    <ListItem>
      {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
      <FormListItemText
        labelKey={props.labelKey}
        value={props.value}
        noValueKey={props.noValueKey}
        inset={!props.icon}
        focused={false}
      />
    </ListItem>
  );
};
