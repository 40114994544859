import { MessageLibraryItem } from 'library';
import React, { useCallback, useState } from 'react';

import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import { Theme } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import PlusIcon from '@material-ui/icons/AddBox';
import { MediaDto } from '@models';

import { MessageCountMap } from '../types';
import { useStyles } from './NavigationSecondaryAction.jss';

interface NavSecondaryActionProps {
  item: MessageLibraryItem;
  messageCount: React.MutableRefObject<MessageCountMap>;
  onPlus: (item: MediaDto) => void;
  onMinus: (item: MediaDto) => void;
}

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  })
)(Badge);

export const NavSecondaryAction: React.FunctionComponent<NavSecondaryActionProps> = (props) => {
  const classes = useStyles();
  const { item, onPlus, onMinus } = props;
  const [count, setCount] = useState(() => {
    return props.messageCount.current[props.item.mediaInfo.id]? props.messageCount.current[props.item.mediaInfo.id].count : 0;
  });
  const onPlusClick = useCallback(() => {
    setCount(count + 1);
    onPlus(item.mediaInfo);
  }, [item, onPlus, count, setCount]);
  // const onMinusClick = useCallback(() => {
  //   setCount(count - 1);
  //   onMinus(item.mediaInfo);
  // }, [item, onPlus, count, setCount]);

  if (props.item.mediaInfo.isFolder) {
    return null;
  }

  return (
    <div className={classes.container}>
      <IconButton size="small" onClick={onPlusClick} disabled={count === 100}>
        <StyledBadge badgeContent={count} color="secondary">
          <PlusIcon />
        </StyledBadge>
      </IconButton>
    </div>
  );
};
