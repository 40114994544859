import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const FileDownloadIcon: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2H14L20 8V20C20 21.1 19.1 22 18 22H5.99C4.89 22 4 21.1 4 20L4.01 4C4.01 2.9 4.9 2 6 2ZM6 20H18V9H13V4H6V20ZM14.59 13.58L16 14.99L11.99 19L8 14.99L9.41 13.57L11 15.16L11 11H13L13 15.16L14.59 13.58Z"
    />
  </SvgIcon>
);
