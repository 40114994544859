import { FormListItem } from 'components';
import React from 'react';
import { DateTimeUtils } from 'utils';

import { ListItem, ListItemText } from '@material-ui/core';
import { MediaTypes } from '@models';

import { FormMediaVolumeListItem } from './FormMediaVolumeListItem';
import { MessagePreview } from './MessagePreview';

export interface AudioFormProps {
  media: MediaTypes.Audio.AudioMediaDto;
}

export const AudioForm: React.FunctionComponent<AudioFormProps> = (props) => {
  return (
    <>
      <ListItem>
        <ListItemText inset disableTypography>
          <MessagePreview
            variant="large"
            contentFilePath={props.media.contentFilePath}
            contentId={props.media.contentId}
          />
        </ListItemText>
      </ListItem>
      <FormMediaVolumeListItem />
      <FormListItem
        labelKey="media.originalDuration"
        noValueKey="media.trueDuration.error.unavailable"
        value={DateTimeUtils.secondsFormat(props.media.properties ? props.media.properties.trueDuration : 0)}
      />
    </>
  );
};
