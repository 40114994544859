import i18next from 'i18next';
import { pushNotification } from 'react-tools';
import { Dispatch } from 'redux';

import { AddressingActions } from './';
import { AddressingApi } from './api';

export const fetchAddressingStructure = (channelId: number, workgroupId: number, treeId: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(AddressingActions.fetchAddressingStructureRequest(channelId, workgroupId, treeId));
    try {
      const addressingStructure = await AddressingApi.fetchAddressingStructure(channelId, workgroupId);
      dispatch(AddressingActions.fetchAddressingStructureSuccess(channelId, workgroupId, addressingStructure, treeId));
      dispatch(AddressingActions.buildAddressingTreeStart(addressingStructure, workgroupId, treeId));
    } catch (err) {
      dispatch(AddressingActions.fetchAddressingStructureError(err, treeId));
      dispatch(pushNotification(i18next.t('addressingStructure.fetch.error'), 'error'));
    }
  };
};

export const fetchAddressingRules = (channelId: number, mediaId: number, treeId: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(AddressingActions.fetchAddressingRulesRequest(channelId, mediaId, treeId));
    try {
      const addressingRules = await AddressingApi.fetchAddressingRules(channelId, mediaId);
      dispatch(AddressingActions.fetchAddressingRulesSuccess(channelId, mediaId, addressingRules, treeId));
    } catch (err) {
      dispatch(AddressingActions.fetchAddressingRulesError(err, treeId));
      dispatch(pushNotification(i18next.t('addressingRules.fetch.error'), 'error'));
    }
  };
};
