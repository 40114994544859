import {
  createStyles,
  fade,
  makeStyles,
  Theme
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    currentFolderContainer: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
    },
    backFolderButton: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(0),
    },
    currentFolderTitle: {},
    breadcrumbsRoot: {
      marginTop: theme.spacing(-1),
    },
  })
);
