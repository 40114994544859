import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@material-ui/core';
import Badge from '@material-ui/core/Badge';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useStyles } from './MediaEditTabs.jss';

export interface MediaEditTabsProps {
  onSelectedTabChanged: (selectedTab: number) => void;
  selectedTab: number;
  classes?: {
    root: string;
    [key: string]: string;
  };
}

export const MediaEditTabs: React.FunctionComponent<MediaEditTabsProps> = React.memo((props) => {
  const { selectedTab, onSelectedTabChanged } = props;
  const theme = useTheme();
  const [t] = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const onTabsChanged = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      onSelectedTabChanged(value);
    },
    [onSelectedTabChanged]
  );

  const getTabLabel = useCallback(
    (child: any, tabIndex: number) => {
      return selectedTab != tabIndex && child.props.errorCount ? (
        <Badge badgeContent={child.props.errorCount} color="secondary">
          {t(child.props.label)}
        </Badge>
      ) : (
        t(child.props.label)
      );
    },
    [selectedTab, (React.Children.toArray(props.children)[selectedTab] as any).props.errorCount]
  );

  return (
    <Paper elevation={0} color="default" className={classNames(classes.tabsContainer, {})}>
      <Tabs
        value={selectedTab}
        onChange={onTabsChanged}
        variant={mobile ? 'fullWidth' : 'standard'}
        centered
        scrollButtons="auto"
      >
        {React.Children.toArray(props.children)
          .filter((c) => c !== null)
          .map((child, index) => (
            <Tab key={index} value={index} label={getTabLabel(child, index)} />
          ))}
      </Tabs>
    </Paper>
  );
});
