import i18next from 'i18next';
import { LayoutState, MainLayoutArea } from 'layout';
import { Zone } from 'zone/duck';
import { ChannelContentType } from 'zone/duck/types';

export const getLayoutFromZones = (
  zones: {
    overHead: Zone[];
    onHold: Zone[];
  },
  zoneId: number
): LayoutState | undefined => {
  let zone = zones.onHold.find((z) => z.zoneId === zoneId);
  if (!zone) {
    zone = zones.overHead.find((z) => z.zoneId === zoneId);
  }
  if (!zone) {
    return undefined;
  }

  const mainLayoutArea =
    zone.contentType === ChannelContentType.OnHold ? MainLayoutArea.ZonesOnHold : MainLayoutArea.ZonesOverhead;

  return {
    selectedMainArea: mainLayoutArea,
    selectedItem: zoneId,
    splashScreenShown: true,
  };
};

export const getDirtyDialogContent = (layoutState: LayoutState): string => {
  const libraryMode =
    layoutState.selectedMainArea === MainLayoutArea.LibraryOnHold ||
    layoutState.selectedMainArea == MainLayoutArea.LibraryOverhead;

  return libraryMode
    ? i18next.t('message.discardChangesPrompt.content')
    : i18next.t('playlist.discardChangesPrompt.content');
};

export interface LayoutStateAndUrl extends LayoutState {
  url: string;
}

export const getDefaultLayoutStateAndUrl: () => LayoutStateAndUrl = () => ({
  selectedMainArea: MainLayoutArea.Unauthorized,
  selectedItem: 0,
  url: '',
  splashScreenShown: true,
});
