import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@material-ui/core';

import { useStyles } from './SplashScreen.jss';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutActions, LayoutSelectors } from './duck';

export interface SplashScreenProps {}

const localStorageHideKey = 'messaging.hideSplash';
export const SplashScreen: React.FunctionComponent<SplashScreenProps> = (props) => {
  const classes = useStyles();
  const [hide, setHide] = useState(false);
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();

  const splashScreenShown = useSelector(LayoutSelectors.selectSplashScreenShown);
  const dispatch = useDispatch();

  const onUserClose = useCallback(() => {
    if (hide) {
      localStorage.setItem(localStorageHideKey, hide.toString());
    }
    setOpen(false);
    dispatch(LayoutActions.markSplashScreenAsShown());
  }, [setOpen, hide]);
  const onDontShowChecked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setHide(checked);
    },
    [hide, setHide]
  );

  useEffect(() => {
    const hideSplash = Boolean(localStorage.getItem(localStorageHideKey));
    if (!hideSplash && !splashScreenShown) {
      setOpen(true);
    }
  }, [splashScreenShown, open]);

  return (
    <Dialog open={open && !splashScreenShown} onClose={onUserClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('splash.title')}</DialogTitle>
      <DialogContent>
        <Typography>{t('splash.content')}</Typography> <br />
        <Typography>
          {t('splash.userGuide')}{' '}
          <a
            className={classes.link}
            href="https://moodmedia.custhelp.com/ci/fattach/get/962573/0/filename/Harmony+Messaging+-+User+Guide.pdf"
            target="_blank"
          >
            {t('splash.here')}
          </a>
        </Typography>
        <br />
        <Typography>
          {t('splash.userExperience')}{' '}
          <a href="https://support.moodmedia.com" target="_blank" className={classes.link}>
            {t('splash.here')}
          </a>
        </Typography>
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          className={classes.hideCheckbox}
          control={<Checkbox checked={hide} onChange={onDontShowChecked} />}
          label={t('splash.hide')}
        />
        <Button onClick={onUserClose}>{t('splash.closeButton')}</Button>
      </DialogActions>
    </Dialog>
  );
};
