import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    slider: {
      flexGrow: 1,
      width: 'calc(100% - 6px) !important',
    },
    sliderItem: {
      marginTop: theme.spacing(-2),
    },
  });

export const useStyles = makeStyles(styles);
