import { FormTextFieldListItem } from 'components';
import React from 'react';

import { useStyles } from './FormMediaNameTextField.jss';

export interface FormMediaNameListItemProps {
  fieldName?: string;
  placeholder?: string;
}
export const FormMediaNameListItem: React.FunctionComponent<FormMediaNameListItemProps> = (props) => {
  const name = props.fieldName ? props.fieldName : 'name';
  const classes = useStyles();
  return (
    <FormTextFieldListItem
      fieldName={name}
      disableGutters
      TextFieldProps={{
        variant: 'standard',
        autoFocus: true,
        placeholder: props.placeholder,
        InputProps: {
          classes: {
            input: classes.nameField,
          },
        },
      }}
    />
  );
};
