import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noDataContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1 0',
      width: '100%',
      maxHeight: '100%',
      alignItems: 'center',
    },
    noDataText: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
  })
);
