import { createSelector } from 'reselect';

import { MediaDto } from '@models';

import { libraryAreaName, LibraryState, MessageLibraryItem } from './types';

export const libraryZoneState = (state: any): LibraryState => {
  return state[libraryAreaName];
};

export const selectFolderPath = createSelector([libraryZoneState], (state) => state.folderPath);

export const selectCurrentFolder = createSelector(
  [libraryZoneState],
  (state) => state.folderPath[state.folderPath.length - 1]
);
export const selectItems = createSelector([libraryZoneState], (state) => state.items);

export const selectMessages = createSelector(
  [selectItems, selectCurrentFolder, (_: any, sortKey: 'creationDate' | 'name') => sortKey],
  (items, currentFolder, sortKey) => {
    const groupped = items
      .filter((li) => li.mediaInfo.mediaFolderId === currentFolder.id)
      .reduce<{
        folders: MessageLibraryItem[];
        messages: MessageLibraryItem[];
      }>(
        (a, i) => {
          if (i.mediaInfo.isFolder) {
            a.folders.push(i);
          } else {
            a.messages.push(i);
          }
          return a;
        },
        { folders: [], messages: [] }
      );
    const folders = groupped.folders.sort(
      (a, b) =>
        (a.mediaInfo[sortKey as keyof MediaDto] as string).localeCompare(
          b.mediaInfo[sortKey as keyof MediaDto] as string
        ) * -1
    );
    const messages = groupped.messages.sort(
      (a, b) =>
        (a.mediaInfo[sortKey as keyof MediaDto] as string).localeCompare(
          b.mediaInfo[sortKey as keyof MediaDto] as string
        ) * -1
    );
    return [...folders, ...messages];
  }
);

export const selectFetchingLibrary = createSelector([libraryZoneState], (state) => {
  return state.fetchingLibrary;
});

export const selectFetchingMessage = createSelector([libraryZoneState], (state) => {
  return state.fetchingMessage;
});

export const selectSavingMessage = createSelector([libraryZoneState], (state) => {
  return state.savingMessage;
});

export const selectMessage = createSelector(
  [libraryZoneState, (_: any, messageId: number) => messageId],
  (state, messageId) => state.items.find((m) => m.mediaInfo.id === messageId)
);

export const selectCurrentMessage = createSelector([libraryZoneState], (state) => {
  return state.selectedItem;
});
