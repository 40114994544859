import React from 'react';

import { Switch, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export const GreenSwitch = withStyles({
    switchBase: {
      '&$checked': {
        color: green[500],
      },
      '&$checked + $track': {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);