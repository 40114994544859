import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gearIcon: {
      fontSize: '1em',
    },
    processingIcon: {
      width: 38,
      paddingLeft: theme.spacing(1),
    },
    checkbox: {
      pointerEvents: 'auto',
    },
    formControl: {
      marginLeft: theme.spacing(1),
      pointerEvents: 'none',
    },
  })
);
