import { useParams } from 'react-router';

import { PlaylistEditProps } from './PlaylistEdit';

export const usePlaylistEditProps = (props: PlaylistEditProps) => {
  let { playlistId: routePlaylistId, zoneId: routeZoneId } = useParams<{
    playlistId?: string;
    zoneId: string;
  }>();

  let playlistId = Number(props.playlistId);
  if (!playlistId) {
    if (!routePlaylistId) {
      playlistId = 0;
    } else {
      playlistId = Number(routePlaylistId);
    }
  }

  let zoneId = Number(props.zoneId);
  if (!zoneId) {
    if (!routeZoneId) {
      throw 'No id supplied to the EventEdit component';
    } else {
      zoneId = Number(routeZoneId);
    }
  }

  return { playlistId, zoneId };
};
