export const dirtyAreaName = 'dirty';

export enum ActionTypes {
  SET_DIRTY = 'SET_DIRTY',
  OPEN_DIALOG = 'OPEN_DIALOG',
  CLEAR_DIALOG = 'CLEAR_DIALOG',
  CONFIRM_CLICKED = 'CONFIRM_CLICKED',
  CANCEL_CLICKED = 'CANCEL_CLICKED',
  CLEAR_DIRTY_INSTANCE = 'CLEAR_DIRTY_INSTANCE',
  INIT_DIRTY_INSTANCE = 'INIT_DIRTY_INSTANCE',
}

export interface DirtyInstanceState {
  type: string;
  id: string;
  value: boolean;
}

export interface DirtyState {
  instances: { [key: string]: DirtyInstanceState };
  dialog: {
    open: boolean;
    confirmClicked: boolean;
    cancelClicked: boolean;
    title: string;
    content: string;
    issuer: string;
  };
}
