import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    dropZone: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '20px',
      height: '250px',
      width: '400px',
      borderWidth: '2px',
      borderRadius: '2px',
      borderColor: theme.palette.grey[500],
      borderStyle: 'dashed',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      outline: 'none',
      transition: 'border .24s ease-in-out',
      cursor: 'pointer',
      // '&:focused': {
      //   borderColor: theme.palette.secondary
      // }
    },
    success: {
      color: theme.palette.success.main,
    },
    fail: {
      color: theme.palette.error.main,
    },
    filesZone: {
      flexShrink: 0,
      marginLeft: theme.spacing(2),
    },
    icon: {
      fontSize: 128,
      color: theme.palette.grey[500],
    },
  })
);
