import React from 'react';
import { useTranslation } from 'react-i18next';

import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { FormListItemText } from './FormListItemText';

export interface FormListCollapseItemProps {
  labelKey: string;
  value: React.ReactElement | string;
  noValueKey?: string;
  icon?: React.ReactElement;
}

export const FormListCollapseItem: React.FunctionComponent<FormListCollapseItemProps> = (props) => {
  const [t] = useTranslation();
  const [open, setOpen] = React.useState(false);
  const handleExpandClick = () => {
    setOpen(!open);
  };
  return (
    <>
      <ListItem onClick={handleExpandClick} button>
        {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
        <FormListItemText
          focused={open}
          labelKey={props.labelKey}
          value={props.value}
          noValueKey={props.noValueKey}
          inset={!props.icon}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {props.children}
      </Collapse>
    </>
  );
};
