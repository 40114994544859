import { GridExportButton, Toolbar } from 'components';
import { FolderPathItem } from 'library/duck/types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelpDialog, SearchBox, useGridQuickSearchEvent } from 'react-tools';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';

import { NavigatorBreadcrumbs } from './NavigatorBreadcrumbs';
import { useStyles } from './NavigatorToolbar.jss';

export interface NavigatorToolbarProps {
  currentFolder: FolderPathItem;
  hideControls?: boolean;
  hideSearch?: boolean;
  onSearchChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  deleteDisabled?: boolean;
  onSort: (prop: 'name' | 'creationDate') => void;
  onFolderBack: () => void;
  onDelete: () => void;
  onUpload: () => void;
}

export const NavigatorToolbar: React.FunctionComponent<NavigatorToolbarProps> = React.memo((props) => {
  const { currentFolder, onFolderBack, onDelete, onUpload, deleteDisabled } = props;
  const classes = useStyles();
  const quickSearch = useGridQuickSearchEvent();
  const [helpOpen, setHelpOpen] = useState(false);

  const [t] = useTranslation();

  const onHelpOpen = useCallback(() => setHelpOpen(true), [setHelpOpen]);
  const onHelpClose = useCallback(() => {
    setHelpOpen(false);
  }, [setHelpOpen]);

  const handleQuicksearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (quickSearch) {
        quickSearch(e.target.value);
      }
    },
    [quickSearch]
  );

  return (
    <Toolbar helpType="library" onSearch={props.onSearchChange ? props.onSearchChange : handleQuicksearch} hideSearch={props.hideSearch}>
      <div className={classes.currentFolderContainer}>
        {currentFolder?.level > 1 && (
          <IconButton size="small" className={classes.backFolderButton} onClick={onFolderBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography className={classes.currentFolderTitle} variant="h5">
          {currentFolder?.name}
        </Typography>
      </div>
      <>
        {!props.hideControls && (
          <>
            <IconButton onClick={onUpload}>
              <CloudUploadIcon />
            </IconButton>
            <GridExportButton fileName={t('export.fileNames.messages')} />
            <IconButton disabled={deleteDisabled} onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </>
      <NavigatorBreadcrumbs typographyProps={{ variant: 'caption' }} classes={{ root: classes.breadcrumbsRoot }} />
    </Toolbar>
  );
});
