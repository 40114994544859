import { MSG } from 'App.bootstrap';
import i18next from 'i18next';
import { pushNotification } from 'react-tools';
import { Dispatch } from 'redux';

import { MediaUpdateDto, MessageType } from '@models';

import { AddressingActions, AddressingApi, MediaDeny } from '../../addressing';
import { ZonesApi } from '../../zone/duck/api';
import * as actions from './actions';
import { LibraryApi } from './api';
import { MediaReferencesPayload } from './types';

export const fetchLibrary = (selectedMessageType: MessageType, workgroupId: number = MSG.workgroupId) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchLibraryRequest(workgroupId));
    try {
      const messages = await LibraryApi.getLibrary(workgroupId);
      dispatch(actions.fetchLibrarySuccess(workgroupId, messages, selectedMessageType));
    } catch (err) {}
  };
};

export const fetchMessage = (id: number) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.fetchMessageInfoRequest(id));
    try {
      const mediaDto = await LibraryApi.getMessageInfo(id);
      // const referencedChannels = await ZonesApi.getMediaChannelReferences(id);
      dispatch(actions.fetchMessageInfoSuccess(mediaDto, []));
      dispatch(actions.selectLibraryItem(id));
    } catch (err) {}
  };
};

export const deleteMessages = (mediaIds: number[]) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.deleteMessagesRequest(mediaIds));
    try {
      var references = await LibraryApi.mediaReferences(<MediaReferencesPayload>{
        OnlySharedReferences: false,
        mediaIds: mediaIds,
      });
      if (references) {
        var playlists = references['playlist'];
        var playlistNames = '';
        if (playlists !== undefined) {
          playlistNames = playlists.join(', ');
        }
        dispatch(pushNotification(i18next.t('denyDeleteMessageTitle'), 'error'));
      } else {
        await LibraryApi.deleteMessages(mediaIds);
        dispatch(actions.deleteMessagesSuccess(mediaIds));
      }
    } catch (err) {}
  };
};

export const saveMessage = (media: MediaUpdateDto, addressing: { [key: string]: MediaDeny[] }) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.saveMessagesRequest(media));
    try {
      let addressingRules: MediaDeny[] = [];
      let channelsToPublish: Number[] = [];

      // concat all the provided mediaDenies into one array and send it to the server
      Object.values(addressing).forEach((mediaDenies) => {
        addressingRules = addressingRules.concat(mediaDenies);

        // also compute a list of channels we need to publish
        mediaDenies.forEach((addrRule: MediaDeny) => {
          if (!channelsToPublish.includes(addrRule.idMediaChannel)) {
            channelsToPublish.push(addrRule.idMediaChannel);
          }
        });
      });

      // before saving the actual message we first save the addressing rules
      // because message saving also takes care of publishing/apply changes
      if (addressingRules.length > 0) {
        await AddressingApi.updateAddressing(addressingRules);
      }

      // update each of the addressing instances with the saved addressing rules
      Object.keys(addressing).forEach((addrId) => {
        dispatch(AddressingActions.saveAddressingRules(addrId));
      });

      // we don't do applyChanges if we know we will also publish
      await LibraryApi.saveMessages(media, channelsToPublish.length === 0);

      // lastly publish all updated channels because apply changes on a message
      // doesn't search for all playlists in which the media is used and doesn't
      // update their respective channels
      if (channelsToPublish.length > 0) {
        await LibraryApi.publishZones(channelsToPublish);
      }

      dispatch(actions.saveMessagesSuccess(media));

      dispatch(pushNotification(i18next.t('success.saveMessage'), 'success'));
    } catch (err) {
      dispatch(pushNotification(i18next.t('error.saveMessage'), 'error'));
    }
  };
};
