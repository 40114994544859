import { IdName } from 'react-tools';
import AddressingWorker from 'worker-loader!./addressingWorker'; // eslint-disable-line import/no-webpack-loader-syntax

import AddressingHelper from './addressingHelper'; // eslint-disable-line import/no-webpack-loader-syntax
import {
    AddressingStructure, AddressingStructureResponse, MediaDeny, MediaDenyApplyResult,
    MediaDenyApplyWorkerResponse
} from './duck';

const INIT_ADDRESSING_WORKER = 'request_buckets_init';
const PROCESS_ADDRESSING_RULES = 'request_apply_addressing_rules';
const PROCESS_ADDRESSING_RULES_COMPLETE = 'response_apply_addressing_rules';

class AddressingService {
  private workersRegistry: { [key: string]: AddressingWorker};
  private static instance: AddressingService;

  private constructor() {
    this.workersRegistry = {};
  }

  public static getInstance() {
    if (!AddressingService.instance) {
      AddressingService.instance = new AddressingService();
    }

    return AddressingService.instance;
  }

  public init(
    data: AddressingStructureResponse[],
    currentWorkgroupId: number,
    treeIdentifier: string
  ): AddressingStructure[][] {
    
    if (!this.workersRegistry[treeIdentifier]) {
      this.workersRegistry[treeIdentifier] = new AddressingWorker();
    }

    this.publish(
      {
        message: INIT_ADDRESSING_WORKER,
        data: {
          result: data,
          currentWorkgroupID: currentWorkgroupId,
        },
      },
      treeIdentifier
    );

    return AddressingHelper.getInstance(treeIdentifier).buildTreeView(data, currentWorkgroupId);
  }

  public applyRules(rules: MediaDeny[], media: IdName, treeIdentifier: string): void {
    this.publish(
      {
        message: PROCESS_ADDRESSING_RULES,
        requestId: +new Date(),
        mediaName: media.name,
        data: {
          denyRules: rules,
        },
      },
      treeIdentifier
    );
  }

  public subscribe(callback: (result: MediaDenyApplyResult) => void, treeIdentifier: string) {
    console.info(`Subscribed to WebWorker: ${treeIdentifier}`);
    this.subscribeToWorker((response: { data: MediaDenyApplyWorkerResponse }) => {
      if (response.data.message === PROCESS_ADDRESSING_RULES_COMPLETE) {
        return callback(response.data.data);
      }
    }, treeIdentifier);
  }

  public removeWorker(treeIdentifier: string) {
    delete this.workersRegistry[treeIdentifier];
  }

  public unsubscribe(handler: (response: MessageEvent<MediaDenyApplyWorkerResponse>) => void, treeIdentifier: string) {
    this.unSubscribeFromWorker(handler, treeIdentifier);
  }

  private publish(message: any, treeIdentifier: string) {
    this.workersRegistry[treeIdentifier].postMessage(message);
  }

  private subscribeToWorker(
    handler: (response: MessageEvent<MediaDenyApplyWorkerResponse>) => void,
    treeIdentifier: string
  ) {
    if (!this.workersRegistry[treeIdentifier]) {
      this.workersRegistry[treeIdentifier] = new AddressingWorker();
    }

    this.workersRegistry[treeIdentifier].addEventListener('message', handler);
  }

  private unSubscribeFromWorker(
    handler: (response: MessageEvent<MediaDenyApplyWorkerResponse>) => void,
    treeIdentifier: string
  ) {
    this.workersRegistry[treeIdentifier].removeEventListener('message', handler);
  }
}

export default AddressingService.getInstance();
