import deDeLocale from 'date-fns/locale/de';
import enGbLocale from 'date-fns/locale/en-GB';
import enUsLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import fiLocale from 'date-fns/locale/fi';
import frLocale from 'date-fns/locale/fr';
import nlLocale from 'date-fns/locale/nl';

export const getDateLocaleBasedOnBrowser = () => {
    switch (navigator.language.toLowerCase()) {
      case 'en-us':
        return enUsLocale;
      case 'en-gb':
        return enGbLocale;
      case 'de':
        return deDeLocale;
      case 'fr':
        return frLocale;
      case 'es':
        return esLocale;
      case 'fi':
        return fiLocale;
      case 'nl':
        return nlLocale;
      default:
        return enGbLocale;
    }
  };

  export const getDateFormatBasedOnBrowser = () => {
    switch (navigator.language.toLowerCase()) {
      case 'en-us':
        return 'MM/dd/yyyy';
      case 'en-gb':
        return 'dd/MM/yyyy';
      case 'de':
        return 'dd.MM.yyyy';
      case 'fr':
        return 'dd/MM/yyyy';
      case 'es':
        return 'dd/MM/yyyy';
      case 'fi':
        return 'd.M.yyyy';
      case 'nl':
        return 'dd-MM-yyyy';
      default:
        return 'dd/MM/yyyy';
    }
  };

  export const getTimeFormatBasedOnBrowser = () => {
    switch (navigator.language.toLowerCase()) {
      case 'en-us':
        return 'hh:mm:ss aa';
      case 'en-gb':
        return 'HH:mm:ss';
      case 'de':
        return 'HH:mm:ss';
      case 'fr':
        return 'HH:mm:ss';
      case 'es':
        return 'HH:mm:ss';
      case 'fi':
        return 'HH:mm:ss';
      case 'nl':
        return 'HH:mm:ss';
      default:
        return 'HH:mm:ss';
    }
  };

  export const getAmPmBasedOnBrowser = () => {
    switch (navigator.language.toLowerCase()) {
      case 'en-us':
        return true;
      case 'en-gb':
        return false;
      case 'de':
        return false;
      case 'fr':
        return false;
      case 'es':
        return false;
      case 'fi':
        return false;
      case 'nl':
        return false;
      default:
        return false;
    }
  }