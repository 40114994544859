import { FormTextFieldListItem } from 'components';
import React from 'react';

import { MediaTypes } from '@models';

export interface AdFormProps {
  media: MediaTypes.Ad.AdMediaDto;
}

export const AdForm: React.FunctionComponent<AdFormProps> = (props) => {
  return (
    <>
      <FormTextFieldListItem label="Account identifier" fieldName="name" inset />
      <FormTextFieldListItem label="Alias" fieldName="name" inset />
    </>
  );
};
