import { action } from 'typesafe-actions';

import { ActionTypes } from './types';

export const setDirty = (type: string | string[], value: boolean) => action(ActionTypes.SET_DIRTY, { type, value });
export const openDialog = (issuer: string, title?: string, content?: string) =>
  action(ActionTypes.OPEN_DIALOG, { issuer, title, content });
export const clearDialog = () => action(ActionTypes.CLEAR_DIALOG);
export const confirmClicked = () => action(ActionTypes.CONFIRM_CLICKED);
export const cancelClicked = () => action(ActionTypes.CANCEL_CLICKED);
export const clearDirtyInstance = (type: string) => action(ActionTypes.CLEAR_DIRTY_INSTANCE, type);
export const initDirtyInstance = (type: string) => action(ActionTypes.INIT_DIRTY_INSTANCE, type);
