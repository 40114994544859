import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IdName } from 'react-tools';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { MessageEdit } from '../../../library/edit/MessageEdit';
import { useStyles } from './PlaylistMessageDialog.jss';

export interface PlaylistMessageDialogProps {
  open: boolean;
  message: IdName | null;
  zoneId: number;
  onMessagePropertiesClose: () => void;
}

export const PlaylistMessageDialog: React.FunctionComponent<PlaylistMessageDialogProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const closeDialog = useCallback(() => {
    props.onMessagePropertiesClose();
  }, [props.onMessagePropertiesClose]);

  return props.message ? (
    <Dialog
      maxWidth={!fullScreen ? 'md' : 'sm'}
      classes={{ paper: classes.dialogPaper }}
      fullWidth={true}
      keepMounted={false}
      open={props.open}
      fullScreen={fullScreen}
      onClose={props.onMessagePropertiesClose}
    >
      <MessageEdit id={props.message.id} onCancel={closeDialog} onSave={closeDialog} channelId={props.zoneId} />
    </Dialog>
  ) : (
    <div></div>
  );
};
