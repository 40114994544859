import {
  createStyles,
  fade,
  makeStyles,
  Theme
} from '@material-ui/core/styles';

  export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      emptySelectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      primaryColorFill: {
        fill: theme.palette.primary.main,
      },
      secondaryColorStroke: {
        stroke: theme.palette.secondary.main,
      },
      fadedColorFill: {
        fill: theme.palette.grey[500],
      },
      fadedColorStroke: {
        stroke: theme.palette.grey[500],
      },
      baseColorFill: {
        fill: theme.palette.text.primary,
      },
      emptySelectionMessage: {
        color: theme.palette.text.secondary,
        marginTop: theme.spacing(4),
      },
      emptySelectionSubMessage: {
        color: theme.palette.text.secondary,
      },
    })
  );
  