import { useField } from 'formik';
import { getDateFormatBasedOnBrowser, getDateLocaleBasedOnBrowser } from 'localization/dateLocale';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface MediaScheduleDatePickerProps {
  name: string;
  label: string;
  maxDate?: Date;
  minDate?: Date;
}

const validDate = (d: any) => d && d.getTime && d.toString() !== 'Invalid Date';

const MediaScheduleDatePicker: React.FunctionComponent<MediaScheduleDatePickerProps> = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const onChangeCallback = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined) => {
      // HMS-53
      // Keyboard changes do not persist hours/minutes/etc of initial value.
      if (date && field) {
        const previous = meta.initialValue;
        if (validDate(previous)) {
          const h = previous.getHours();
          const m = previous.getMinutes();
          const s = previous.getSeconds();
          const ml = previous.getMilliseconds();
          date.setHours(h, m, s, ml);
        }
      }
      helpers.setValue(date);
    },
    [helpers.setValue, meta]
  );

  const format = getDateFormatBasedOnBrowser();

  return (
    <KeyboardDatePicker
      fullWidth
      margin="normal"
      name={props.name}
      id={props.name}
      label={props.label}
      format={format}
      value={field.value}
      onChange={onChangeCallback}
      maxDate={props.maxDate}
      minDate={props.minDate}
      error={meta.error !== undefined}
      helperText={meta.error}
    />
  );
};

export const MediaScheduleDates: React.FunctionComponent = (_) => {
  const [t] = useTranslation();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getDateLocaleBasedOnBrowser()}>
      <Grid container spacing={2}>
        <Grid item md={12} lg={6}>
          <MediaScheduleDatePicker name="scheduling.startDate" label={t('media.startDate')} />
        </Grid>
        <Grid item md={12} lg={6}>
          <MediaScheduleDatePicker name="scheduling.endDate" label={t('media.endDate')} />
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
