import './localization/i18n';

import { LicenseManager } from '@ag-grid-enterprise/core';

import { setupMSG } from './App.bootstrap';
import reportWebVitals from './reportWebVitals';

LicenseManager.setLicenseKey(
  'CompanyName=Mood Media Corp,LicensedApplication=Harmony,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=12,AssetReference=AG-010890,ExpiryDate=9_November_2021_[v2]_MTYzNjQxNjAwMDAwMA==5560f4e01c18d0d55d499757f5865a06'
);

setupMSG();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
