import arrayMove from 'array-move';
import { SkeletonList } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SortEndHandler } from 'react-sortable-hoc';
import { IdName } from 'react-tools';
import { ZoneSelectors } from 'zone/duck';

import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

import { ListMediaItem, MessageCountMap } from '../types';
import { BrowseDialog } from './BrowseDialog';
import {
  generateListMediaItemCopies,
  processBrowsedMedias
} from './browsedMediaProcessor';
import { PlaylistMessageDialog } from './PlaylistMessageDialog';
import { useStyles } from './PlaylistMessages.jss';
import { PlaylistMessagesToolbar } from './PlaylistMessagesToolbar';
import { SortableListContainer } from './SortableList';

export interface PlaylistMessagesProps {
  messages: ListMediaItem[];
  zoneId: number;
  onMessagesChange: (messages: ListMediaItem[]) => void;
  playlistId: number;
}

export const PlaylistMessages: React.FunctionComponent<PlaylistMessagesProps> = (props) => {
  const { messages, onMessagesChange } = props;
  const [browseMode, setBrowseMode] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [messageDialog, setMessageDialog] = useState<IdName | null>(null);
  const classes = useStyles();

  const theme = useTheme();
  const isSm = useMediaQuery(`(max-width: ${theme.breakpoints.values.sm}px)`);

  useEffect(() => {
    setSelectedIds([]);
  }, [props.playlistId]);

  const onChecked = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const id = Number(event.currentTarget.id);
      if (id) {
        const currentIndex = selectedIds.indexOf(id);
        const newSelected = [...selectedIds];

        if (currentIndex === -1) {
          newSelected.push(id);
        } else {
          newSelected.splice(currentIndex, 1);
        }

        setSelectedIds(newSelected);
      }
    },
    [selectedIds, setSelectedIds]
  );

  const onSortEnd = useCallback<SortEndHandler>(
    (sort, sortEvent) => {
      onMessagesChange(arrayMove(messages, sort.oldIndex, sort.newIndex));
    },
    [messages]
  );

  const onBrowseClick = useCallback(() => {
    setBrowseMode(true);
  }, [setBrowseMode]);

  const onBrowseDone = useCallback(
    (newMessagesCount: MessageCountMap) => {
      const newMessages = processBrowsedMedias(messages, newMessagesCount);
      onMessagesChange(newMessages);
      setBrowseMode(false);
    },
    [messages, onMessagesChange, setBrowseMode]
  );

  const onDuplicateClick = useCallback(() => {
    let newMessages = [...messages];

    selectedIds.sort().forEach((e: number, index: number) => {
      const messageIndex = newMessages.findIndex((f) => f.localId === e);
      if (index === -1) {
        return;
      }

      const [copied] = generateListMediaItemCopies(newMessages, newMessages[messageIndex].media.id, 1);
      newMessages.splice(messageIndex + 1, 0, copied);
    });

    newMessages.forEach((e, index) => ({
      ...e,
      localId: index + 1,
    }));
    setSelectedIds([]);
    props.onMessagesChange(newMessages);
  }, [selectedIds, messages, props.onMessagesChange]);

  const onRemoveClick = useCallback(() => {
    const newMessages = messages.filter((m) => selectedIds.indexOf(m.localId) === -1);
    onMessagesChange(newMessages);
    setSelectedIds([]);
  }, [selectedIds, messages]);

  const onMessagePropertiesClick = useCallback(
    (message: IdName) => {
      setMessageDialog(message);
    },
    [setMessageDialog]
  );

  const onMessagePropertiesClose = useCallback(() => {
    setMessageDialog(null);
  }, [setMessageDialog]);

  const onToolbarCheckChanged = useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedIds(messages.map((m) => m.localId));
      } else {
        setSelectedIds([]);
      }
    },
    [messages, setSelectedIds]
  );
  const loading = useSelector(ZoneSelectors.selectIsFetchingPlaylist);
  const [t] = useTranslation();

  return (
    <>
      <div className={classes.container}>
        <PlaylistMessagesToolbar
          onBrowse={onBrowseClick}
          onDuplicate={onDuplicateClick}
          onRemove={onRemoveClick}
          selectionCount={selectedIds.length}
          checkStatus={selectedIds.length === 0 ? false : selectedIds.length === messages.length ? true : null}
          onCheckChange={onToolbarCheckChanged}
        />
        {loading && (
          <SkeletonList
            minItems={5}
            maxItems={10}
            icon
            divider
            secondaryAction
            iconStyle="circle"
            secondaryActionStyle="rect"
          />
        )}
        {!loading && messages.length > 0 && (
          <SortableListContainer
            selectedIds={selectedIds}
            onChecked={onChecked}
            onClick={onMessagePropertiesClick}
            items={messages}
            onSortEnd={onSortEnd}
            useDragHandle={!isSm}
            dragHandle={!isSm}
            pressDelay={isSm ? 200 : 0}
            lockAxis="y"
            helperClass={classes.sortableListItem}
          />
        )}
        {!loading && messages.length === 0 && (
          <Typography variant="subtitle2" color="textSecondary" align="center">
            {t('playlistMessages.emptyList')}
          </Typography>
        )}
      </div>
      <BrowseDialog open={browseMode} onCancel={() => setBrowseMode(false)} onDone={onBrowseDone} />
      <PlaylistMessageDialog
        open={messageDialog !== null}
        message={messageDialog}
        zoneId={props.zoneId}
        onMessagePropertiesClose={onMessagePropertiesClose}
      ></PlaylistMessageDialog>
    </>
  );
};
