import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText, { ListItemTextClassKey } from '@material-ui/core/ListItemText';

export interface ListItemLinkProps {
  id?: string;
  icon?: React.ReactElement;
  primary: string;
  secondary?: string;
  selected?: boolean;
  primaryTextClasses?: Partial<Record<ListItemTextClassKey, string>> | undefined;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  [otherProp: string]: any;
}

export const ListItemLink: React.FunctionComponent<ListItemLinkProps> = (props) => {
  const { icon, primary, secondary, onClick, selected, primaryTextClasses, ...otherProps } = props;
  const onListItemClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );
  return (
    <li>
      <ListItem onClick={onListItemClick} button selected={selected} {...otherProps}>
        {props.icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText classes={primaryTextClasses} primary={primary} secondary={secondary} />
      </ListItem>
    </li>
  );
};
