import { format } from 'date-fns';
import HttpService from 'utils/http';

import { MediaDto, MediaTypes, MessageType } from '@models';

import { ChannelContentType, Zone } from './types';

export const ZonesApi = {
  getZones: async (workgroupId: number): Promise<{ overhead: Zone[]; onHold: Zone[] }> => {
    const allZones = await HttpService.get<Zone[]>('v6/messaging/zones');

    return {
      overhead: allZones.filter((z) => z.contentType === ChannelContentType.Overhead),
      onHold: allZones.filter((z) => z.contentType === ChannelContentType.OnHold),
    };
  },
  getZonePlaylists: async (zoneId: number, messageType: MessageType): Promise<MediaTypes.Playlist.PlaylistDto[]> => {
    if (messageType === MessageType.Overhead) {
      return await HttpService.get<MediaTypes.Playlist.PlaylistDto[]>(`v6/channel/events/${zoneId}`);
    }
    return await HttpService.get<MediaTypes.Playlist.PlaylistDto[]>(`v6/channel/${zoneId}`);
  },
  getPlaylistInfo: async (playlistId: number): Promise<{ playlist: MediaTypes.Playlist.PlaylistDto; messages: MediaDto[] }> => {
    const dtos = await HttpService.post<MediaTypes.Playlist.PlaylistDto[]>('v6/media/batch', [playlistId]);
    if (dtos.length === 0) {
      throw 'No media could be found for id ' + playlistId;
    }
    const playlist = dtos[0];
    const messages = await HttpService.get<MediaDto[]>(`v6/playlist/${playlistId}`);
    return { playlist, messages };
  },
  savePlaylist: async (
    zoneId: number,
    playlistInfo: MediaTypes.Playlist.PlaylistUpdateDto,
    messages: MediaDto[],
    position: number,
    messageType: MessageType
  ): Promise<{ items: number[]; playlistInfo: MediaTypes.Playlist.PlaylistDto } | null> => {
    const data = {
      items: messages ? messages.map((m) => m.id) : [],
      playlistInfo: {
        ...playlistInfo,
        startDate: playlistInfo.startDate,
        endDate: playlistInfo.endDate,
      },
    };
    if (playlistInfo.id && playlistInfo.id > 0) {
      return HttpService.post(`v6/playlist/messageSchedule?publish=1&channelId=${zoneId}`, data);
    }

    if (messageType === MessageType.Overhead) {
      return HttpService.post(`v6/messaging/addEvent/${zoneId}`, data);
    } else {
      return HttpService.post(`v6/messaging/addLoop/${zoneId}/${position}`, data);
    }
  },
  deletePlaylist: async (zoneId: number, playlistId: number) => {
    return HttpService.delete(`v6/messaging/${zoneId}/${playlistId}`);
  },
  getMediaChannelReferences: async (mediaId: number): Promise<Zone[]> => {
    return await HttpService.get<Zone[]>(`v6/messaging/mediaZones/${mediaId}`);
  },
};
