import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) => {
  return createStyles({
    unselectableRow: {
      pointerEvents: 'none',
      '& .ag-icon-checkbox-checked': {
        color: `${theme.palette.grey[500]} !important`,
      },
    },
    gridContainer: {
      height: '100%',
      width: '100%',
    },
    rowPointer: {
      cursor: 'pointer',
    },
  });
};

export const useStyles = makeStyles(styles);
