import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const styles = (theme: Theme) =>
  createStyles({
    appBarButton: {
      marginLeft: theme.spacing(0.5),
      padding: theme.spacing(0.5),
    },
  });

export const useStyles = makeStyles(styles);
