import { MessageLibraryItem } from 'library';
import React from 'react';

import { RowNode } from '@ag-grid-enterprise/all-modules';

interface GridContext {
  secondaryAction: React.FunctionComponent<{ item: MessageLibraryItem }>;
  secondaryActionProps: {
    [key: string]: any;
  };
}

interface NavigatorTableActionRendererProps {
  context: GridContext;
  node: RowNode;
}

export const NavigatorTableActionRenderer: React.FunctionComponent<NavigatorTableActionRendererProps> = (
  props: NavigatorTableActionRendererProps
) => {
  if (props.context.secondaryAction) {
    return <props.context.secondaryAction {...props.context.secondaryActionProps} item={props.node.data} />;
  } else {
    return <span>no action</span>;
  }
};
