import { FullWidthSearchBox, SkeletonList } from 'components';
import { parseISO } from 'date-fns';
import { MessagePreview } from 'library/edit/mediaTypes/audio/MessagePreview';
import React, { createRef, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PauseIcon from '@material-ui/icons/Pause';
import PlayCircleIcon from '@material-ui/icons/PlayCircleOutline';
import { ContentFileState } from '@models/ContentFileState';

import { NavigatorItemsProps } from '../types';
import { useStyles } from './NavigatorList.jss';

export interface NavigatorListProps extends NavigatorItemsProps {
  
}

export const NavigatorList: React.FunctionComponent<NavigatorListProps> = (props) => {
  const { libraryItems, fetchingMessages, onItemSelected } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [currentPlayingMessageId, setCurrentPlayingMessage] = useState(0);
  const [onPlayError, setOnPlayError] = useState(false);

  const audioRef = createRef<HTMLAudioElement>();

  const onItemClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const id = Number(event.currentTarget.id);
      const libraryItem = libraryItems.find((li) => li.mediaInfo.id === id);
      if (!libraryItem || !onItemSelected) {
        return;
      }

      onItemSelected(libraryItem);
    },
    [onItemSelected, libraryItems, dispatch]
  );

  const isPlaying = (mediaId: number) => currentPlayingMessageId === mediaId;
  const previewMessage = (mediaId: number, contentId: number | undefined) => {
    setCurrentPlayingMessage(mediaId);
    setOnPlayError(false);
    if (isPlaying(mediaId)) {
      audioRef.current?.pause();
      setCurrentPlayingMessage(0);
      return;
    }

    if (audioRef?.current && contentId) {
      audioRef.current.src = `${process.env.REACT_APP_ROOT_DOMAIN}v5/medias/${contentId}/parts/${contentId}.mp3`;
      var promise = audioRef.current.play();
      if (promise !== undefined) {
        promise
          .then((_) => {
            // Autoplay started!
          })
          .catch((error) => {
            console.log('no message found');
            setOnPlayError(true);
          });
      }
    }
  };
  const onEnded = useCallback(() => {
    setCurrentPlayingMessage(0);
  }, []);

  return (
    <>
      {fetchingMessages ? (
        <SkeletonList listClass={classes.list} minItems={5} maxItems={10} subtitle divider/>
      ) : (
        <List className={classes.list}>
          {libraryItems.map((msg) => (
            <ListItem id={msg.mediaInfo.id.toString()} button key={msg.mediaInfo.id} divider onClick={onItemClick}>
              <ListItemText
                primary={msg.mediaInfo.name}
                secondary={parseISO(msg.mediaInfo.creationDate).toDateString()}
              />
              <ListItemSecondaryAction>
                {props.secondaryAction ? (
                  <props.secondaryAction item={msg} {...props.secondaryActionProps} />
                ) : (
                  !msg.mediaInfo.isFolder &&
                  (msg.mediaInfo.fileStatus === ContentFileState.Ready ||
                    msg.mediaInfo.fileStatus === ContentFileState.Reencoded) && (
                    <>
                      <IconButton edge="end" onClick={() => previewMessage(msg.mediaInfo.id, msg.mediaInfo.contentId)}>
                        {onPlayError && msg.mediaInfo.id === currentPlayingMessageId ? (
                          <ErrorOutlineIcon />
                        ) : msg.mediaInfo.id === currentPlayingMessageId ? (
                          <PauseIcon />
                        ) : (
                          <PlayCircleIcon />
                        )}
                      </IconButton>
                      <audio preload="none" onEnded={onEnded} ref={audioRef} />
                    </>
                  )
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};
