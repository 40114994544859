import HttpService from 'utils/http';

import { MediaDto, MediaUpdateDto } from '@models';

import { MediaReferencesPayload, MessageLibraryItem } from './types';

export const LibraryApi = {
  getLibrary: async (workgroupId: number): Promise<MessageLibraryItem[]> => {
    return await HttpService.get<MessageLibraryItem[]>('v6/messaging/library');
  },
  deleteMessages: async (mediaIds: number[]): Promise<any> => {
    return await HttpService.post<any>('v6/media/delete', mediaIds);
  },
  saveMessages: async (media: MediaUpdateDto, applyChanges: boolean): Promise<any> => {
    return await HttpService.post<any>(`v6/media/batchUpdate?publish=${applyChanges ? 1 : 0}`, [media]);
  },
  getMessageInfo: async (messageId: number): Promise<MediaDto> => {
    const mediaDtos = await HttpService.post<MediaDto[]>('v6/media/batch', [messageId]);
    return mediaDtos[0];
  },
  mediaReferences: async (mediaReferencesPayload: MediaReferencesPayload): Promise<any> => {
    return await HttpService.post<any>('v6/media/references', mediaReferencesPayload);
  },
  publishZones: async (zoneIds: Number[], sendNotification: boolean = false): Promise<any> => {
    return await HttpService.post<any>(`v6/publish/channel?sendNotification=${sendNotification}`, zoneIds);
  },
};
