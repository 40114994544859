import { EntityType } from 'react-tools';
import { FixedSizeNodeData } from 'react-vtree';

export enum TreeItemState {
  InheritAllow = 0,
  Allow = 1,
  Deny = 2,
  InheritDeny = 3,
}

export interface AddressingStructure {
  uniqueTreeId: string;
  label: string;
  level: number;
  entityType: EntityType;
  modelType: TreeItemType;
  modelId: string;
  parentModelId: string;
  cleanParentId: string;
  isExpanded: boolean;
  isSelected: boolean;
  children: AddressingStructure[];
  metadata: string;
  childCount: number;
  hasChildren: boolean;
  $$hashKey: string;
  filteredIn: boolean;
  chckState: number;
  cleanId: number;
  link?: boolean;
  linked?: boolean;
}

//export type TreeItemType = 'Site' | 'Player' | 'Workgroup' | 'Group' | 'Stream' | 'FOLDER';

export enum TreeItemType {
  Site = 'Site',
  Player = 'Player',
  Workgroup = 'Workgroup',
  Group = 'Group',
  SiteGroup = 'SiteGroup',
  Stream = 'Stream',
  FOLDER = 'FOLDER',
}

export interface MediaDeny {
  idMediaChannel: number;
  idMedia: number;
  idEntity: number;
  idEntityType: EntityType;
  deny: boolean | null;
}

export interface AddressingStructureResponse {
  id: string;
  parent: string;
  name: string;
  level: number;
  entityType: number;
  link: boolean;
}

export interface MediaDenyApplyWorkerResponse {
  message: string;
  requestId: number;
  data: MediaDenyApplyResult;
}

export interface MediaDenyApplyResult {
  bucketGroups: { [key: string]: number };
  bucketSites: { [key: string]: number };
  bucketPlayers: { [key: string]: number };
  bucketStream: { [key: string]: number };
  bucketWorkgroups: { [key: string]: number };
}

export enum AddressingViewModes {
  AZ = 0,
  Workgroups = 1,
  Groups = 2,
}

export enum DefaultMediaAddressingBehavior {
  Deny = 0,
  Allow = 1
}

export interface AddressingState {
  [key: string]: AddressingInstanceState;
}

export interface AddressingInstanceState {
  addressingStructure: AddressingStructureState;
  addressingRules: AddressingRulesState;
  needToUpdateTree: boolean;
  workgroupId: number;
  channelId: number;
  mediaId: number;
  viewMode: AddressingViewModes;
  subscribedToWebWorker: boolean;
  initializationComplete: boolean;
}

export interface AddressingContextData {
  mediaId: number;
  channelId: number;
  workgroupId: number;
  cleanupMode?: { [key in AddressingCleanUp]?: boolean };
}

interface AddressingData<T, K, P> {
  originalData: T;
  rawData: K;
  processedData: P;
  processingStatus: {
    isProcessing: boolean;
    complete: boolean;
  };
  fetchStatus: {
    isFetching: boolean;
    complete: boolean;
    error: string;
  };
}

export type AddressingStructureState = AddressingData<
  AddressingStructure[][],
  AddressingStructureResponse[],
  AddressingStructure[][]
>;
export type AddressingRulesState = AddressingData<MediaDeny[], MediaDeny[], MediaDenyApplyResult>;

export type TreeNode = Readonly<AddressingStructure>;

export type TreeData = FixedSizeNodeData &
  Readonly<{
    isLeaf: boolean;
    label: string;
    nestingLevel: number;
    isExpanded: boolean;
    chckState: number;
    modelType: TreeItemType;
    isProcessing: boolean;
    isLinked: boolean;
    onToggle: () => void;
    onChange: () => void;
  }>;

export type NodeMeta = Readonly<{
  nestingLevel: number;
  node: TreeNode;
}>;

export enum AddressingCleanUp {
  NONE = 0,
  ON_WORKGROUP_CHANGE = 1,
  ON_CHANNEL_CHANGE = 2,
  ON_MEDIA_CHANGE = 4,
  ON_UNMOUNT = 8,
  ON_ALL = 15,
}

export enum AddressingClearMode {
  RESET = 1,
  REVERT,
}

export const addressingAreaName = 'ADDRESSING_AREA';

export enum ActionTypes {
  SUBSCRIBE_TO_WEB_WORKER = 'SUBSCRIBE_TO_WEB_WORKER',
  FETCH_ADDRESSING_STRUCTURE_REQUEST = 'FETCH_ADDRESSING_STRUCTURE_REQUEST',
  FETCH_ADDRESSING_STRUCTURE_SUCCESS = 'FETCH_ADDRESSING_STRUCTURE_SUCCESS',
  FETCH_ADDRESSING_STRUCTURE_ERROR = 'FETCH_ADDRESSING_STRUCTURE_ERROR',
  CLEAR_ADDRESSING_STRUCTURE = 'CLEAR_ADDRESSING_STRUCTURE',
  FETCH_ADDRESSING_RULES_REQUEST = 'FETCH_ADDRESSING_RULES_REQUEST',
  FETCH_ADDRESSING_RULES_SUCCESS = 'FETCH_ADDRESSING_RULES_SUCCESS',
  FETCH_ADDRESSING_RULES_ERROR = 'FETCH_ADDRESSING_RULES_ERROR',
  SAVE_ADDRESSING_RULES = 'SAVE_ADDRESSING_RULES',
  BUILD_ADDRESSING_TREE = 'BUILD_ADDRESSING_TREE',
  PROCESS_ADDRESSING_RULES_START = 'PROCESS_ADDRESSING_RULES_START',
  ADDRESSING_RULES_RESET = 'ADDRESSING_RULES_RESET',
  PROCESS_ADDRESSING_RULES_END = 'PROCESS_ADDRESSING_RULES_END',
  CLEAR_ADDRESSING_RULES = 'CLEAR_ADDRESSING_RULES',
  CLEAR_ADDRESSING_FULL = 'CLEAR_ADDRESSING_FULL',
  UPDATE_ADDRESSING_TREE = 'UPDATE_ADDRESSING_TREE',
  TOGGLE_NODE = 'TOGGLE_NODE',
  SET_ADDRESSING_VIEW_MODE = 'SET_ADDRESSING_VIEW_MODE',
  INIT_ADDRESSING_INSTANCE = 'INIT_ADDRESSING_INSTANCE',
  REMOVE_ADDRESSING_INSTANCE = 'REMOVE_ADDRESSING_INSTANCE',
}
