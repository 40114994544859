import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

import { useStyles } from './EmptyAddressing.jss';

interface EmptyAddressingProps {
  title?: string;
  subtitle?: string;
}

export const EmptyAddressing: React.FunctionComponent<EmptyAddressingProps> = React.memo((props) => {
  const classes = useStyles();
  const [t] = useTranslation();
  return (
    <div className={classes.emptySelectionContainer}>
      <svg width="191" height="175" viewBox="0 0 191 175" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M27.7554 146.772C25.3185 139.381 24 131.481 24 123.272C24 81.8511 57.5786 48.2725 99 48.2725C140.421 48.2725 174 81.8511 174 123.272C174 131.481 172.681 139.381 170.245 146.772"
          className={classes.fadedColorStroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="12 10"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9706 64.1921C17.5954 63.5673 17.5954 62.5542 16.9706 61.9294C16.3457 61.3045 15.3327 61.3045 14.7078 61.9294L11.3137 65.3235L7.9196 61.9294C7.29476 61.3045 6.2817 61.3045 5.65686 61.9294C5.03202 62.5542 5.03202 63.5673 5.65686 64.1921L9.05097 67.5862L5.65685 70.9803C5.03201 71.6052 5.03201 72.6182 5.65685 73.2431C6.28169 73.8679 7.29475 73.8679 7.91959 73.2431L11.3137 69.849L14.7078 73.2431C15.3327 73.8679 16.3457 73.8679 16.9706 73.2431C17.5954 72.6182 17.5954 71.6052 16.9706 70.9803L13.5764 67.5862L16.9706 64.1921Z"
          className={classes.fadedColorFill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.9706 111.192C55.5954 110.567 55.5954 109.554 54.9706 108.929C54.3457 108.304 53.3327 108.304 52.7078 108.929L49.3137 112.323L45.9196 108.929C45.2948 108.304 44.2817 108.304 43.6569 108.929C43.032 109.554 43.032 110.567 43.6569 111.192L47.051 114.586L43.6569 117.98C43.032 118.605 43.032 119.618 43.6569 120.243C44.2817 120.868 45.2948 120.868 45.9196 120.243L49.3137 116.849L52.7078 120.243C53.3327 120.868 54.3457 120.868 54.9706 120.243C55.5954 119.618 55.5954 118.605 54.9706 117.98L51.5765 114.586L54.9706 111.192Z"
          className={classes.baseColorFill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.971 47.1921C185.595 46.5673 185.595 45.5542 184.971 44.9294C184.346 44.3045 183.333 44.3045 182.708 44.9294L179.314 48.3235L175.92 44.9294C175.295 44.3045 174.282 44.3045 173.657 44.9294C173.032 45.5542 173.032 46.5673 173.657 47.1921L177.051 50.5862L173.657 53.9803C173.032 54.6052 173.032 55.6182 173.657 56.2431C174.282 56.8679 175.295 56.8679 175.92 56.2431L179.314 52.849L182.708 56.2431C183.333 56.8679 184.346 56.8679 184.971 56.2431C185.595 55.6182 185.595 54.6052 184.971 53.9803L181.576 50.5862L184.971 47.1921Z"
          className={classes.baseColorFill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M155.971 148.192C156.595 147.567 156.595 146.554 155.971 145.929C155.346 145.304 154.333 145.304 153.708 145.929L150.314 149.323L146.92 145.929C146.295 145.304 145.282 145.304 144.657 145.929C144.032 146.554 144.032 147.567 144.657 148.192L148.051 151.586L144.657 154.98C144.032 155.605 144.032 156.618 144.657 157.243C145.282 157.868 146.295 157.868 146.92 157.243L150.314 153.849L153.708 157.243C154.333 157.868 155.346 157.868 155.971 157.243C156.595 156.618 156.595 155.605 155.971 154.98L152.576 151.586L155.971 148.192Z"
          className={classes.baseColorFill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M126.607 30.2223C126.997 29.8317 126.997 29.1986 126.607 28.808C126.216 28.4175 125.583 28.4175 125.192 28.808L123.071 30.9294L120.95 28.8081C120.559 28.4175 119.926 28.4175 119.536 28.8081C119.145 29.1986 119.145 29.8317 119.536 30.2223L121.657 32.3436L119.536 34.4649C119.145 34.8554 119.145 35.4886 119.536 35.8791C119.926 36.2696 120.559 36.2696 120.95 35.8791L123.071 33.7578L125.192 35.8791C125.583 36.2697 126.216 36.2697 126.607 35.8791C126.997 35.4886 126.997 34.8554 126.607 34.4649L124.485 32.3436L126.607 30.2223Z"
          className={classes.fadedColorFill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.6066 69.2222C80.9971 68.8317 80.9971 68.1985 80.6066 67.808C80.216 67.4175 79.5829 67.4175 79.1923 67.808L77.071 69.9293L74.9497 67.808C74.5592 67.4175 73.926 67.4175 73.5355 67.808C73.145 68.1985 73.145 68.8317 73.5355 69.2222L75.6568 71.3435L73.5355 73.4648C73.145 73.8554 73.145 74.4885 73.5355 74.8791C73.926 75.2696 74.5592 75.2696 74.9497 74.8791L77.071 72.7577L79.1923 74.8791C79.5829 75.2696 80.216 75.2696 80.6066 74.8791C80.9971 74.4885 80.9971 73.8554 80.6066 73.4649L78.4852 71.3435L80.6066 69.2222Z"
          className={classes.fadedColorFill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.364 78.2423C113.598 78.008 113.598 77.6281 113.364 77.3938C113.13 77.1595 112.75 77.1595 112.515 77.3938L111.243 78.6666L109.97 77.3938C109.736 77.1595 109.356 77.1595 109.121 77.3938C108.887 77.6281 108.887 78.008 109.121 78.2423L110.394 79.5151L109.121 80.7879C108.887 81.0222 108.887 81.4021 109.121 81.6364C109.356 81.8707 109.736 81.8707 109.97 81.6364L111.243 80.3636L112.515 81.6364C112.75 81.8707 113.13 81.8707 113.364 81.6364C113.598 81.4021 113.598 81.0222 113.364 80.7879L112.091 79.5151L113.364 78.2423Z"
          className={classes.baseColorFill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.607 118.222C153.997 117.832 153.997 117.198 153.607 116.808C153.216 116.417 152.583 116.417 152.192 116.808L150.071 118.929L147.95 116.808C147.559 116.417 146.926 116.417 146.536 116.808C146.145 117.198 146.145 117.832 146.536 118.222L148.657 120.343L146.536 122.465C146.145 122.855 146.145 123.488 146.536 123.879C146.926 124.27 147.559 124.27 147.95 123.879L150.071 121.758L152.192 123.879C152.583 124.27 153.216 124.27 153.607 123.879C153.997 123.489 153.997 122.855 153.607 122.465L151.485 120.343L153.607 118.222Z"
          className={classes.fadedColorFill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M41.6066 147.222C41.9971 146.832 41.9971 146.198 41.6066 145.808C41.216 145.417 40.5829 145.417 40.1924 145.808L38.071 147.929L35.9497 145.808C35.5592 145.417 34.926 145.417 34.5355 145.808C34.145 146.198 34.145 146.832 34.5355 147.222L36.6568 149.343L34.5355 151.465C34.145 151.855 34.145 152.488 34.5355 152.879C34.926 153.27 35.5592 153.27 35.9497 152.879L38.071 150.758L40.1924 152.879C40.5829 153.27 41.216 153.27 41.6066 152.879C41.9971 152.488 41.9971 151.855 41.6066 151.465L39.4852 149.343L41.6066 147.222Z"
          className={classes.fadedColorFill}
        />
        <path
          d="M139 165.273C139 164.981 138.87 164.607 138.436 164.141C137.996 163.669 137.3 163.167 136.317 162.657C134.355 161.639 131.45 160.692 127.777 159.885C120.447 158.276 110.272 157.273 99 157.273C87.728 157.273 77.5533 158.276 70.223 159.885C66.5497 160.692 63.6448 161.639 61.6826 162.657C60.7003 163.167 60.0037 163.669 59.5644 164.141C59.1299 164.607 59 164.981 59 165.273C59 165.564 59.1299 165.938 59.5644 166.404C60.0037 166.876 60.7003 167.378 61.6826 167.888C63.6448 168.907 66.5497 169.853 70.223 170.66C77.5533 172.269 87.728 173.273 99 173.273C110.272 173.273 120.447 172.269 127.777 170.66C131.45 169.853 134.355 168.907 136.317 167.888C137.3 167.378 137.996 166.876 138.436 166.404C138.87 165.938 139 165.564 139 165.273Z"
          fillOpacity="0.1"
          className={classNames(classes.fadedColorStroke, classes.baseColorFill)}
          stroke-width="2"
        />
        <path
          d="M128 84.0225V95.2725H139.25V102.773H128V114.023H120.5V102.773H109.25V95.2725H120.5V84.0225H128ZM98 129.023C93.875 129.023 90.5 125.648 90.5 121.523C90.5 117.398 93.875 114.023 98 114.023C102.125 114.023 105.5 117.398 105.5 121.523C105.5 125.648 102.125 129.023 98 129.023ZM101.75 91.7475V99.3225C100.51 99.1265 99.2558 99.0262 98 99.0225C85.4375 99.0225 75.5 108.66 75.5 122.273C75.5 131.048 82.8125 142.673 98 156.548C113.188 142.673 120.5 131.085 120.5 122.273V121.523H128V122.273C128 134.723 117.987 149.46 98 166.523C78.0125 149.46 68 134.723 68 122.273C68 103.598 82.25 91.5225 98 91.5225C99.275 91.5225 100.513 91.5975 101.75 91.7475Z"
          className={classes.primaryColorFill}
        />
        <path
          d="M74.0185 10.291L58.291 26.0184L52 19.7275"
          className={classes.secondaryColorStroke}
          strokeWidth="5"
          strokeLinecap="round"
        />
      </svg>
      <Typography className={classes.emptySelectionMessage} variant="body1" align="center">
        {props.title ? t(props.title) : t('addressing.noDataForViewMode')}
      </Typography>
    </div>
  );
});
