import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as Actions from './actions';
import { ActionTypes, LayoutState, MainLayoutArea } from './types';

type LayoutActions = ActionType<typeof Actions>;

const initialState: LayoutState = {
  selectedMainArea: MainLayoutArea.LibraryOverhead,
  selectedItem: 0,
  splashScreenShown: false,
};

export const layoutReducer: Reducer<LayoutState, LayoutActions> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.MENU_SELECTION:
      return {
        ...state,
        selectedMainArea: action.payload.mainArea,
        selectedItem: action.payload.selectedItem,
      };
    case ActionTypes.MARK_SPLASH_SCREEN_AS_SHOWN:
      return {
        ...state,
        splashScreenShown: true,
      };
    default:
      return state;
  }
};
