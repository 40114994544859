import React from 'react';

import { ListItemSecondaryAction } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Skeleton from '@material-ui/lab/Skeleton';

export interface SkeletonListProps extends SkeletonListItemsProps {
  listClass?: string;
}

const randomBetween = (min: number, max: number) => Math.floor(min + Math.random() * (max - min));

export const SkeletonList: React.FunctionComponent<SkeletonListProps> = React.memo(
  (props) => {
    return (
      <List className={props.listClass}>
        <SkeletonListItems {...props} />
      </List>
    );
  }
);

export interface SkeletonListItemsProps {
  minItems: number;
  maxItems?: number;
  icon?: boolean;
  iconStyle?: 'circle' | 'rect'
  subtitle?: boolean;
  divider?: boolean;
  secondaryAction?: boolean;
  secondaryActionStyle?: 'circle' | 'rect'
}

export const SkeletonListItems: React.FunctionComponent<SkeletonListItemsProps> = React.memo(
  (props) => {
    const numberOfItems = !props.maxItems ? props.minItems : randomBetween(props.minItems, props.maxItems);

    return (
      <>
        {Array.from(Array(numberOfItems).keys()).map((i) => (
          <ListItem key={i} divider={props.divider}>
            {props.icon && (
              <ListItemIcon>
                <Skeleton variant={props.iconStyle ? props.iconStyle : 'circle'} width={24} height={24} />
              </ListItemIcon>
            )}
            <ListItemText
              disableTypography
              primary={<Skeleton variant="text" width={randomBetween(100, 300)} />}
              secondary={props.subtitle ? <Skeleton variant="text" width={randomBetween(100, 300)} /> : null}
            />
            {props.secondaryAction && (
              <ListItemSecondaryAction>
                 <Skeleton variant={props.secondaryActionStyle ? props.secondaryActionStyle : 'circle'} width={24} height={24} />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </>
    );
  }
);
