import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { createDefaultStore } from 'store';

import App from './App';

const defaultStore = createDefaultStore();

// wrapper for ReactDOM.render() call
export const startReact = (rootNode = 'root', appStore: Store = defaultStore) => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={appStore}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById(rootNode)
  );
};
