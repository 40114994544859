export const resources = {
  en: {
    translation: {
      cancel: 'Cancel',
      confirm: 'Confirm',
      done: 'Done',
      back: 'Back',
      close: 'Close',
      save: 'Save',
      home: 'Home',
      library: 'Library',
      zones: 'Zones',
      onHold: 'On-Hold',
      overHead: 'Overhead',
      zonesOnHold: 'On-Hold zones',
      zonesOverHead: 'Overhead zones',
      search: 'Search',
      remove: 'Remove',
      duplicate: 'Duplicate',
      browse: 'Add from library',
      unavailable: 'unavailable',
      help: 'Help',
      playlists: 'Playlists',
      count: 'Count',
      none: 'None',
      'select.all': 'Select all',
      'select.none': 'Select none',
      'select.count': '{{count}} item selected',
      'select.count_plural': '{{count}} items selected',
      'message.addressing.select.zone': 'Please select a zone',
      'message.addressing.no.zones': 'There are no zones containing the media',
      'export.csv': 'Export CSV',
      'export.xlsx': 'Export XLSX',
      'export.fileNames.messages': 'Messages',
      'message.emptySelectionTitle': 'No message selected',
      'message.emptySelectionSubtitle': 'Edit message name, scheduling, volume and more!',
      'message.emptyZonePlaylistSelectionTitle': 'No Playlist Selected',
      'message.emptyZonePlaylistSelectionSubtitle': 'Edit Playlist name, scheduling and more!',
      name: 'Name',
      creationDate: 'Created',
      duration: 'Duration',
      durationUnavailable: 'Duration unavailable',
      playMessage: 'Play message',
      pauseMessage: 'Pause',
      dateInterval: 'Date interval',
      timeInterval: 'Time interval',
      properties: 'Properties',
      deleteMessageConfirmTitle: 'Delete message',
      deleteMessageContent: 'Are you sure you want to delete this message?',
      denyDeleteMessageTitle: 'The message is scheduled',
      selectDiffusionDays: 'Choose at least one',
      startTimeGreater: "Start time can't be after end time",
      endTimeGreater: "End time can't be before start time",

      'splash.title': 'Welcome to the Messaging app!',
      'splash.content':
        'You can use this app to manage your Overhead Audio Messages and your On-Hold Music and/or Messaging.',
      'splash.userGuide': ' For the new Harmony Messaging User Guide, click',
      'splash.userExperience':
        'For more information on your Harmony Music/Messaging Experience, head to our Support Hub by clicking',
      'splash.closeButton': 'Close',
      'splash.hide': "Don't show this again",
      'splash.here': 'here',

      'media.id': 'Id',
      'media.name': 'Name',
      'media.description': 'Description',
      'media.creationDate': 'Created',
      'media.duration': 'Duration',
      'media.date.notSet': '(not set)',
      'media.durationUnavailable': 'Duration unavailable',
      'media.originalDuration': 'Original duration',
      'media.startDate': 'Start date',
      'media.endDate': 'End date',
      'media.startTime': 'Start time',
      'media.endTime': 'End time',
      'media.soundVolume': 'Sound Volume',
      'media.duration.hours': 'Hours',
      'media.duration.minutes': 'Minutes',
      'media.duration.seconds': 'Seconds',
      'media.useCustomDuration': 'Use custom duration',
      'media.duration.error.unavailable': 'Media duration not set',
      'media.trueDuration.error.unavailable': 'Original duration not available',
      'media.validators.maxLength': 'Length should be less than {{length}}',
      'media.validators.name': 'Name cannot be empty',
      'media.validators.invalidDate': 'Invalid date',
      'media.validators.scheduling.date': 'End date cannot be before start date',
      'media.validators.scheduling.time': 'End time cannot be before start time',
      'media.validators.scheduling.repeatInterval.required': 'Repeat interval is required',
      'media.validators.scheduling.repeatInterval.min': 'Repeat interval must be greater than 0',
      'media.validators.scheduling.repeatInterval.max': 'Repeat interval must be less than 1440',
      'media.validators.scheduling.diffusionDays': 'At least one week day needs to be selected',

      'media.type.10': 'Audio',
      'media.type.35': 'Folder',
      'media.type.999': 'Ad',

      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',

      'monday.abv': 'Mon',
      'tuesday.abv': 'Tue',
      'wednesday.abv': 'Wed',
      'thursday.abv': 'Thu',
      'friday.abv': 'Fri',
      'saturday.abv': 'Sat',
      'sunday.abv': 'Sun',

      messages: 'Messages',
      schedule: 'Schedule',
      locations: 'Locations',

      sortByName: 'Sort by Name',
      sortByCreatedDate: 'Sort by Created Date',

      playMode: 'Play mode',
      repeatFrequency: 'Repeat Frequency',
      'playMode.afterCurrentSong': 'After Current Song Finishes',
      'playMode.interruptSong': 'Interrupt Song',
      'playMode.overlay': 'Overlay',

      randomMessagePlayback: 'Random message playback',
      'randomMessagePlayback.every': 'every',
      'randomMessagePlayback.playedMedia': 'played media',
      numberOfMessagesToPlay: 'Number of messages to play',
      playAllMessages: 'Play all messages',
      isAllDay: 'All day',
      unauthorized: 'This account does not have any messaging zones.',

      playFrequency: 'Play frequency',
      'playFrequency.onceDaily': 'Play Once Daily',
      'playFrequency.timeInterval': 'Time Interval',
      'playFrequency.playbackInterval': 'Playback Interval',

      'success.saveMessage': 'Message data saved',
      'error.saveMessage': 'Could not save message',
      createPlaylist: 'Create playlist',

      'playlist.success.saveMessage': 'Message scheduled data saved',
      'playlist.success.deletePlaylist': 'Playlist deleted',
      'playlist.error.saveMessage': 'Could not save message schedule',
      'playlist.error.deletePlaylist': 'Could not delete playlist',
      'playlists.emptyList': 'No playlists found',
      'playlists.edit.message.dialog.title': 'Edit message {{name}}',
      'playlistMessages.emptyList': 'No messages selected',
      createZonePlaylist: 'Create zone playlist',
      deleteZonePlaylist: 'Delete zone playlist',
      zonePlaylistTitlePlaceholder: 'Playlist title',
      'dialog.discardChangesPrompt.title': 'Discard changes',
      'dialog.discardChangesPrompt.content':
        'Are you sure you want to navigate away? The currently selected item has unsaved changes!',
      'playlist.discardChangesPrompt.content':
        'Are you sure you want to navigate away? The currently selected playlist has unsaved changes!',
      'message.discardChangesPrompt.content':
        'Are you sure you want to navigate away? The currently selected message has unsaved changes!',

      'navigator.noRowsInFolder': 'There are no messages in this folder',
      'navigator.noRows': 'There are no messages in the library',

      'addressing.defaultBehavior.allowByDefault': 'Allow by default',
      'addressing.defaultBehavior.help.deny': 'Playlists are prevented from playing by default on every location. Turn the switch on to change the default behavior to "Allow to play". Locations which are already denied explicitly will remain so.',
      'addressing.defaultBehavior.help.allow': 'Messages are allowed to play by default on every location. Turn the switch off to change the default behavior to "Deny everywhere". Locations which are already allowed explicitly will remain so.',
      'addressing.viewMode.az': 'A-Z',
      'addressing.viewMode.groups': 'Groups',
      'addressing.linkedToChannel': 'Connected to Channel',
      'addressing.otherSites': 'Not Connected',
      'addressing.noDataForViewMode': 'There is no data to show for this view mode',
    },
  },
  fr: {
    translation: {},
  },
  de: {
    translation: {},
  },
};
