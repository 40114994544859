import { Navigator } from 'library';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MediaDto } from '@models';

import { MessageCountMap } from '../types';
import { useStyles } from './BrowseDialog.jss';
import { NavSecondaryAction } from './NavigationSecondaryAction';

export interface BrowseDialogProps {
  open: boolean;
  onDone: (messagesCount: MessageCountMap) => void;
  onCancel: () => void;
}

export const BrowseDialog: React.FunctionComponent<BrowseDialogProps> = (props) => {
  const { onDone, onCancel, open } = props;

  const [t] = useTranslation();
  const theme = useTheme();
  const messageCount = useRef<MessageCountMap>({});
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  useEffect(() => {
    messageCount.current = {};
  }, [open]);

  const handleDoneClick = useCallback(() => {
    onDone(messageCount.current);
  }, [messageCount, onDone]);

  const onPlus = useCallback(
    (item: MediaDto) => {
      const newMap = { ...messageCount.current };
      if (!newMap[item.id]) {
        newMap[item.id] = { count: 0, item };
      }
      if (newMap[item.id].count < 100) {
        newMap[item.id].count++;
      }
      messageCount.current = newMap;
    },
    [messageCount]
  );

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      maxWidth="lg"
      fullWidth
      keepMounted={false}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle>{t('browse')}</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Navigator
          variant={fullScreen ? 'list' : 'table'}
          disableSelection
          hideControls
          secondaryActionLabel={'count'}
          secondaryAction={NavSecondaryAction as any}
          secondaryActionProps={{
            onPlus,
            messageCount
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} autoFocus>
          {t<string>('cancel')}
        </Button>
        <Button variant="contained" onClick={handleDoneClick}>
          {t<string>('done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
