import React from 'react';

import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const EventPlayModeIcon: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 11L19 8L23 12L19 16L19 13L15 13L15 11L19 11ZM5 11L5 8L9 12L5 16L5 13L0.999999 13L0.999999 11L5 11ZM11 4L13 4L13 20L11 20L11 4Z"
    />
  </SvgIcon>
);
