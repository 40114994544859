import { dirtyAreaName, DirtyInstanceState } from './types';

export const selectOpen = (state: any): boolean => state[dirtyAreaName].dialog.open;
export const selectIssuer = (state: any): string => state[dirtyAreaName].dialog.issuer;
export const selectConfirmClicked = (state: any): boolean => state[dirtyAreaName].dialog.confirmClicked;
export const selectCancelClicked = (state: any): boolean => state[dirtyAreaName].dialog.cancelClicked;
export const selectTitle = (state: any): string => state[dirtyAreaName].dialog.title;
export const selectContent = (state: any): string => state[dirtyAreaName].dialog.content;
export const selectDirty = (identifier: string) => {
  return (state: any): boolean => {
    return state[dirtyAreaName].instances[identifier]?.value;
  };
};

export const selectInstances = (types?: string[]) => {
  return (state: any): { [key: string]: DirtyInstanceState } => {
    return types
      ? types.reduce<{ [key: string]: DirtyInstanceState }>((acc, type) => {
          acc[type] = { ...state[dirtyAreaName].instances[type] };
          return acc;
        }, {})
      : Object.keys(state[dirtyAreaName].instances).reduce<{ [key: string]: DirtyInstanceState }>((acc, type) => {
          acc[type] = { ...state[dirtyAreaName].instances[type] };
          return acc;
        }, {});
  };
};
