import {
  addressingAreaName,
  addressingReducer,
  AddressingState
} from 'addressing';
import { dirtyAreaName, dirtyReducer, DirtyState } from 'dirty';
import { layoutAreaName, layoutReducer, LayoutState } from 'layout';
import { libraryAreaName, libraryReducer, LibraryState } from 'library';
import {
  notificationsAreaName,
  notificationsReducer,
  NotificationsState
} from 'react-tools';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { zoneAreaName, zoneReducer, ZoneState } from 'zone';

export interface AppState {
  [notificationsAreaName]: NotificationsState;
  [layoutAreaName]: LayoutState;
  [zoneAreaName]: ZoneState;
  [libraryAreaName]: LibraryState;
  [addressingAreaName]: AddressingState;
  [dirtyAreaName]: DirtyState;
}

export const createDefaultStore = () => {
  const store = createStore(
    combineReducers({
      [notificationsAreaName]: notificationsReducer,
      [layoutAreaName]: layoutReducer,
      [libraryAreaName]: libraryReducer,
      [zoneAreaName]: zoneReducer,
      [addressingAreaName]: addressingReducer,
      [dirtyAreaName]: dirtyReducer,
    }),
    composeWithDevTools(applyMiddleware(thunkMiddleware))
  );
  return store;
};
